import React from "react";
import { RecruitmentSteps, ScreenFlow,Warning } from "../../components";
import {quotationIcon,faqPlus,cmvCloseIcon} from "../theme/icons"
import { melanomaFaqs } from "../../helpers";
import {melanomaQuestions} from './questions'
import {environment} from "../../constants"
import { log } from "../../helpers/helper";
const StudyID = environment === "PROD" ? "STU01013" : "STU01617";

const flowarray = [
  { title: "Home", link: "/" },
  { title: "Find Trials", link: "/#find_trial" },
  {
    title: "Search Results",
    link: `/results?q=melonama&in=UNITED%20STATES`,
  },
  {
    title: "Trial Details",
    link: `/results?q=cancer&in=UNITED%20STATES`,
  },
];
const LocationString =
`San Francisco, California 94115 US	Recruiting -*-
Santa Monica, California 90404 US	Recruiting -*-
New Haven, Connecticut 06520 US	Recruiting -*-
Boston, Massachusetts 02114 US	Not yet recruiting -*-
Saint Louis, Missouri 63110 US	Recruiting -*-
New York, New York 10016 US	Recruiting -*-
Portland, Oregon 97239 US	Recruiting -*-
Nashville, Tennessee 37203 US	Recruiting -*-
Westmead, New South Wales 2145 AU	Recruiting -*-
Subiaco, Western Australia 6008 AU	Recruiting -*-
Woolloongabba, Queensland 4102 AU	Recruiting -*-
Dallas, Texas 75246 US	Recruiting -*-
Washington, District of Columbia 20007 US	Recruiting -*-
Hackensack, New Jersey 07601 US	Recruiting -*-
West Palm Beach, Florida 33401 US	Recruiting -*-
Boston, Massachusetts 02215 US	Recruiting -*-
Aurora, Colorado 80045 US	Opted out`
class NCT03897881 extends React.Component {
  constructor(props) {
    super();
    this.obj = {};
    this.state = {
    city:"",
      state:"",
      country:"",
      showFaq:true,
      faqIcon:faqPlus,
      faqid:0,
      checked:false
    };
  }
  goToEnroll = ()=>{
    window.location.href = `/enroll/${StudyID}/ps?city=${this.state.city}&state=${this.state.state}&country=${this.state.country}`
  }
  onCheckboxChange = (e, qid) => {
    this.setState({checked:null})
    if (e.target.checked) {
      log(`${e.target.checked}, ${e.target.value}, "checking inputs"`);
      this.obj.hasOwnProperty(qid)
        ? this.obj[qid] = [e.target.value]
        : (this.obj[qid] = [e.target.value]);
    } 
    // else {
    //   log(e.target.checked,e.target.value,'checking inputs')
    //   if (this.obj.hasOwnProperty(qid)) {
    //     let arr = this.obj[qid].indexOf(e.target.value);
    //     this.obj[qid].splice(arr, 1);
    //   }
    // }
    log(this.obj+"in melanoma oncheckbox");
  };

  onQualify=()=>{
    let qualify = []
      Object.keys(this.obj).map(uq=>{
        log(this.checkForOneQuestion(uq)+'got uq')
        qualify.push(this.checkForOneQuestion(uq))
      })
      log(qualify+'qualify array')
      if(qualify && qualify.length > 0 ){
        let disqualify = qualify.filter(item=>item.result === false);
        if(disqualify && disqualify.length > 0){
          alert("Unfortunately, based on your answers to the questions, you're not eligible to participate in this trial.")
        } else{
          log("user profile matched");
          this.setState({checked:false},()=>{
            this.obj={}
          })
          this.goToEnroll()
        }
      }
  }

  checkForOneQuestion=(question)=>{
    log(question+'q')
    let gotQues = melanomaQuestions.filter(cmvq=>cmvq.question === question)
    if(gotQues && gotQues.length > 0){
      let checkEligibility = gotQues[0].answers.filter(ans=>ans.option === this.obj[question][0])
      log(checkEligibility+'qualify check');
      return checkEligibility[0]
    }
  }

  faqAnswerList=(list)=>{
    let [first,...remainingList] = list
    return(
      <div className="faq">
          <h3 style={{fontFamily:'LatoRegular'}} className="my-2">{first}</h3>
          {remainingList.map(listitem=>
            <ul className="pl-4">
                <li style={{fontFamily:'LatoRegular'}}>{listitem}</li>
            </ul>
          )}
      </div>
    )
  }

  onExpandFaq=(id)=>{
    this.setState({faqid:id,showFaq:true})
    if(id === this.state.faqid && this.state.showFaq === false){
      this.setState({showFaq:true})
    }
    else if(id === this.state.faqid){
      this.setState({showFaq:false})
    }
  }

  onLocationSelect = (location) => {
    this.setState({ location: location }, () => {
      let splitLocation = this.state.location.split(",");
      let city = splitLocation[0];
      let splitLocationRemaining = splitLocation[1].split(" ");
      log(splitLocationRemaining+"remaining");
      let state = splitLocationRemaining[1];
      let country = splitLocationRemaining[2];
      this.setState({
        city: city,
        state: state,
        country: country,
      });
    });
  };

  onPromoSubmit = () => {
    let ckeys = Object.keys(this.obj);
    let cvalues = Object.values(this.obj);
    if (ckeys && ckeys.length > 0) {
      if (ckeys && ckeys.length < melanomaQuestions.length) {
        alert("Please answer all the eligibility criteria questions. If you press back button, Please reload the page and retry. Thank you!");
      } else {
        if (cvalues && cvalues.length === 0) {
          alert("Please answer all the eligibility criteria questions. If you press back button, Please reload the page and retry. Thank you!");
        } else {
          let val = Object.values(this.obj).filter(
            (qid) => qid && qid.length === 0
          );
          if (val && val.length === 0) {
            this.onQualify()
            this.setState({ criteria: JSON.stringify(this.obj) }, () => {
              log(JSON.stringify(this.obj));
              localStorage.setItem("criteria", JSON.stringify(this.obj));
            });
          } else {
            alert("Please answer all the eligibility criteria questions. If you press back button, Please reload the page and retry. Thank you!");
          }
        }
      }
    } else {
      alert("Please answer all the eligibility criteria questions. If you press back button, Please reload the page and retry. Thank you!");
    }
  };

  goToCriteria=()=>{
    window.location.href="/study/NCT03897881#criteria"
  }


  render() {
      const {faqid,showFaq,checked} = this.state
    return (
      <div>
        {this.props.location &&
        this.props.location.search &&
        this.props.location.search.indexOf("embed=true") !== -1 ? null : (
          <>
            <RecruitmentSteps step={"Review Trials"} />
            <div className="container d-flex align-items-center justify-content-start">
              <ScreenFlow flow={flowarray} />
            </div>
          </>
        )}
        <div className="container">
            <Warning
              subtitle={
                "This trial has completed recruitment on this platform, and is no longer accepting new referrals."
              }
            />
            {/* skin cancer intro */}
            <div className="melanoma my-4">
                <h2 className="my-3">A clinical trial for patients with skin cancer.</h2>
                <h3 className="my-3">An Efficacy Study of Adjuvant Treatment with a Personalized mRNA Cancer Vaccine and Pembrolizumab in Melanoma Patients at High Risk of Cancer Recurrence</h3>
                <p className="my-3 mb-0" style={{color:'#12BBE5'}}>Trial ID: NCT03897881</p>
            </div>
        </div>
        {/* video */}
        <div className="container-fluid melanoma_vid">
            <div className="container py-5 mx-auto text-center">
                <div className="my-2 text-left mx-auto col-sm-12 col-md-12 col-lg-10">
                    <img className="my-2" src={quotationIcon} alt="quotation icon"></img>
                    <h3 className="my-2">Every patient’s cancer is unique. At Moderna, we believe mRNA personalized vaccines may bring us much closer to the goal of helping cure more patients of their cancers</h3>
                    <h4 className="my-2">Tal Zaks, MD</h4>
                </div>
                <div className="my-3 col-lg-12 col-md-12 col-sm-12 col-xs-12 top-right">
                    <iframe className="w-75" title={"skin cancer demo video"} src={"https://www.youtube.com/embed/zM97kg7atWw?autoplay=0"} allow={"autoplay"}></iframe>
                </div>
            </div>
        </div>
        <div className="container">
            {/* trial details */}
            <div className="my-4 melanoma_details">
                <h2 className="my-3">Trial Details</h2>
                <h3 className="my-2">Moderna is creating individualized, mRNA-based personalized cancer vaccines (PCVs) to deliver a customtailored medicine for one patient at a time.</h3>
                <br></br>
                <h3 className="my-2">This study is for people with high risk melanoma (skin cancer), who have had surgery for their skin cancer and are currently disease-free, but who have a high risk of it coming back.</h3>
                <br></br>
                <h3 className="my-2">The purpose of the study is to test whether the combination of the approved immunotherapy, pembrolizumab (also called Keytruda), and the mRNA-4157 personalized cancer vaccine works better than just pembrolizumab to prevent the skin cancer from coming back.</h3>
            </div>
            {/* enroll  */}
            {/* <div className="melanoma_enroll">
                <div className="row mx-auto text-center">
                    <div className="mt-5 col-sm-6 col-md-6 col-lg-6">
                        <h3>Estimated Enrollment</h3>
                        <p className="mb-0">250 Female Participants</p>
                    </div>
                    <div className="mt-5 col-sm-6 col-md-6 col-lg-6">
                        <h3>Phase</h3>
                        <p className="mb-0">2</p>
                    </div>
                </div>
            </div> */}
            {/* eligibility */}
            <div className="cmv_eligibility p-5 my-3"> 
                <div className="row">
                    <div className="ml-auto col-sm-12 col-md-12 col-lg-7 pl-0">
                        <h2>Check Your Eligibility</h2>
                        <p className="mb-0">We'll check to see if you're eligible by asking some questions</p>
                    </div>
                    <div className="">
                        <button onClick={()=>this.goToCriteria()} className="btn btn-danger btn-lg">GET STARTED</button>
                    </div>
                </div>
            </div>
            {/* what to expect */}
            <div className="my-3 melanoma_what">
                <h2 className="my-4">What to expect</h2>
                <h3 className="my-2">If you agree to participate in the study, you will have a screening visit at the study site. At this visit, the study doctor will review your medical history, conduct a physical examination, draw blood and collect a urine sample to confirm if you are eligible to participate. The study doctor will also use a sample of your tumor that was removed in your surgery to see if it can be utilized to make your personalized vaccine.</h3>
                <br></br>
                <h3 className="my-2">During participation, you will need to regularly travel to the study site for up to 3 years. You will have site visits every 3 weeks (21 days) for up to 1 year (approximately 18 visits) to receive study treatment and check your health. After you complete study treatment, you will be asked to complete safety follow-up visits. If your disease has not come back, you will be asked to come in for scans (every 3 to 6 months) for up to 2 years.</h3>
            </div>
            {/* locations */}
            <div className="my-5 locations">
              <h3 className="my-3">
                You will need to travel to one of the{" "}
                {LocationString.split("-*-") &&
                  LocationString.split("-*-").length}{" "}
                sites taking part in this clinical trial, Sites are located in:
              </h3>
              <div className="all_locations border border-light border-rounded">
                <ul>
                  {LocationString.split("-*-").map((location, i) =>
                    this.state.location === location ? (
                      <li
                        key={i}
                        style={{
                          backgroundColor: "darkorange",
                          color: "white",
                        }}
                        // onClick={() => {
                        //   this.setState({ location: location });
                        // }}
                      >
                        {location}
                      </li>
                    ) : (
                      <li
                        key={i}
                        // onClick={() => this.onLocationSelect(location)}
                      >
                        {location}
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
            {/* why participate */}
            <div className="my-3 melanoma_why">
                <h2 className="my-3">Why participate</h2>
                <h3 className="my-3">All patients in this study will receive pembrolizumab (also called Ketruda) and have regular health check-ups with the study doctor at no cost to you.</h3>
                <h3 className="my-3">Pembrolizumab helps your immune system to fight cancer. Pembrolizumab is approved by the FDA for the treatment of patients with certain types of cancer including the adjuvant treatment of patients with melanoma with involvement of lymph node(s) following complete resection.</h3>
                <h3 className="my-3">Some patients on this study will take just pembrolizumab, and some patients will take the combination of both pembrolizumab and mRNA-4157. Giving pembrolizumab and mRNA-4157 together may help the immune system to kill more cancer cells, but it is not known if the combination of pembrolizumab and mRNA-4157 will work any better.</h3>
            </div>
            {/* FAQ'S */}
            <div className="my-3 cmv_faq">
              <h2 className="my-3">Frequently Asked Questions (FAQ'S)</h2>
              {melanomaFaqs.map(faq=>
                  <div onClick={()=>this.onExpandFaq(faq.id)} key={faq && faq.id} className="faq border-bottom my-2 py-3">
                      <div className="d-flex align-items-center justify-content-between">
                          <h2>{faq.question}</h2>
                          <img onClick={()=>this.onExpandFaq(faq.id)} style={{height:25,width:25}} src={showFaq && faqid === faq.id ? cmvCloseIcon : faqPlus} alt="plus"></img>
                      </div>
                      {showFaq && faqid === faq.id ? 
                      <div>
                          {faq.answer.split('-*-') && faq.answer.split('-*-').length === 0 ? 
                              <h3 className="my-2">{faq.answer}</h3> 
                              :
                              this.faqAnswerList(faq.answer.split('-*-'))
                          }
                      </div>
                      : null}
                  </div>
              )}
          </div>
          {/* eligibility criteria */}
          <div id="criteria" className="promo_criteria">
              <h2>Eligibility Criteria</h2>
              {melanomaQuestions.map((question) => (
                <div key={question.id} className="promo_question my-3">
                  <h2>{question.question}</h2>
                  {question.answers.map((answer, i) => (
                    <div key={i} className="promo_answer">
                      <input
                        className="mx-1 mt-2"
                        type={question.type}
                        onChange={(e) =>
                          this.onCheckboxChange(e, question.question)
                        }
                        name={question.question}
                        value={answer.option}
                        checked={checked}
                      />
                      <span className="mx-2">{answer.option}</span>
                    </div>
                  ))}
                </div>
              ))}
            </div>
            {environment === 'DEV' ? (
              <div className="my-3 d-flex align-items-center justify-content-end">
                <button
                  style={{ backgroundColor: "#BDBDBD" }}
                  onClick={() => this.onPromoSubmit()}
                  className="promo_next btn btn-lg"
                  disabled={true}
                >
                  Next
                </button>
              </div>
            ) : (
              <div className="my-3 d-flex align-items-center justify-content-end">
                <button
                  className="promo_next btn btn-lg"
                  style={{ backgroundColor: "#BDBDBD" }}
                  disabled={true}
                >
                  Next
                </button>
              </div>
              )}
        </div>
      </div>
    );
  }
}

export default NCT03897881;
