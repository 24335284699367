import React from "react";
import { DataNotFound, RecruitmentSteps, ScreenFlow } from "../../components";
import {
  enroll_gender,
  enroll_age,
  enroll_rank,
  upcomingStudyIcon,
  template2logo,
  template3qualify,
  greenDot,
  saveButton,
  template2identifier,
  template2status,
  template2site,
  template2gender1,
  template2nctid,
  template3age,
} from "../theme/icons";
import {
  enrollLink,
  getInclusionCriteria,
  getExclusionCriteria,
} from "../../helpers/helper";

const Template3 = (props) => {
  const { trial, searchTerm } = props;

  const flowarray = (studyNumber) => {
    return [
      { title: "Home", link: "/" },
      { title: "Find Trials", link: "/#find_trial" },
      {
        title: "Search Results",
        link: `/results?q=${studyNumber}&in=UNITED%20STATES`,
      },
      {
        title: "Trial Details",
        link: `/preview/${studyNumber}?template=${searchTerm}`,
      },
    ];
  };

  const studyIdentifiers = () => {
    return [
      {
        id:1,
        logo: template2identifier,
        title: (trial && trial.nct_number) || "None",
        subtitle: "Clinical Study Identifier",
      },
      {
        id:2,
        logo: template2site,
        title: (trial && trial.site_name) || "None",
        subtitle: "Site Name",
      },
      {
        id:3,
        logo: template2status,
        title: (trial && trial.status) || "None",
        subtitle: "Trial Status",
      },
    ];
  };

  const studyQualifications = () => {
    return [
      {
        id:1,
        logo: template2gender1,
        title: "Gender",
        subtitle: (trial && trial.sex) || "None",
        bg: "#C9AFD2",
      },
      {
        id:2,
        logo: template3age,
        title: "Maximum Age",
        subtitle: (trial && trial.maximum_age) || "None",
        bg: "#F2D888",
      },
      {
        id:3,
        logo: template3age,
        title: "Minimum Age",
        subtitle: (trial && trial.minimum_age) || "None",
        bg: "#E99781",
      },
    ];
  };

  return (
    <div>
      {trial === null ? (
        <DataNotFound message="Selected template not found" />
      ) : (
        <div>
          <div>
            <div className="container">
              {/* <RecruitmentSteps step={"Review Trials"} /> */}
              <div className="d-flex align-items-center justify-content-start">
                {/* <ScreenFlow
                  flow={flowarray(trial && trial.snow_study_number)}
                /> */}
              </div>
              <div className="template3_main d-flex align-items-center justify-content-center flex-column py-5 my-0">
                    <p className="mb-0 col-4 ml-4">
                      {(trial && trial.brief_title) || "None"}
                    </p>
                    {/* <h6 className="mb-0">Location Details</h6>
                    <p className="my-2 mb-0 site">
                      {(trial && trial.site_name) || "None"}
                    </p>
                    <p className="mb-0">
                      {(trial && trial.street_address) || "None"},{" "}
                      {(trial && trial.city) || "None"}
                    </p>
                    <p className="mb-0">
                      {(trial && trial.state) || "None"},{" "}
                      {(trial && trial.country) || "None"},{" "}
                      {(trial && trial.pin_code) || "None"}
                    </p> */}
                    <button
                      onClick={() =>{
                        if(trial && trial.active === "true" && trial.promote === "true"){
                          enrollLink(trial && trial.snow_study_number)
                        }
                      }
                      }
                      disabled = {(trial && trial.active === "true" && trial.promote === "true") ? false : true}
                      className="upcoming_signup btn btn-lg my-3"
                    >
                      Sign Up
                    </button>
              </div>
              <div className="col-lg-10 text-left px-0">
                <div className="row text-left template2_title">
                  <div className="d-flex align-items-center justify-content-center col-xs-12">
                      <img className="mt-1" style={{height:17,width:17}} src={greenDot} alt="dot"></img>
                      <p
                        className="mb-0 pl-2 pr-5 template2_study_number"
                      >
                        {(trial && trial.snow_study_number) || "None"}
                      </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-center col-xs-12">
                      <p className="mb-0 mx-lg-5 mx-md-5 mx-sm-5">
                        {(trial && trial.study_name) || "None"}
                      </p>
                      <img src={saveButton} alt="savetag"></img>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 px-3 my-3">
                <div className="row my-5 text-left">
                  {studyIdentifiers().map((studyIdentifier) => (
                    <div className="col-lg-4 px-lg-5">
                      <div className="d-flex align-items-center justify-content-start">
                        <img
                          style={{ height: 90, width: 85 }}
                          src={studyIdentifier.logo}
                          alt={"identiferlogo"}
                        ></img>
                        <div className="template2_identifier mx-3">
                        <p className="mb-0">{studyIdentifier.subtitle}</p>
                          <h6>{studyIdentifier.title}</h6>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <div className="upcoming_purpose my-3">
                  <h2 className="my-3">Purpose</h2>
                  <p style={{ fontWeight: "normal" }} className="mb-0">
                    {(trial && trial.brief_summary) || "None"}
                  </p>
                </div>
                <br />
                <div className="upcoming_purpose my-3">
                  <h2 className="my-3">Description</h2>
                  <p style={{ fontWeight: "normal" }} className="mb-0">
                    {(trial && trial.detailed_description) || "None"}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="upcoming_qualify mb-3 col-lg-6">
                  <h3 className="my-2">Who Qualifies?</h3>
                  <img className="template3_qualify_logo" src={template3qualify} alt="qualify_logo"></img>
                </div>
                <div className="col-lg-6 template3qualify_right p-5">
                    <div className="template3_nctid d-flex align-items-center justify-content-center py-2">
                        <p className="mb-0">Study Id</p>
                        <span className="mx-lg-5 mx-md-5 mx-sm-5 mx-xs-3">-</span>
                        <h6>{(trial && trial.snow_study_number) || "None"}</h6>
                    </div>
                    <div className="text-left px-0">
                        {studyQualifications().map(studyQualification=>
                            <div className="d-flex align-items-center justify-content-start">
                                <div
                                style={{ backgroundColor: studyQualification.bg, borderRadius: 40 }}
                                className="template2qualify_logo p-2 my-2"
                                >
                                    <div className="mx-auto text-center">
                                        <img style={{ height: studyQualification.id === 2 || studyQualification.id === 3 ? "18px"  : "auto" }} className = {studyQualification.id === 2 || studyQualification.id === 3 ? "mt-3" : "mt-2 pt-1"} src={studyQualification.logo} alt="qualifylogo"></img>
                                    </div>
                                </div>
                                <div className="template2qualify_content d-flex align-items-center justify-content-between ml-3">
                                    <h6 className="mx-1 mb-0">{studyQualification.title}</h6>
                                    <span className="mx-1 mb-0">-</span>
                                    <p className="mx-1 mb-0">{studyQualification.subtitle}</p>
                                </div>
                            </div>
                        )}
                    </div>
                    <div>

                    </div>
                </div>
              </div>
              <div className="col-lg-12">
                  <div className="upcoming_purpose">
                    <h3 className="my-2 upcoming_criteria">Criteria</h3>
                    <h2 className="my-3">Inclusion Criteria</h2>
                    <p style={{ fontWeight: "normal" }} className="mb-0">
                      {getInclusionCriteria(trial && trial.eligibility)}
                    </p>
                  </div>
                  <div className="my-2 upcoming_purpose">
                    <h2 className="my-3">Exclusion Criteria</h2>
                    <p style={{ fontWeight: "normal" }} className="mb-0">
                      {getExclusionCriteria(trial && trial.eligibility)}
                    </p>
                  </div>
                </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Template3;
