import React from "react";
import "../../../App.css";
import axios from "axios";
import { Domain } from "../../../constants";
import { withRouter } from "react-router-dom";
import { getQuestionGroup } from "../../../helpers";
import { ScopeER, PSER } from "./index";
import { log } from "../../../helpers/helper";
class EnrollmentRequirements extends React.Component {
  constructor(props) {
    super();
    this.state = {
      type: "",
      criteria: null,
      trialId: "",
      studySysId: "",
      questions: [],
      isLoading: false,
    };
  }
  onCheckboxChange = (e) => {
    log(`${e.target.name}, ${e.target.checked}, ${e.target.value}, "checking inputs"`);
  };

  optionChange = (e) => {
    log(`${e.target.name}, ${e.target.value}`);
    this.setState({ [e.target.name]: e.target.value });
  };

  goToEnroll = ()=>{
    window.location.href=`/enroll/${this.state.trialId}/`
  }

  onEnroll = () => {
    if (Object.keys(this.state).length < 6) { 
      if(this.state.questions && this.state.questions.length === 0){
        log('no pre-screening questions found')
        this.goToEnroll()
      }else{
      alert("Please fill all pre-screening questions");
      }
    }
    else if (this.state.questions && this.state.questions.length > 0) {
      this.state.questions.map((ques) => {
       return axios
          .get(
            `${Domain}backend/submit?answer=${this.state[ques.sys_id]}&sys_id=${
              ques.sys_id
            }`
          )
          .then((suc) => {
            log(`${suc}, "submitted answers"`);
            this.goToEnroll()
          })
          .catch((e) => {
            log("error submitting answers");
            this.goToEnroll()
          });
      });
    }
    else{
      this.goToEnroll()
    }
  };

  componentDidMount() {
    this.setState(
      {
        type: this.props.type,
        criteria: this.props.criteria,
        trialId: this.props.sysId,
        studySysId: this.props.studySysId,
      },
      () => {
        getQuestionGroup(this.state.studySysId,this.state.trialId)
          .then((qG) => {
            log(`${qG}, "qG"`);
            this.setState({ questions: qG && qG.questions, isLoading: true });
          })
          .catch((errQG) => {
            log(`${errQG}, "errQG"`);
            this.setState({ questions: [], isLoading: true });
          });
      }
    );
  }

  renderER = () => {
    const { type, criteria, trialId, questions, isLoading } = this.state;
    return(
      <div>
          {trialId && trialId.startsWith("STU") === true ? 
              <PSER
              criteria={criteria}
              onCheckboxChange={this.onCheckboxChange}
              sysId={trialId}
              city={this.props.city}
              state={this.props.state}
              country={this.props.country}
              zipcode={this.props.zipcode}
              questions={questions}
              isLoading={isLoading}
              optionChange={this.optionChange}
              onEnroll={this.onEnroll}
            />
            :
            (
              trialId && trialId.startsWith("NCT") === true ?
              <ScopeER
              criteria={criteria}
              onCheckboxChange={this.onCheckboxChange}
              type={type}
              nctId={trialId}
              city={this.props.city}
              state={this.props.state}
              country={this.props.country}
              zipcode={this.props.zipcode}
            />
              :
              <div className="text-center">
                <h2>No Criteria Found</h2>
              </div>
            )
          }
      </div>
    )
  };

  render() {
    return (
      <div className="enrollment_requirements text-left">{this.renderER()}</div>
    );
  }
}
export default withRouter(EnrollmentRequirements);
