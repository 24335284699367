import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { log } from "../../../helpers/helper";

const ScopeER = (props) => {
  const [color, changeBgColor] = useState("#BDBDBD;");
  const { criteria, onCheckboxChange, nctId, type,city,state,country,zipcode } = props;
  const splitCriteria =
    criteria &&
    criteria.split("Exclusion Criteria:") &&
    criteria.split("Exclusion Criteria:").length === 0
      ? null
      : criteria &&
        criteria.split("Exclusion Criteria:") &&
        criteria.split("Exclusion Criteria:");
  let youMust =
    splitCriteria &&
    splitCriteria[0] &&
    splitCriteria[0].replace("Inclusion Criteria:", "").trim();
  let youNotMust =
    splitCriteria &&
    splitCriteria[1] &&
    splitCriteria[1].replace("Exclusion Criteria:", "").trim();
  return (
    <div>
      <h2>Enrollment Requirements</h2>
      <div className="you_must">
        <h3>You must</h3>
        {log(`${youNotMust}, "notmust"`)}
        {(youMust && youMust.length === 0) ||
        youMust === null ||
        youMust === undefined ? (
          <h2 className="text-center">
            {"No Inclusion Criteria found for this trial"}
          </h2>
        ) : youMust.split("- ") && youMust.split("- ").length > 0 ? (
          youMust.split("- ").map((inclusion,i) =>
            inclusion === "" ? null : (
              <div key={i} className="checkbox d-flex align-items-start justify-content-start">
                <input
                  className="mx-1 mt-2"
                  type="checkbox"
                  onChange={onCheckboxChange}
                  name={inclusion}
                  value={inclusion}
                />
                <p className="mx-1 mb-0">{inclusion}</p>
              </div>
            )
          )
        ) : (
          <h2 className="text-center">None</h2>
        )}
      </div>
      <div className="you_must">
        <h3>You must not have</h3>
        {log(`${youMust}, "notmust"`)}
        {(youNotMust && youNotMust.length === 0) ||
        youNotMust === null ||
        youNotMust === undefined ? (
          <h2 className="text-center">
            {"No Inclusion Criteria found for this trial"}
          </h2>
        ) : youNotMust.split("- ") && youNotMust.split("- ").length > 0 ? (
          youNotMust.split("- ").map((exclusion,i) =>
            exclusion === "" ? null : (
              <div key={i} className="checkbox d-flex align-items-start justify-content-start">
                <input
                  className="mx-1 mt-2"
                  type="checkbox"
                  onChange={onCheckboxChange}
                  name={exclusion}
                  value={exclusion}
                />
                <p className="mx-1 mb-0">{exclusion}</p>
              </div>
            )
          )
        ) : (
          <h2 className="text-center">None</h2>
        )}
      </div>
      <div className="my-2 d-flex align-items-center justify-content-end">
        <a
          href={`/enroll/${nctId}/${type}?city=${city}&state=${state}&country=${country}`}
          style={{ backgroundColor: color }}
          className="next mb-0 btn btn-danger btn-lg"
        >
          <h3 className="mb-0">NEXT</h3>
        </a>
      </div>
    </div>
  );
};

export default withRouter(ScopeER);
