import React from "react";
import {
  ScreenFlow,
  RecruitmentSteps,
} from "../../components";

const flowarray = [
  { title: "Home", link: "/" },
  { title: "Find Trials", link: "/#find_trial" },
  {
    title: "Search Results",
    link: `/results?q=NCT04528719&in=UNITED%20STATES`,
  },
  {
    title: "Trial Details",
    link: `/study/NCT04528719`,
  },
];

class NCT04528719 extends React.Component {
  
  render() {
    return (
      <div>
        {this.props.location &&
        this.props.location.search &&
        this.props.location.search.indexOf("embed=true") !== -1 ? null : (
          <>
            <RecruitmentSteps step={"Review Trials"} />
            <div className="container d-flex align-items-center justify-content-start">
              <ScreenFlow flow={flowarray} />
            </div>
          </>
        )}
        <div className="container">
          <iframe style={{height:2200,width:'100%',border:'none'}} title="NCT04528719" src="https://connect.trialscope.com/studies/81c2f468-ba75-403b-9ac8-24c0c3234071?embed=1"></iframe>
        </div>
      </div>
    );
  }
}

export default NCT04528719;
