import React from "react";
import { PaginationLeft, PaginationRight } from "../features/theme/icons";

const Pagination = (props) => {
  const { arr, onPagination, pagenumber } = props;
  return (
    <div className="pagination col-sm-3 d-flex align-items-center justify-content-start">
      <span>
        <img src={PaginationLeft} alt="pagination-left-icon"></img>
      </span>
      {arr.map((count,i) =>
        count === pagenumber ? (
          <span key={i} style={{backgroundColor:'#006D9C',color:'white'}} onClick={() => onPagination(count)}>{count}</span>
        ) : (
          <span key={i} onClick={() => onPagination(count)}>{count}</span>
        )
      )}
      <span>
        <img src={PaginationRight} alt="pagination-right-icon"></img>
      </span>
    </div>
  );
};

export default Pagination;
