import React from "react";

const Nurse = () =>{
    return(
        <div className="my-5 py-5 container">
            <h6>Nurse Concierge</h6>
        </div>
    )
}

export default Nurse