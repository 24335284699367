import React, { useState, useEffect } from 'react';
import { log } from '../helpers/helper';

const PreScreen = ({ questions, optionChange, onEnroll }) => {
  const [order_questions, set_order_questions] = useState([])


  useEffect(()=>{
    if(questions && questions.length > 0){
      // let q_order = []
      // log(questions, 'qs in prescreening')
      let check_for_order_empty = questions.filter(q => (q && q.order === "") || (q && q.order === " ") || (q && q.order === null) || (q && q.order === undefined))
      log(check_for_order_empty,'order related')
      if(check_for_order_empty && check_for_order_empty.length === 0){
        
        let questions_order = questions.map(q=> parseInt(q.order)).sort()
        // log(questions_order)
        if(questions_order && questions_order.length > 0){
          let q_order = questions.sort((a, b) => parseFloat(a.order) - parseFloat(b.order));
          log(q_order,'q_order')
          // questions_order.map(item => {
          //   let filter_question = questions.filter(ques => parseInt(ques.order) === item)
          //   if(filter_question && filter_question.length > 0){
          //     q_order.push(filter_question[0])
          //   }else{
          //     // log('err while ordering questions', filter_question)
          //   }
          // })
          // log(q_order,'ordered questions')
          set_order_questions(q_order)
        } else{
          // log('error fetching order')
          set_order_questions(questions)
        }
      }else{
        questions.sort((q1, q2) =>
                          new Date(q1.sys_created_on).getTime() -
                          new Date(q2.sys_created_on).getTime()
                      )
        set_order_questions(questions)
      }
      // questions.map(ques =>{
      //   if((ques && ques.order === "") || (ques && ques.order === " ") || (ques && ques.order === null) || (ques && ques.order === undefined)){
      //     ques.order = 0
      //   }
      // })
    }else{
      set_order_questions([])
    }
  },[])

    return (
      <div className="prescreen">
        <h2>Pre-screening Questions</h2>
        {questions && questions.length === 0 ? (
          <p>No Pre-screening Questions Found</p>
        ) : (
          questions.map((question) => (
            <div className="question my-3" key={question.sys_id}>
              <h3>{question.question}</h3>
              <div className="d-flex align-items-center justify-content-start">
                <div className="prescreen_option mx-2 form-check">
                  <input
                    type="radio"
                    onChange={optionChange}
                    value={"true"}
                    name={question.sys_id}
                    className="form-check-input"
                    style={{cursor:'pointer'}}
                  />{" "}
                  <span>True</span>
                </div>
                <div className="prescreen_option mx-2 form-check">
                  <input
                    type="radio"
                    onChange={optionChange}
                    value={"false"}
                    name={question.sys_id}
                    className="form-check-input"
                    style={{cursor:'pointer'}}
                  />{" "}
                  <span>False</span>
                </div>
              </div>
            </div>
          ))
        )}
          <button onClick={onEnroll} className="btn btn-lg btn-primary">
            NEXT
          </button>
      </div>
    );
  };


  export default PreScreen