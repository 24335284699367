import React from "react";
import "../../App.css";
import "../../bootstrap.css";
import {
  greenDot,
  viewMore,
  ctFaviLogo,
} from "../../features/theme/icons";

const Card = (props) => {
  const {
    phase,
    condition,
    locationCount,
    locationZipcode,
    title,
    description,
    trialSponsor,
    trialsGoal,
    onCTCardClick,
    nctId,
  } = props;
  return (
    <div
      onClick={() => onCTCardClick(nctId)}
      style={{
        filter: "drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.15))",
        cursor: "pointer",
      }}
      className="card border rounded py-3 pl-0 mx-0 my-3"
    >
      <div className="d-flex align-items-start justify-content-between">
        <div className="mx-3">
          <img className="mx-2" src={greenDot} alt="greendot"></img>
          <span className="mx-1">{phase}</span>
          {/* <span className="mx-1">{"|"}</span> */}
          {/* {condition && condition.length > 0 ? (
            condition.length < 2 ? (
              condition.map((disease, i) => (
                <span key={i} className="mx-1">
                  {disease && disease.length > 30 ? disease.slice(0,30)+'...' : disease}
                </span>
              ))
            ) : (
              <span>
                {condition[0].slice(0, 10) + "..."}, {condition.length - 1}
                {"more ..."}
              </span>
            )
          ) : (
            <span className="mx-1">{"None"}</span>
          )} */}
        </div>
        <div className="mr-4">
          <img
            src={ctFaviLogo}
            alt="ctfavicon"
            className="ptrlogo"
          ></img>
          <div className="partnertext">{"https://clinicaltrials.gov/"}</div>
          {/* <img src={bookmark_inactive} alt="select tag"></img> */}
        </div>
      </div>
      <div className="row card_description ml-3">
        <div className="col-sm-12 col-md-8 col-lg-8">
          <h3 className="my-3">{title}</h3>
          <p className="my-3">{description}</p>
        </div>
        <div className="card_location col-sm-11 col-md-3 col-lg-3">
          <div className="card_right_border border-bottom mb-1">
            <h4>LOCATION</h4>
            <h5 style={{ fontSize: 13 }}>
              <span>{locationCount}</span> locations near the US, zip code{" "}
              <span>{locationZipcode}</span>
            </h5>
          </div>
          <div className="card_right_border border-bottom mb-1">
            <h4>Gender</h4>
            <h5>{trialsGoal}</h5>
          </div>
          <div className="mb-1">
            <h4>Study Type</h4>
            <h5>{trialSponsor}</h5>
          </div>
        </div>
        <div className="col-sm-1 col-md-1 col-lg-1  p-0 m-0 mt-lg-5 mt-md-5 mt-sm-5 d-flex align-items-center justify-content-end">
          <img className="mt-lg-5 mt-md-5 mt-sm-5" src={viewMore} alt="view more"></img>
        </div>
      </div>
    </div>
  );
};

export default Card;
