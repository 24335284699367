import React from "react";
import "../../App.css";
import {
  RecruitmentSteps,
  ScreenFlow,
  SuggestedSearches,
  CTCard,
  Loading,
  PTCard,
  Sidebar,
  Pagination,
} from "../../components";

// const StudiesCount = ({ num, image }) => {
//   return num && num.length === 0 ? null : (
//     <h5 style={{ color: "#DC741B" }} className="my-4">
//       {num}
//       {/* <span>
//         <img
//           className="mx-1"
//           style={{ height: 50, width: "auto" }}
//           src={image}
//           alt="trials logo"
//         ></img>
//       </span> */}{" "}
//       clinical trials found.
//     </h5>
//   );
// };

const ResultsView = (props) => {
  const {
    isLoading,
    trialscope,
    ourfilteredstudies,
    applyFiltersChange,
    clearAllSidebarFilters,
    condition,
    country,
    onCTCardClick,
    onPTCardClick,
    onSuggestionClick,
    history,
    showPagination,
    filterCheckboxChange,
    selectedFilters,
    onPagination,
    pagenumber,
    ctstudies,
    hideSteps,
    allTrials,
  } = props;

  const ptFilteredStudies = ourfilteredstudies;
  const flowarray = [
    { title: "Home", link: "/" },
    { title: "Find Trials", link: "/#find_trial" },
    {
      title: "Search Results",
      link: `/results?q=${condition}&in=${country}`,
    },
  ];

  const RenderResults = () => {
    return (
      <div className="container px-0">
        <div className="row results">
          <div className="col-sm-12 col-md-12 col-lg-12 pl-0">
            <Sidebar
              country={country}
              condition={condition}
              filterCheckboxChange={filterCheckboxChange}
              selectedFilters={selectedFilters}
              clearAllSidebarFilters={clearAllSidebarFilters}
              applyFiltersChange={applyFiltersChange}
            />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 results_right">
            <h2 className="mt-1 px-2">
              A listing of @ medical research trials actively recruiting patient
              volunteers. Adjust your search filter to quickly matched the right
              trials for your needs in your area.
            </h2>
            <div className="px-2">
                <SuggestedSearches
                  onSuggestionClick={onSuggestionClick}
                  history={history}
                />
            </div>
            <div>
              {showPagination ? (
                <Pagination
                  arr={[1, 2, 3, 4]}
                  onPagination={onPagination}
                  studies={trialscope}
                  pagenumber={pagenumber}
                />
              ) : null}
            </div>

            <div className="px-2">
              {/* <StudiesCount
                num={ptFilteredStudies && ptFilteredStudies.length}
                image={logo}
              /> */}
              <h6>Number of Clinical Trials - {allTrials &&  allTrials.length}</h6>
              {allTrials && allTrials.length === 0 ? (
                <h2 className="text-center">
                  {"Studies not found"}
                </h2>
              ) : allTrials && allTrials.length > 0 ? (
                allTrials.map((study, i) => (
                  study && study.partner_type === "patientrials" ? 
                  <PTCard
                    key={i}
                    nctNumber={study && study.nct_number}
                    snow_study_number={study && study.snow_study_number}
                    phase={
                      study &&
                      study.study_phase &&
                      study.study_phase.length > 0
                        ? study.study_phase
                        : "None"
                    }
                    condition={
                      study &&
                      study.conditions &&
                      study.conditions.length > 0
                        ? study.conditions
                        : "None"
                    }
                    locationCount={1}
                    study_sponsor_url={study && study.study_sponsor_url}
                    locationZipcode={study && study.pin_code}
                    title={
                      `${study && study.official_title}`.length > 0
                        ? `${study && study.official_title}`.length > 60
                          ? `${study && study.official_title}`.slice(
                              0,
                              100
                            ) + "..."
                          : `${study && study.official_title}`
                        : study && study.brief_title
                    }
                    status={study && study.status}
                    sex={study && study.sex}
                    maximum_age={study && study.maximum_age}
                    minimum_age={study && study.minimum_age}
                    site_name={study && study.site_name}
                    study_name={study && study.study_name}
                    description={
                      `${study && study.brief_summary}`.length > 0
                        ? `${study && study.brief_summary}`.length > 60
                          ? `${study && study.brief_summary}`.slice(
                              0,
                              300
                            ) + "..."
                          : `${study && study.brief_summary}`
                        : "None"
                    }
                    // trialSponsor={"Grady Memorial Hospital"}
                    // trialsGoal={"Diagnostic, Treatment"}
                    onPTCardClick={onPTCardClick}
                    //sysId={ptstudy && ptstudy.sys_id}
                    sysId={study && study.snow_study_number}
                    staticLink={study && study.static_recruitment_link}
                    vendor={study && study.vendor}
                    embeddedLink={study && study.embedded_link}
                  />
                  :
                  <CTCard
                          key={i}
                          phase={
                            study && study.NCTId
                              ? (study && study.NCTId === "N/A") ||
                                (study && study.NCTId === "None")
                                ? study && study.NCTId
                                : study && study.NCTId
                              : study && study.BriefTitle.slice(0, 20) + "..."
                          }
                          condition={
                            study && study.Condition ? study.Condition : "None"
                          }
                          locationCount={
                            study &&
                            study.LocationZip &&
                            study.LocationZip.length > 0
                              ? study.LocationZip.length
                              : 0
                          }
                          locationZipcode={"#"}
                          title={
                            `${study && study.BriefTitle}`.length > 60
                              ? `${study && study.BriefTitle}`.slice(0, 30) +
                                "..."
                              : `${study && study.BriefTitle}`
                          }
                          description={
                            `${study && study.BriefSummary}`.length > 60
                              ? `${study && study.BriefSummary}`.slice(0, 100) +
                                "..."
                              : `${study && study.BriefSummary}`
                          }
                          trialSponsor={study && study.StudyType}
                          trialsGoal={study && study.Gender}
                          onCTCardClick={onCTCardClick}
                          nctId={study && study.NCTId}
                        />
                ))
              ) : (
                <h2 className="text-center">{"Loading ..."}</h2>
              )}
              {/* <StudiesCount
                num={trialscope && trialscope.length}
                image={trialscope_logo}
              /> */}
              {/* {trialscope && trialscope.length > 0
                ? trialscope.map((trial, i) => (
                    <TSCard
                      key={i}
                      phase={
                        trial && trial.node && trial.node.phase
                          ? trial.node.phase === "N/A" ||
                            trial.node.phase === "None"
                            ? trial.node.nctId
                            : trial.node.phase
                          : "None"
                      }
                      condition={
                        trial && trial.node && trial.node.conditions
                          ? trial.node.conditions
                          : "None"
                      }
                      locationCount={
                        trial && trial.node && trial.node.sites
                          ? trial.node.sites.length
                          : 0
                      }
                      locationZipcode={"#"}
                      title={
                        `${trial && trial.node && trial.node.title}`.length > 60
                          ? `${trial && trial.node && trial.node.title}`.slice(
                              0,
                              100
                            ) + "..."
                          : `${trial && trial.node && trial.node.title}`
                      }
                      description={
                        `${trial && trial.node && trial.node.description}`
                          .length > 60
                          ? `${
                              trial && trial.node && trial.node.description
                            }`.slice(0, 180) + "..."
                          : `${trial && trial.node && trial.node.description}`
                      }
                      trialSponsor={trial && trial.node && trial.node.studyType}
                      trialsGoal={trial && trial.node && trial.node.gender}
                      onTSCardClick={onTSCardClick}
                      nctId={trial && trial.node && trial.node.nctId}
                    />
                  ))
                : null
                  // <DataNotFound message={"No Results Found. Please try again."} />
              } */}
            </div>
            {/* <StudiesCount
              num={ctstudies && ctstudies.length}
              image={clinical_trials_logo}
            /> */}
            {/* <div className="d-flex">
              <div
                className="mx-0 "
              >
                {ctstudies && ctstudies.length > 0
                  ? ctstudies.map((trial, i) => (
                      <div key={i} className="p-0">
                        <CTCard
                          phase={
                            trial && trial.NCTId
                              ? (trial && trial.NCTId === "N/A") ||
                                (trial && trial.NCTId === "None")
                                ? trial && trial.NCTId
                                : trial && trial.NCTId
                              : trial && trial.BriefTitle.slice(0, 20) + "..."
                          }
                          condition={
                            trial && trial.Condition ? trial.Condition : "None"
                          }
                          locationCount={
                            trial &&
                            trial.LocationZip &&
                            trial.LocationZip.length > 0
                              ? trial.LocationZip.length
                              : 0
                          }
                          locationZipcode={"#"}
                          title={
                            `${trial && trial.BriefTitle}`.length > 60
                              ? `${trial && trial.BriefTitle}`.slice(0, 30) +
                                "..."
                              : `${trial && trial.BriefTitle}`
                          }
                          description={
                            `${trial && trial.BriefSummary}`.length > 60
                              ? `${trial && trial.BriefSummary}`.slice(0, 100) +
                                "..."
                              : `${trial && trial.BriefSummary}`
                          }
                          trialSponsor={trial && trial.StudyType}
                          trialsGoal={trial && trial.Gender}
                          onCTCardClick={onCTCardClick}
                          nctId={trial && trial.NCTId}
                        />
                      </div>
                    ))
                  : // <DataNotFound
                    //   message={"No Results Found. Please try again."}
                    // />
                    null}
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {hideSteps === true ? null : (
        <>
          <RecruitmentSteps step={"Find a trial"} />
          <div className="container d-flex align-items-center justify-content-start">
            <ScreenFlow flow={flowarray} />
          </div>
        </>
      )}
      {isLoading ? <RenderResults /> : <Loading />}
    </div>
  );
};
export default ResultsView;
