import React from "react";
import { Gif1, Gif2, Gif3, Gif4, Gif5, Gif6 } from "../features/theme/icons";
import { log } from "../helpers/helper";

const components = [
  { item: Gif4, hideMargin: false },
  { item: Gif1, hideMargin: false },
  { item: Gif5, hideMargin: true },
  { item: Gif3, hideMargin: false },
  { item: Gif2, hideMargin: false },
  { item: Gif6, hideMargin: true },
];

class Loading extends React.Component {
  intervalid = 0;
  constructor(props) {
    super();
    this.state = {
      counter: 0,
      hide: false,
      showModal: false,
    };
  }

  componentDidMount() {
    this.intervalid = setInterval(() => {
      log("changing counter value");
      if (this.state.counter >= 5) {
        this.setState({ counter: 0 });
      } else this.setState({ counter: this.state.counter + 1 });
    }, 3000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalid);
  }
  render() {
    const { counter } = this.state;
    return (
      <div className="d-flex align-items-center justify-content-center mt-4">
        <img
          alt="loading icons"
          src={components[counter].item}
          className={`${components[counter].hideMargin === true ? "" : "my-5 py-5"}`}
        ></img>
        {/* <h3 className="my-5">Advertise Your Study Here ...</h3> */}
      </div>
    );
  }
}

export default Loading;
