import React from "react";
import "../../App.css";
import axios from "axios";
import { Domain } from "../../constants";
import {
  getPatientrialsStudies,
  getSuggestions,
  filterPTstudies,
  getClinicalTrials,
  getTenResults,
} from "../../helpers";
import ResultsView from "./ResultsView";
import { log } from "../../helpers/helper";

class ResultsController extends React.Component {
  constructor(props) {
    super();
    this.HScroller = React.createRef();
    this.selectedFilters = {};
    this.state = {
      error: false,
      isLoading: false,
      output: [],
      trialscope: [],
      showtrialscope: false,
      latestoutput: [],
      ourstudies: [],
      ctstudies: [],
      ourfilteredstudies: [],
      selectedfilters: [],
      condition: "",
      country: "UNITED%20STATES",
      norecords: false,
      allelements: [],
      checkboxitems: [],
      resultdata: null,
      option: "",
      showPagination: false,
      paginationstudies: [],
      pagenumber: 1,
      allTrials: [],
    };
  }
  left = () => {
    this.HScroller.current.scrollLeft -= 300;
  };
  right = () => {
    //log(`${this.HScroller},'scroller'`)
    this.HScroller.current.scrollLeft += 300;
  };
  // checkNCTNumber = ()=>{
  //   let inputCondition = this.state.condition;
  //   if(inputCondition.startsWith("NCT") === true){
  //     getTrialId('ts',this.state.condition).then(gotStudy=>{
  //       log(`${gotStudy}, "got trialscope study with nctid"`);
  //       // this.setState(
  //       //   {
  //       //     trialscope: [gotStudy && gotStudy.trial]
  //       //   })
  //     }).catch(errStudy=>{
  //       log(`${errStudy}, "error with trialscope study with nctid"`);
  //       this.setState(
  //         {
  //           trialscope: []
  //         })
  //     })
  //   } else{
  //     this.getResults()
  //   }
  // }
  fetchOurStudies = () => {
    getPatientrialsStudies(this.state.condition)
      .then((ptstudies) => {
        //log(`${ptstudies}, "ptstudies"`);
        if (ptstudies.error) {
          alert(ptstudies.message);
        } else if (!ptstudies.error) {
          this.setState(
            { ourstudies: ptstudies && ptstudies.output, isLoading: true },
            () => {
              let os = this.state.ourstudies;
              //log(`${os}, "os"`);
              filterPTstudies(os, this.state.condition.trim())
                .then((ofs) => {
                  //log(ofs, 'ofs')
                  ofs.ourfilteredstudies.map(
                    (study) => (study["partner_type"] = "patientrials")
                  );
                  this.setState(
                    {
                      ourfilteredstudies: ofs.ourfilteredstudies,
                      norecords: ofs.norecords,
                    },
                    () => {
                      this.setState({
                        allTrials: this.state.ourfilteredstudies,
                        isLoading: true,
                      });
                      this.fetchClinicalTrials();
                    }
                  );
                })
                .catch((errofs) => {
                  //log(`${errofs}, "error getting pt filtered studies"`);
                  this.setState(
                    { ourfilteredstudies: [], isLoading: true },
                    () => {
                      this.fetchClinicalTrials();
                    }
                  );
                });
            }
          );
        }
      })
      .catch((errptstudies) => {
        // log(
        //   `${errptstudies},
        //   "errptstudies - error getting patientrials studies"`
        // );
        this.setState({ ourfilteredstudies: [], isLoading: true }, () => {
          this.fetchClinicalTrials();
        });
      });
  };

  getOnly20Items = (items) =>{
    let list = []
    if(items && items.length > 0){
      items.map((item, id)=>{
        if(id > 20){
          return
        }else{
          list.push(item)
        }
      })
      return list
    }else{
      return [] 
    }
  }

  fetchClinicalTrials = () => {
    //this.setState({ isLoading: false });
    getClinicalTrials(this.state.condition)
      .then((gotStudies) => {
        //log(gotStudies, "got ctg studies");
        let ct_studies = gotStudies;
        if (ct_studies && ct_studies.length > 0) {
          let filterCTGstudies = ct_studies.filter((study) =>
            this.state.country === "UNITED STATES"
              ? (study.OverallStatus === "RECRUITING" ||
                study.OverallStatus === "Recruiting" ||
                  study.OverallStatus === "recruiting") &&
                (study.LocationCountry.startsWith("United States") ===
                  true ||
                  study.LocationCountry.startsWith(
                    "United States of America"
                  ) === true ||
                  study.LocationCountry.startsWith("united states") ===
                    true ||
                  study.LocationCountry.startsWith(
                    "united states of america"
                  ) === true ||
                  study.LocationCountry.startsWith("USA") === true ||
                  study.LocationCountry.startsWith("usa") === true)
              : (study.OverallStatus === "Recruiting" ||
                  study.OverallStatus === "recruiting") &&
                (study.LocationCountry.startsWith(
                  this.state.country.toLowerCase()
                ) === true ||
                  study.LocationCountry.startsWith(
                    this.state.country.toLowerCase()
                  ) === true)
          );
          log(filterCTGstudies,'filtered ct studies')
          this.setState(
            {
              ctstudies:
                filterCTGstudies && filterCTGstudies.length > 0
                  ? filterCTGstudies.length > 20 ? this.getOnly20Items(filterCTGstudies) : filterCTGstudies
                  : [],
            },
            () => {
              this.setState({
                allTrials: [
                  ...this.state.ourfilteredstudies,
                  ...this.state.ctstudies,
                ],
                isLoading: true,
              });
            }
          );
        } else {
          this.setState({ ctstudies: [] }, () => {
            this.setState({
              allTrials: [...this.state.ourfilteredstudies],
              isLoading: true,
            });
          });
        }
      })
      .catch((errStudies) => {
        //log(errStudies, "err fetching ctg studies");
        this.setState({ ctstudies: [] }, () => {
          this.setState({
            allTrials: [...this.state.ourfilteredstudies],
            isLoading: true,
          });
        });
      });
  }

  clearAllSidebarFilters = () => {
    this.selectedFilters = {};
    this.setState({
      allTrials: [...this.state.ourfilteredstudies, ...this.state.ctstudies],
    });
  };

  filterCheckboxChange = (e) => {
    if (e.target.checked) {
      if (e.target.name === "Clear All") {
        this.clearAllSidebarFilters();
      }
      //log(`${e.target.checked}, ${e.target.value}, "checking filter inputs"`);
      this.selectedFilters.hasOwnProperty(e.target.name)
        ? this.selectedFilters[e.target.name].push(e.target.value)
        : (this.selectedFilters[e.target.name] = [e.target.value]);
    } else {
      //log(`${e.target.checked}, ${e.target.value}, "checking filter inputs"`);
      if (e.target.name === "Clear All") {
        delete this.selectedFilters["Clear All"];
      } else if (this.selectedFilters.hasOwnProperty(e.target.name)) {
        if (
          this.selectedFilters[e.target.name] &&
          this.selectedFilters[e.target.name].length > 1
        ) {
          let arr = this.selectedFilters[e.target.name].indexOf(e.target.value);
          this.selectedFilters[e.target.name].splice(arr, 1);
        } else {
          delete this.selectedFilters[e.target.name];
        }
      }
    }
    //log(this.selectedFilters, "in filters oncheckbox");
    this.setState({ selectedfilters: this.selectedFilters[e.target.name] });
  };

  getOption = (conditions, options) => {
    let length = options && options.length;
    if (length === 1) {
      return conditions.toLowerCase().indexOf(options[0]) !== -1;
    } else if (length === 2) {
      return (
        conditions.toLowerCase().indexOf(options[0]) !== -1 ||
        conditions.toLowerCase().indexOf(options[1]) !== -1
      );
    }
  };

  getEachFilter = (trial, filter, filters) => {
    return (
      (filter === "gender" &&
        (trial.partner_type === "patientrials"
          ? filters["gender"] &&
            filters["gender"].indexOf(trial && trial.sex) !== -1
          : filters["gender"] &&
            filters["gender"].indexOf(
              trial && trial.Gender && trial.Gender[0]
            ) !== -1)) ||
      (filter === "age" &&
        (trial && trial.partner_type === "patientrials"
          ? parseInt(filters["age"][0]) >= parseInt(trial.minimum_age) &&
            parseInt(filters["age"][0]) <= parseInt(trial.maximum_age)
          : parseInt(filters["age"][0]) >= parseInt(trial.MinimumAge[0]) &&
            parseInt(filters["age"][0]) <= parseInt(trial.MaximumAge[0]))) ||
      (filter === "StudyType" &&
        filters["StudyType"] &&
        filters["StudyType"].indexOf(
          trial && trial.StudyType && trial.StudyType[0]
        ) !== -1) ||
      (filter === "conditions" &&
        (trial && trial.partner_type === "patientrials"
          ? this.getOption(trial.conditions, filters["conditions"]) ||
            this.getOption(trial.brief_title, filters["conditions"]) ||
            this.getOption(trial.brief_summary, filters["conditions"]) ||
            this.getOption(trial.official_title, filters["conditions"])
          : //trial.conditions && trial.conditions.toLowerCase().indexOf(this.getOption(filters["conditions"])) !== -1
            trial &&
            trial.Condition &&
            trial.Condition[0] &&
            trial.Condition[0]
              .toLowerCase()
              .indexOf(filters["conditions"][0]) !== -1))
    );
  };

  provideFilter = (trial, filters) => {
    let keys = Object.keys(filters);
    let length = keys && keys.length;
    if (length > 0) {
      if (length === 1) {
        return this.getEachFilter(trial, keys[0], filters);
      } else if (length === 2) {
        return (
          this.getEachFilter(trial, keys[0], filters) &&
          this.getEachFilter(trial, keys[1], filters)
        );
      } else if (length === 3) {
        return (
          this.getEachFilter(trial, keys[0], filters) &&
          this.getEachFilter(trial, keys[1], filters) &&
          this.getEachFilter(trial, keys[2], filters)
        );
      } else if (length === 4) {
        return (
          this.getEachFilter(trial, keys[0], filters) &&
          this.getEachFilter(trial, keys[1], filters) &&
          this.getEachFilter(trial, keys[2], filters) &&
          this.getEachFilter(trial, keys[3], filters)
        );
      }
    }
  };

  applyFiltersChange = (filters) => {
    let length = Object.keys(filters);
    if (length && length.length === 0) {
      this.setState({
        allTrials: [...this.state.ourfilteredstudies, ...this.state.ctstudies],
      });
    } else if (length && length.length > 0) {
      this.setState(
        {
          allTrials: [
            ...this.state.ourfilteredstudies,
            ...this.state.ctstudies,
          ],
        },
        () => {
          let filtered = this.state.allTrials.filter((trial) =>
            this.provideFilter(trial, filters)
          );
          //log(filtered, "filtered");
          this.setState({ allTrials: filtered });
        }
      );
    }
  };

  getResults() {
    const { condition = "" } = this.state;
    axios
      .get("/backend/nlp?q=" + condition)
      .then((s) => {
        // log(`${s.data}, "sdata"`);
        if (s.data && s.data.error) {
          this.setState({ isLoading: true, error: true });
        } else {
          this.setState(
            {
              output: s.data.output,
              latestoutput: s.data.output, //s.data.output.filter(a=>new Date(a.StartDate[0]) >= new Date(date.toDateString())),
            },
            () => {
              axios
                .post("/backend/trialscope", {
                  message: s.data.term_words.split("+").join("_"),
                  // s.data.nlp.term_words.includes("type") ? s.data.nlp.term_words.replace('type','').split('+').join('_') : s.data.nlp.term_words.split('+').join('_') ||
                  // s.data.nlp.term_words.includes("Type") ? s.data.nlp.term_words.replace('Type','').split('+').join('_') : s.data.nlp.term_words.split('+').join('_') ||
                  // s.data.nlp.term_words.includes("TYPE") ? s.data.nlp.term_words.replace('TYPE','').split('+').join('_') : s.data.nlp.term_words.split('+').join('_'),
                  // age:s.data.nlp.age,
                  gender: s.data.Gender,
                  zipcode: "",
                  //travelradius:s.data.nlp.travelradius,
                  studytype: s.data.Study_type,
                  latitude: this.state.latitude,
                  longitude: this.state.longitude,
                })
                .then((scope) => {
                  //need to change code for displaying error message from API
                  //log(`${scope}, "trialscope output"`);
                  if (
                    (scope.data && scope.data.errors) ||
                    scope.data.data === null
                  ) {
                    this.state.ourfilteredstudies &&
                    this.state.ourfilteredstudies.length > 0
                      ? this.setState({ isLoading: true, norecords: false })
                      : this.setState({ isLoading: true, norecords: true });
                  } else if (scope.data && scope.data.error) {
                    this.state.ourfilteredstudies &&
                    this.state.ourfilteredstudies.length > 0
                      ? this.setState({ isLoading: true, norecords: false })
                      : this.setState({ isLoading: true, norecords: true });
                  } else {
                    let trialscopestudies =
                      scope.data.data &&
                      scope.data.data.baseMatches &&
                      scope.data.data.baseMatches.edges &&
                      scope.data.data.baseMatches.edges.filter(
                        (study) =>
                          (study && study.node && study.node.overallStatus) ===
                            "Recruting" || "recruiting"
                      )
                        ? scope.data.data &&
                          scope.data.data.baseMatches &&
                          scope.data.data.baseMatches.edges &&
                          scope.data.data.baseMatches.edges.filter(
                            (study) =>
                              (study &&
                                study.node &&
                                study.node.overallStatus) === "Recruting" ||
                              "recruiting"
                          )
                        : [];
                    let fivetrialscopestudies =
                      trialscopestudies && trialscopestudies.length > 0
                        ? [
                            trialscopestudies[0],
                            trialscopestudies[1],
                            trialscopestudies[2],
                            trialscopestudies[3],
                            trialscopestudies[4],
                          ]
                        : [];
                    this.setState(
                      {
                        isLoading: true,
                        output:
                          scope.data.data &&
                          scope.data.data.baseMatches &&
                          scope.data.data.baseMatches.edges,
                        trialscope: fivetrialscopestudies,
                        showtrialscope: !this.state.showtrialscope,
                      },
                      () => {
                        //this.onPagination(this.state.pagenumber);
                        // getAllElements(
                        //   this.state.trialscope.map((ts) => ts.node)
                        // )
                        //   .then((allElements) => {
                        //     log(`${allElements}, "allelements"`);
                        //     this.setState({ allelements: allElements }, () => {
                        //       if (
                        //         this.state.allelements &&
                        //         this.state.allelements.length > 0
                        //       ) {
                        //         removeRepeatedElements(this.state.allelements)
                        //           .then((unsortedObj) => {
                        //             log(`${unsortedObj}, "unsortedobj"`);
                        //             if (unsortedObj === undefined || null) {
                        //               log(
                        //                 "err with getting obj from allelements"
                        //               );
                        //               this.setState({ allelements: [] });
                        //             }
                        //             getRepeatedKeyValues(unsortedObj, 1)
                        //               .then((mainobj) => {
                        //                 this.setState(
                        //                   {
                        //                     checkboxitems:
                        //                       mainobj.checkboxitems,
                        //                     resultdata: mainobj.result,
                        //                   },
                        //                   () => {
                        //                     removeRepeatedElements(
                        //                       this.state.checkboxitems
                        //                     ).then((cool) => {
                        //                       log(`${cool}, "checkbox"`);
                        //                     });
                        //                   }
                        //                 );
                        //               })
                        //               .catch((errMainObj) => {
                        //                 log(
                        //                   `${errMainObj},
                        //                   "err with getting obj from allelements"`
                        //                 );
                        //                 this.setState({ allelements: [] });
                        //               });
                        //           })
                        //           .catch((errUnsortedObj) => {
                        //             log(
                        //               `${errUnsortedObj},
                        //               "cannot remove repeated elements"`
                        //             );
                        //           });
                        //       } else {
                        //         this.setState({ allelements: [] });
                        //       }
                        //     });
                        //   })
                        //   .catch((errAllItems) => {
                        //     this.setState({ allelements: [] });
                        //   });
                      }
                    );
                  }
                })
                .catch((e) => {
                  //log(`${e}, "err with trialscope api"`);
                  this.setState({ trialscope: [], allelements: [] });
                });
            }
          );
        }
      })
      .catch((e) => {
        //log(e);
        this.setState({ error: true });
      });
  }

  onTSCardClick = (id) => {
    this.props.history.push(
      `/study/${id}?q=${this.state.condition}&in=${this.state.country}`
    );
  };

  onCTCardClick = (id) => {
    this.props.history.push(
      `/study/${id}?q=${this.state.condition}&in=${this.state.country}`
    );
  };

  onPTCardClick = (sysId) => {
    this.props.history.push(`/study/${sysId}`);
  };

  onSuggestionClick = (item) => {
    window.location.href = `/results?q=${item}&in=UNITED%20STATES`;
  };

  onPagination = (pageNumber) => {
    this.setState({ pagenumber: pageNumber }, () => {
      let allStudies = getTenResults(pageNumber, [...this.state.trialscope]);
      //log(`${allStudies},'all studies'`)
      this.setState({ paginationstudies: allStudies }, () => {
        this.setState({ showPagination: false });
      });
    });
  };

  componentDidMount() {
    const { location } = this.props;
    this.fetchOurStudies();
    let decodeString = decodeURIComponent(location.search);
    //log(decodeString, "decodeString");
    if (
      decodeString === "" ||
      decodeString.indexOf("?q") === -1 ||
      decodeString.indexOf("&") === -1 ||
      decodeString.indexOf("&in") === -1
    ) {
      alert(
        "Please enter search condition and select country to get clinical trials"
      );
      window.location.href = "/";
    }
    let condition = decodeString.split("?q=")[1].split("&")[0],
      country = decodeString.split("&in=")[1].split("&")[0];
    //log(condition,country, "condition and country");
    this.setState({ condition: condition, country: country }, () => {
      //this.getResults();
      //this.fetchClinicalTrials();
      window.sessionStorage.setItem("condition", condition);
      window.sessionStorage.setItem("country", country);
    });
  }

  componentWillUnmount() {
    this.setState({
      allTrials: [],
      ourfilteredstudies: [],
      ourstudies: [],
      ctstudies: [],
    });
  }

  render() {
    const {
      error,
      isLoading,
      output,
      trialscope,
      showtrialscope,
      latestoutput,
      ourstudies,
      ourfilteredstudies,
      condition,
      country,
      norecords,
      showPagination,
      paginationstudies,
      pagenumber,
      ctstudies,
      allTrials,
    } = this.state;
    const { history } = this.props;
    return (
      <div
        className={`${
          this.props.location &&
          this.props.location.search &&
          this.props.location.search.indexOf("embed=true") !== -1
            ? ""
            : "pt-5 mt-5"
        }`}
      >
        {/* <div className="container p-0">
            <Warning subtitle={"Please send your feedback to hello@patientrials.com"} />
        </div> */}
        <ResultsView
          error={error}
          isLoading={isLoading}
          output={output}
          hideSteps={
            this.props.location &&
            this.props.location.search &&
            this.props.location.search.indexOf("embed=true") !== -1
              ? true
              : false
          }
          allTrials={allTrials}
          selectedFilters={this.selectedFilters}
          clearAllSidebarFilters={this.clearAllSidebarFilters}
          trialscope={trialscope}
          filterCheckboxChange={this.filterCheckboxChange}
          applyFiltersChange={this.applyFiltersChange}
          showtrialscope={showtrialscope}
          latestoutput={latestoutput}
          ourstudies={ourstudies}
          ourfilteredstudies={ourfilteredstudies}
          condition={condition}
          country={country}
          norecords={norecords}
          onTSCardClick={this.onTSCardClick}
          onPTCardClick={this.onPTCardClick}
          onCTCardClick={this.onCTCardClick}
          onSuggestionClick={this.onSuggestionClick}
          history={history}
          showPagination={showPagination}
          paginationstudies={paginationstudies}
          onPagination={this.onPagination}
          pagenumber={pagenumber}
          ctstudies={ctstudies}
          cardRight={this.right}
          cardLeft={this.left}
          HScroller={this.HScroller}
        />
      </div>
    );
  }
}
export default ResultsController;
