import React from "react";
import "../App.css";
import "../bootstrap.css";

const ScreenFlow = (props) => {
  const { flow } = props;
  // provide index value so that prop will match with the steps arrayitem
  return (
    <div className="screenflow row d-flex align-items-center justify-content-center my-1 px-0">
      {flow.map((item,i) =>
        flow.length - 1 === flow.indexOf(item.title) ? (
          <a key={i} href={item.link}>
            <h2 className="mx-1">{item.title}</h2>
          </a>
        ) : (
          <a key={i} href={item.link}>
            <h2 className="mx-1">{`${item.title} ${
              flow && flow.length === 1 ? "" : ">"
            }`}</h2>
          </a>
        )
      )}
    </div>
  );
};

export default ScreenFlow;
