import React from "react";
import { getSuggestions } from "../../helpers";
import { log } from "../../helpers/helper";
import { thirdStep } from "../theme/icons";
import HomeView from "./HomeView";

class HomeController extends React.Component {
  constructor(props) {
    super();
    this.state = {
      condition: "",
      country: "UNITED STATES",
      suggestions: [],
      cursor: 0,
    };
  }

  handleChange = (e) => {
    //log(e.target.name,e.target.value , 'checking inputs');
    this.setState({ [e.target.name]: e.target.value, cursor: 0 }, () => {
      if (this.state.condition && this.state.condition.length > 0) {
        getSuggestions(this.state.condition)
          .then((gotSuggestions) => {
            console.log(gotSuggestions, "got suggestions");
            if (gotSuggestions && gotSuggestions.length > 0) {
              this.setState({ suggestions: gotSuggestions });
            } else {
              this.setState({ suggestions: [], cursor: 0 });
            }
          })
          .catch((errSuggestions) => {
            //log(errSuggestions,'got suggestions');
            this.setState({ suggestions: [], cursor: 0 });
          });
      } else {
        this.setState({ suggestions: [], cursor: 0 });
      }
    });
  };

  handleSearchKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      let previous_cursor = this.state.cursor;
      if (previous_cursor === this.state.suggestions.length) {
        this.setState({ cursor: previous_cursor }, () => {
          this.setState({
            condition: this.state.suggestions[this.state.cursor - 1],
          });
        });
      } else {
        this.setState({ cursor: previous_cursor + 1 }, () => {
          this.setState({
            condition: this.state.suggestions[this.state.cursor - 1],
          });
        });
      }
    } else if (e.key === "ArrowUp") {
      let previous_cursor = this.state.cursor;
      if (previous_cursor === 1) {
        this.setState({ cursor: previous_cursor }, () => {
          this.setState({
            condition: this.state.suggestions[this.state.cursor - 1],
          });
        });
      } else {
        this.setState({ cursor: previous_cursor - 1 }, () => {
          this.setState({
            condition: this.state.suggestions[this.state.cursor - 1],
          });
        });
      }
    } else if (e.key === "Enter") {
      this.checkInputs();
    }
  };

  sendInfo = () => {
    this.checkInputs();
  };

  checkInputs = () => {
    const { condition = "", country = "" } = this.state;
    const { history } = this.props;
    if (condition === "" || country === "") {
      alert(
        "Please enter search condition and select country to get clinical trials"
      );
    } else {
      history.push(`/results?q=${condition}&in=${country}`);
    }
  };

  setCondition = (condition) => {
    this.setState({
      condition: condition,
      suggestions: [],
    });
  };

  componentDidMount() {
    window.localStorage.removeItem("chat_status");
    window.localStorage.removeItem("prospectID");
    window.localStorage.removeItem("chat_status");
    window.localStorage.removeItem("acc_sid");
    window.localStorage.removeItem("flow_id");
    window.localStorage.removeItem("pt_sys_id");
    window.localStorage.removeItem("prospectID");
    window.localStorage.removeItem("f_name");
    window.localStorage.removeItem("study_nct");
    window.localStorage.removeItem("study_sid");
    window.localStorage.clear()
  }

  render() {
    return (
      <div>
        <HomeView
          handleChange={this.handleChange}
          handleSearchKeyDown={this.handleSearchKeyDown}
          setCondition={this.setCondition}
          sendInfo={this.sendInfo}
          {...this.state}
          hideSpacing={`${
            this.props.location &&
            this.props.location.search &&
            this.props.location.search.indexOf("embed=true") !== -1
              ? "row"
              : "row pt-5 mt-5"
          }`}
        />
      </div>
    );
  }
}

export default HomeController;
