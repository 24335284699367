import React from "react";
import { RecruitmentSteps, ScreenFlow } from "../components";
import {
  enrollLink,
  getInclusionCriteria,
  getExclusionCriteria,
} from "../helpers/helper";
import {
  enroll_gender,
  enroll_age,
  enroll_rank,
  upcomingStudyIcon,
  kiraLogo,
} from "../features/theme/icons";
import Map from "./Map";
import { Google_Map_Key } from "../constants";

const Kira = (props) => {
  const { trial, searchTerm } = props;
  const flowarray = (studyNumber) => {
    return [
      { title: "Home", link: "/" },
      { title: "Find Trials", link: "/#find_trial" },
      {
        title: "Search Results",
        link: `/results?q=kidney%20disease&in=UNITED%20STATES`,
      },
      {
        title: "Trial Details",
        link: `/kira/`,
      },
    ];
  };

  return (
    <div>
      <div>
        <div className="container">
          <RecruitmentSteps step={"Review Trials"} />
          <div className="container d-flex align-items-center justify-content-start px-0 mx-0">
            <ScreenFlow flow={flowarray(trial && trial.snow_study_number)} />
          </div>
          <div
            style={{
              background: "rgb(241 241 241)",
              //"linear-gradient(180deg, rgba(196, 196, 196, 0) 12.81%, rgba(118, 214, 238, 0.2) 73.23%)",
            }}
            className="row p-0 p-3"
          >
            <div className="col-lg-6 p-0 d-flex align-items-center justify-content-start">
              <img
                className="upcoming_study_image img-responsive"
                style={{ height: 180, width: '70%' }}
                src={kiraLogo}
                alt="study_image"
              ></img>
            </div>
            <div className="upcoming_paid col-lg-6 p-0 my-auto">
              <div className="text-left">
                <h3
                  style={{ fontSize: "20px", letterSpacing: 1.5 }}
                  className="col-lg-12 px-0 text-left my-3"
                >
                  Assess the safety and efficacy of the KidneyCare platform as
                  tool to successfully augment immunosuppressant agents
                </h3>
                <p style={{fontWeight:'normal'}} lassName="my-3">
                  Trial Status :{" "}
                  <span style={{ color: "#cb333b", fontWeight: 'normal' }}>Not yet recruiting</span>
                </p>
                {/* <div>
                  <p className="mb-0">CareDx Trials Safety Group</p>
                  <div className="d-flex align-items-center justify-content-center my-0 py-0">
                    <p className="mb-0" style={{ fontWeight: "normal" }}>
                      45 Rockefeller Plaza,
                    </p>
                    <p className="mb-0" style={{ fontWeight: "normal" }}>
                      New York,
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-center my-0 py-0">
                    <p className="mb-0" style={{ fontWeight: "normal" }}>
                      New York,
                    </p>
                    <p className="mb-0" style={{ fontWeight: "normal" }}>
                      USA,
                    </p>
                    <p className="mb-0" style={{ fontWeight: "normal" }}>
                      10111
                    </p>
                  </div>
                </div> */}
                {/* <button
                      onClick={() =>
                        enrollLink(trial && trial.snow_study_number)
                      }
                      className="upcoming_signup btn btn-lg my-3"
                    >
                      SIGN UP FOR THE STUDY
                    </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="upcoming_purpose mt-5 mb-0">
          <h2 style={{fontFamily: 'GeorgiaBold',fontSize:26}} className="my-3">Purpose</h2>
          <p style={{ fontWeight: "normal" }} className="mb-0">
            Non-Inferiority - demonstrate the safety and efficacy of KidneyCare
            as a tool to successfully augment immunosuppressant agents using
            regular surveillance for safe drug minimization. Identify
            correlation between dd-cfDNA and histopathological allograft
            rejection based on all clinical biopsies. Compare rates of de-novo
            DSA antibody formation in immuno-optimization and control groups.
            Assessment of all medical events throughout the duration of the
            study. Assess utility of AlloMap Kidney and iBox results for
            purposes of immune optimization and post transplant surveillance.
          </p>
        </div>
        <br />
        <div className="upcoming_purpose my-3">
          <h2 style={{fontFamily: 'GeorgiaBold',fontSize:26}} className="my-3">Description</h2>
          <p style={{ fontWeight: "normal" }} className="mb-0">
            Open-label randomized controlled four-arm interventional trial.
            Centers that utilize either standard triple therapy
            immunosuppression (tacrolimus, mycophenolate mofetil, and
            prednisone) or dual therapy (tacrolimus and mycophenolate mofetil
            with early standard-of-care steroid withdrawal) may participate.
            Patients anticipated to undergo standard-of-care steroid withdrawal
            per their center protocol will only be randomized to the MMF
            immuno-optimization or corresponding control arm; the expected
            number of these patients is not anticipated to exceed 25% of the
            total enrolled. Eligible patients will be consented at the time of
            transplant. All centers will use their own hospital induction
            regimen, which may include thymoglobulin, basiliximab, or
            alemtuzumab, and methylprednisolone. Patients enrolled in the study
            will begin
            <br />
            KidneyCare testing as specified below (Section 8). After a 3-month
            run-in period, eligibility to proceed will be confirmed based on
            inclusion/exclusion criteria and patients will be randomized at a
            2:1 ratio into one of two AlloSure/KidneyCare immuno-optimization
            (intervention) arms or the corresponding observational (control)
            arms. Patients not meeting eligibility criteria at the conclusion of
            the run-in period will be withdrawn from the study. Assuming
            requisite clinical/laboratory criteria are met, patients in the
            interventional arms will then begin stepwise optimization of their
            immunosuppression regimen based their group assignment and the
            results of their AlloSure/KidneyCare, clinical DSA testing, and
            histology. The weaning schedule, clinical/laboratory pre-requisites,
            and clinician guidance for MMF/steroid minimization are outlined as
            part of the study.
            <br />
            <br />
            Any immune optimization changes in the observational arms will occur
            based on clinician discretion. Clinicians in all study arms will be
            blinded to results of AlloMap Kidney, iBox, and the centralized
            research DSA testing. Protocolized surveillance biopsy will occur at
            12 months; surveillance biopsies will also be recommended for all
            study patients at 3 and 24 months. The minimization and active
            surveillance portions of the trial will last for two years and will
            require monthly patient participation. In total 24 visits are
            required, however many of these will be part of the clinical
            standard of care, and most will only involve blood tests. Data will
            be collected through an electronic data capture portal Medrio, where
            key results will be transcribed from the hospital EMR into the
            portal.
          </p>
        </div>
        <div className="upcoming_qualify mb-3">
          <h3 className="my-2 pt-3">Who Qualifies?</h3>
          <div className="row d-flex align-items-center justify-content-between px-4">
            <div className="text-center col-lg-3 col-sm-12 col-md-12 col-xs-12">
              <img src={enroll_gender} alt="gender"></img>
              <p className="mb-0 my-3">Gender: All</p>
            </div>
            <div className="text-center col-lg-4 col-sm-12 col-md-12 col-xs-12">
              <img src={enroll_age} alt="age"></img>
              {/* <div className="my-3">
                      <span className="mb-0 mx-1">Max - 70</span>
                      <span className="mb-0 mx-1">Min - 20</span>
                    </div> */}
              <div className="my-3">
                <span className="mb-0 mx-1">
                  Minimum Age: 18 years or older
                </span>
                {/* <span className="mb-0 mx-1">
                  Max - {(trial && trial.maximum_age) || "None"} years
                </span> */}
              </div>
            </div>
            <div className="text-center col-lg-4 col-sm-12 col-md-12 col-xs-12">
              <img className="mt-4" src={enroll_rank} alt="rank"></img>
              {/* <p className="mb-0 my-3">Conditions: 1</p> */}
              <p className="mb-0 my-3 mt-0">
                Conditions: Recipient of single, first-time Kidney Transplant
              </p>
            </div>
          </div>
        </div>
        <div className="upcoming_purpose">
          <h3 className="my-2 upcoming_criteria">Criteria</h3>
          <h2 className="my-3">Inclusion Criteria</h2>
          <ul>
            <li>
              Participant is willing and able to give informed consent for
              participation in the trial
            </li>
            <li>Patients aged 18 years or older</li>
            <li>
              cPRA &lt;20% & no preformed DSA at time of transplant (MFI &gt;
              500)
            </li>
            <li>Recipient of single, first-time Kidney Transplant</li>
            <li>
              Planned post-transplant maintenance immunosuppression regimen
              consisting of tacrolimus and MMF, with or without prednisone
            </li>
            <li>
              Negative virtual crossmatch (performed by transplant center)
            </li>
            <li>
              Female participants of childbearing potential and male
              participants whose partner is of childbearing potential must be
              willing to ensure that they or their partner use effective
              contraception during the trial and for 3 months thereafter
            </li>
            <li>
              In the investigator's opinion, is able and wiling to comply with
              all the trial requirements
            </li>
          </ul>
        </div>
        <div className="my-2 upcoming_purpose">
          <h2 className="my-3">Exclusion Criteria</h2>
          <p className="mb-0">
            The participant may not enter the trial if{" "}
            <span style={{ textDecoration: "underline" }}>ANY</span> of the
            following apply:
          </p>
          <ul>
            <li>
              Female participant who is pregnant, lactating or planning
              pregnancy during the trial
            </li>
            <li>Preformed DSA or ABO incompatible transplant</li>
            <li>Chronic steroid use for any reason</li>
            <li>
              Planned post-transplant immunosuppression regimen utilizing
              cyclosporine, azathioprine, mTOR inhibitors,and/or co-stimulatory
              blockers
            </li>
            <li>
              Multi-visceral transplant or repeat kidney transplant recipient
            </li>
            <li>Contraindication to having AlloSure Testing</li>
            <li>
              Participant with life expectancy of less than 6 months or is
              inappropriate for immuno-optimization (including those patients at
              increased risk of primary disease recurrence w/ reduction in
              post-transplant immunosuppression)
            </li>
            <li>
              Any other significant disease or disorder which, in the opinion of
              the Investigator, may either put the participants at risk because
              of participation in the trial
            </li>
            <li>
              Participants who are currently or have previously participated in
              another research trial involving an investigational drug/product
              in the past 12 weeks.
            </li>
            <li>Any condition that would preclude protocol biopsies</li>
            <li>
              At 3 months post-transplant: maintenance immunosuppression that
              includes cyclosporine, azathioprine, mTOR inhibitors, and/or
              co-stimulatory blockers
            </li>
            <li>
              At 3 months post-transplant: baseline proteinuria 0.5g/day
              (confirmed by repeat measurement)
            </li>
            <li>
              At 3 months post-transplant: baseline eGFR &lt;45mL/min (average
              of ~3 prior readings)
            </li>
          </ul>
        </div>
        <div id="map" className="container my-3">
          <h3 style={{fontFamily: 'GeorgiaBold',fontSize:26}} className="pt-4">Locations</h3>
          <Map
            isMarkerShown={true}
            toEnroll={false}
            position={[
              {
                actual_address: "Plano TX United States of America",
                address: "Plano, TX, USA",
                location: { lat: 33.0198431, lng: -96.6988856 },
                //studyId: "STU01667",
              },
              {
                actual_address: "10701 Corporate Dr, Stafford, TX 77477",
                address: "10701 Corporate Dr #310, Stafford, TX 77477, USA",
                location: { lat: 29.6326033, lng: -95.59397779999999 },
                //studyId: "STU01619",
              },
              {
                actual_address: "Shreveport, TX,",
                address: "Shreveport, TX, USA",
                location: { lat: 32.523659, lng: -93.763504 },
                //studyId: "STU01619",
              },
              {
                actual_address: "Phoenix, Arizona, TX,",
                address: "Phoenix, Arizona, TX,",
                location: { lat: 33.448376, lng: -112.074036 },
                //studyId: "STU01619",
              },
              {
                actual_address:
                  "4 Pennsylvania Plaza, New York, NY 10001, United States",
                address:
                  "Madison square garden, 4 Pennsylvania Plaza, New York, NY 10001, United States",
                location: { lat: 40.7505, lng: -73.9934 },
                //studyId: "STU01619",
              },
              {
                actual_address:
                  "5721 S Maryland Ave, Chicago, IL 60637, United States",
                address:
                  "5721 S Maryland Ave, Chicago, IL 60637, United States",
                location: { lat: 41.881832, lng: -87.623177 },
                //studyId: "STU01619",
              },
              {
                actual_address:
                  "UCSF Medical Center, 1975 4th St, San Francisco, CA 94158, United States",
                address:
                  "UCSF Medical Center, 1975 4th St, San Francisco, CA 94158, United States",
                location: { lat: 37.755634, lng: -122.403748 },
                //studyId: "STU01619",
              },
            ]}
            showMap={true}
            containerElement={
              <div className="map_dimensions" />
            }
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${Google_Map_Key}`}
            loadingElement={<div style={{ height: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
      </div>
    </div>
  );
};

export default Kira;
