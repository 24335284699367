const questions = [
  {
    id: 1,
    type: "radio",
    question: "Have you ever had a positive test for COVID-19?",
    answers: [
      { option: "Yes", result: false },
      { option: "No", result: true },
    ],
  },
  {
    id: 2,
    type: "radio",
    question: "Are you 65 years or older?",
    answers: [
      { option: "Yes", result: true },
      { option: "No", result: true },
    ],
  },
  {
    id: 3,
    type: "checkbox",
    question: "How do you identify your race or ethnicity?",
    answers: [
      { option: "American Indian or Alaskan Native", result: true },
      { option: "Asian or Pacific Islander", result: false },
      { option: "Black or African American", result: true },
      { option: "Hispanic or Latino", result: true },
      { option: "White or Caucasian", result: false },
    ],
  },
  {
    id: 4,
    type: "checkbox",
    question:
      "Have you been diagnosed with any of the following medical conditions that put you at increased risk of COVID-19 infection?",
    answers: [
      { option: "Obesity (body mass index over 30)", result: true },
      { option: "Chronic kidney disease", result: true },
      { option: "Cardiovascular or heart disease", result: true },
      { option: "Lung Disease", result: true },
      { option: "Type 1 or Type 2 diabetes", result: true },
      { option: "None of the above", result: false },
    ],
  },
  {
    id: 5,
    type: "radio",
    question: "If female, are you currently pregnant or breast-feeding?",
    answers: [
      { option: "Yes", result: false },
      { option: "No", result: true },
      { option: "Not Applicable", result: true },
    ],
  },
  {
    id: 6,
    type: "radio",
    question:
      "Have you been diagnosed with any of the following medical conditions?",
    answers: [
      {
        option:
          "Autoimmune or immunodeficiency disease/condition that requires ongoing immunomodulatory therapy",
        result: false,
      },
      {
        option: "Active cancer (malignancy) within the past 1 year",
        result: false,
      },
      { option: "None of the above", result: true },
    ],
  },
];

const cmvQuestions = [
  {
    id: 1,
    type: "radio",
    question: "Are you between the ages of 18-40?",
    answers: [
      { option: "Yes", result: true },
      { option: "No", result: false },
    ],
  },
  {
    id: 2,
    type: "radio",
    question: "What was your assigned sex at birth?",
    answers: [
      { option: "Female", result: true },
      { option: "Male", result: false },
    ],
  },
  {
    id: 3,
    type: "radio",
    question:
      "In the past 10 years, have you been diagnosed with any cancer besides non-melanoma skin cancer?",
    answers: [
      { option: "Yes", result: false },
      { option: "No", result: true },
    ],
  },
  {
    id: 4,
    type: "radio",
    question:
      "Do you have any immune system disorders such as HIV, or active Hepatitis B or C?",
    answers: [
      { option: "Yes", result: false },
      { option: "No", result: true },
      { option: "Unsure", result: true },
    ],
  },
  {
    id: 5,
    type: "radio",
    question:
      "Are you currently pregnant or breastfeeding, or do you plan to become pregnant within the next year?",
    answers: [
      { option: "Yes", result: false },
      { option: "No", result: true },
      { option: "Unsure", result: true },
    ],
  },
  {
    id: 6,
    type: "radio",
    question:
      "Are you currently or have you participated in another research study within the past month?",
    answers: [
      { option: "Yes", result: false },
      { option: "No", result: true },
    ],
  },
];

const melanomaQuestions = [
  {
    id: 1,
    type: "radio",
    question: "Are you 18 years of age or older?",
    answers: [
      { option: "Yes", result: true },
      { option: "No", result: false },
    ],
  },
  {
    id: 2,
    type: "radio",
    question: "Has your doctor diagnosed you with melanoma (skin cancer)?",
    answers: [
      { option: "Yes", result: true },
      { option: "No", result: false },
    ],
  },
  {
    id: 3,
    type: "radio",
    question: "What stage of skin cancer do you have?",
    answers: [
      { option: "Stage I", result: false },
      { option: "Stage II", result: false },
      { option: "Stage III", result: true },
      { option: "Stage IV", result: true },
      { option: "Unsure", result: true },
    ],
  },
  {
    id: 4,
    type: "radio",
    question: "Did you have surgery to remove the skin cancer?",
    answers: [
      { option: "The surgery is scheduled and upcoming", result: true },
      { option: "The surgery was less than 6 weeks ago", result: true },
      { option: "The surgery was more than than 6 weeks ago", result: false },
      { option: "I do not need surgery", result: false },
      { option: "I do not have skin cancer", result: false },
    ],
  },
  {
    id: 5,
    type: "radio",
    question: "Have you had any other cancers in the past 5 years?",
    answers: [
      { option: "Yes", result: false },
      { option: "No", result: true },
    ],
  },
  {
    id: 6,
    type: "radio",
    question:
      "Have you been given any medical treatments by your doctor for the melanoma (skin cancer) other than surgery, radiotherapy or interferon therapy?",
    answers: [
      { option: "Yes", result: false },
      { option: "No", result: true },
    ],
  },
  {
    id: 7,
    type: "radio",
    question:
      "Do you have any immune system disorders such as HIV, active Hepatitis B or C, organ or bone marrow transplant, or are you taking oral or injectable steroids (>10mg per day)?",
    answers: [
      { option: "Yes", result: false },
      { option: "No", result: true },
    ],
  },
];
const tbiQuestions = [
  {
    id: 1,
    type: "radio",
    question: "Is the potential participant 18 to 75 years of age?",
    answers: [
      { option: "Yes", result: true },
      { option: "No", result: false },
    ],
  },
  {
    id: 2,
    type: "radio",
    question: "Has the potential participant been diagnosed with TBI?",
    answers: [
      { option: "Yes", result: true },
      { option: "No", result: false },
    ],
  },
  {
    id: 3,
    type: "radio",
    question: "People with TBI may experience changes in behavior described as agitation, aggression, and/or irritability. Examples may include shouting or cursing angrily, becoming easily upset, being difficult to get along with, and attempting to hit or hurt others. Has the potential participant experienced any of these behavioral changes since the TBI?",
    answers: [
      { option: "Yes", result: true },
      { option: "No", result: false },
    ],
  },
  {
    id: 4,
    type: "radio",
    question: "Does the potential participant interact with someone (at minimum 2 hours per day for at least 3 days a week) who can attend study visits and provide information about behavior?",
    answers: [
      { option: "Yes", result: true },
      { option: "No", result: false },
    ],
  },
  {
    id: 5,
    type: "radio",
    question: "Does the potential participant have a history of schizophrenia, schizoaffective disorder, or bipolar disorder?",
    answers: [
      { option: "Yes", result: false },
      { option: "No", result: true },
    ],
  },
]
const colorectalCancerQuestions=[
  {
    id: 1,
    type: "radio",
    question: "Do you have colon cancer or rectal cancer that has spread to other parts of your body (metastatic colorectal cancer)?",
    answers: [
      { option: "Yes", result: true },
      { option: "No", result: false },
      { option: "Unsure", result: true },
    ],
  },
  {
    id: 2,
    type: "radio",
    question: "Has your cancer gotten worse since your last cancer treatment?",
    answers: [
      { option: "Yes", result: true },
      { option: "No", result: false },
      { option: "Unsure", result: true },
    ],
  },
  {
    id: 3,
    type: "radio",
    question: "Was your last treatment so difficult that you had to stop being treated?",
    answers: [
      { option: "Yes", result: true },
      { option: "No", result: false },
      { option: "Unsure", result: true },
    ],
  },
  {
    id: 4,
    type: "radio",
    question: "Have you already had any of these cancer treatments? Suggest checking all that apply.",
    answers: [
      { option: "5-FU, capecitabine (XELODA), Oxaliplatin (ELOXATIN), Irinotecan (CAMPTOSAR), Ziv-aflibercept (ZALTRAP)", result: true },
      { option: "5-FU, capecitabine (XELODA) Oxaliplatin (ELOXATIN), Irinotecan (CAMPTOSAR), Bevacizumab (AVASTIN)", result: true },
      { option: "5 FU, capecitabine (XELODA), Oxaliplatin (ELOXATIN), Irinotecan (CAMPTOSAR), Ramucibrumab (CYRAMZA)", result: true },
      { option: "Not Applicable", result: false },
      { option: "Unsure", result: false },
    ],
  },
  {
    id: 5,
    type: "radio",
    question: "Select one of the following that applies to you:",
    answers: [
      { option: "My tumor has not been tested for HER2 and I am willing to get my tumor tested for HER2", result: true },
      { option: "My tumor has not been tested for HER2 and I do not want to get tested", result: false },
      { option: "I have been tested for HER2 and my tumor is positive for HER2", result: true },
      { option: "I have been tested and my tumor is positive for HER2 and I have already had trastuzumab (HERCEPTIN), lapatinib (TYKERB), neratinib (NERLYNX), and/or trastuzumab deruxtecan (ENHERTU)", result: false },
      { option: "I have been tested for HER2 and my results were negative. I am willing to get tested again.", result: true },
      { option: "I have been tested for HER2 and my results were negative. I am not willing to get tested again.", result: false },
      { option: "I am unsure of testing history and willing to get tested", result: true },
      { option: "I am unsure of testing history and unwilling to get tested.", result: true },
    ],
  },
  {
    id: 6,
    type: "radio",
    question: "Select one of the following that applies to you:",
    answers: [
      { option: "My cancer has not spread to my brain or nerves in my spine (central nervous system)", result: true },
      { option: "My cancer has spread to my brain or nerves in my spine and I have had treatment for this in the past (define >x months)", result: true },
      { option: "My cancer has spread to my brain or nerves in my spine and I am currently taking steroids for it (within the last 30 days)", result: false },
      { option: "My cancer has spread to my brain or nerves in my spine and I have not taken any treatment", result: false },
      { option: "I am unsure whether my cancer has spread to my brain or nerves in my spine", result: true },
      { option: "My cancer has spread to my brain or nerves in my spine but I am not sure if I am currently taking steroids for it", result: true }
    ],
  },
  {
    id: 7,
    type: "radio",
    question: "Do you have heart disease or lung disease? This includes heart failure or blood pressure that stays high, even if you’re on medication.",
    answers: [
      { option: "Yes", result: false },
      { option: "No", result: true },
      { option: "Unsure", result: true },
    ],
  },
  {
    id: 8,
    type: "radio",
    question: "Have you had a heart attack, a stent in your heart, or heart surgery in the past 6 months?",
    answers: [
      { option: "Yes", result: false },
      { option: "No", result: true },
      { option: "Unsure", result: true },
    ],
  },
]
const schizophreniaQuestions=[
  {
    id: 1,
    type: "radio",
    question: "Is the potential participant 18 to 60 years of age?",
    answers: [
      { option: "Yes", result: true },
      { option: "No", result: false },
    ],
  },
  {
    id: 2,
    type: "radio",
    question: "Has the potential participant been diagnosed with schizophrenia for at least 1 year?",
    answers: [
      { option: "Yes", result: true },
      { option: "No", result: false },
    ],
  },
  {
    id: 3,
    type: "radio",
    question:
      "Is the potential participant taking medication for schizophrenia?",
    answers: [
      { option: "Yes", result: true },
      { option: "No", result: false },
    ],
  },
  {
    id: 4,
    type: "radio",
    question:
      "Has the potential participant had negative symptoms of schizophrenia for at least 6 months? Negative symptoms include reduced facial expressions and speech, a lack of motivation and enthusiasm, and limited interaction with other people.",
    answers: [
      { option: "Yes", result: true },
      { option: "No", result: false },
    ],
  },
  {
    id: 5,
    type: "radio",
    question:
      "Does the potential participant have current major depressive disorder (MDD)?",
    answers: [
      { option: "Yes", result: false },
      { option: "No", result: true },
    ],
  },
  {
    id: 6,
    type: "radio",
    question:
      "Has the potential participant been diagnosed with schizoaffective disorder or bipolar disorder?",
    answers: [
      { option: "Yes", result: false },
      { option: "No", result: true },
    ],
  },
]
export { questions, cmvQuestions, melanomaQuestions, schizophreniaQuestions, colorectalCancerQuestions, tbiQuestions };
