import React, { useState } from "react";
import { PopularSearch} from "../../components";
import { countries } from "../../helpers";
import "../../App.css";
import {
  SearchIcon,
  SearchLightIcon,
  Down,
  arrowRight,
  step1,
  step2,
  step3,
  step4,
  step5,
  ReviewImg1,
  quotationIcon,
  review_slide_active,
  review_slide_inactive,
  StoreIcons,
  Iphone,
  Partnering,
  trialscope_main_logo,
  twilio_logo,
  service_now_logo,
} from "../theme/icons";

const Reviews = [
  {
    id: 0,
    feedback:
      "My wife has a chronic kidney disease and she was diagnosed with a thyroid cancer recently. We were able to find a trial in few days on Trials.me. Since then, my wife has been actively in touch with study team. When needed, we always have someone ready to answer on PatienTrials App and knowing what to expect, her energy goes up. Trials.me changed our life quality and we are much more happier now. I will recommend to other patients for sure!",
    img: ReviewImg1,
    patientName: "Jacob Jones",
    role: "Patient",
  },
  {
    id: 1,
    feedback:
      "I was suffering from Jaw pain with dry mouth and tried multiple treatments with no result. I signed up for a study match through PatienTrials and they were able to help me find the right study in couple of weeks. Effective treatment depends on getting the right diagnosis as soon as possible with less impact on your out of pocket expenses. The PatienTrials App was critical to my treatment as I was able share and get a quick feedback from the study coordinators on my sporadic conditions. This helped me a lot to take care of my condition. Now that I'm free from my Jaw pain and dry mouth, I recommend other patients like me find the right study for their condition at Trials.me",
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTY0J5j_EaLOFVzjvaB2qnqFVOr5Fghm-EuMQ&usqp=CAU",
    patientName: "Patrick Hughes",
    role: "Patient",
  },
  // {
  //   id: 2,
  //   feedback:
  //     "when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  //   img:
  //     "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSnY9tlaExmXctCs1YG8F-NBqNQeE0uejVN4g&usqp=CAU",
  //   patientName: "Willam Jones",
  //   role: "CRO",
  // },
];

const HomeView = (props) => {
  const {
    handleChange,
    sendInfo,
    condition,
    country,
    onOptionClick,
    history,
    hideSpacing,
    setCondition,
    suggestions,
    handleSearchKeyDown,
  } = props;
  const [reviewItem, setReviewItem] = useState(0);

  const dotClick = (id) => {
    setReviewItem(id);
  };

  const ProcessStep = ({ img, title, description, leftWidth, rightWidth }) => {
    return (
      <div className="row">
        <div className={`${leftWidth} p-0`}>
          <img src={img} alt={"step"}></img>
        </div>
        <div className={`${rightWidth} text-left mt-5`}>
          <h3 style={{ fontSize: "22px", fontFamily: "LatoBold" }}>{title}</h3>
          <p
            style={{
              fontSize: "18px",
              fontFamily: "LatoBold",
              paddingTop: "10px",
              fontWeight: "normal",
            }}
          >
            {description}
          </p>
        </div>
      </div>
    );
  };

  const Review = ({ img, feedback, patientName, role }) => {
    return (
      <div className="row ml-5 my-3 feedback">
        <div className="col-sm-12 col-md-12 col-lg-5 mx-2 p-0 ">
          <img className="mr-0 img-fluid" alt={"user"} src={img}></img>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6 text-left feedback_right">
          <img src={quotationIcon} alt={"quotation"}></img>
          <h3>{feedback}</h3>
          <h2>{patientName}</h2>
          <p>{role}</p>
        </div>
      </div>
    );
  };
  return (
    <div>
      {/* <div className="container p-0">
        <Warning subtitle={"Please send your feedback to hello@patientrials.com"} />
      </div> */}
      <div id="find_trial" className={hideSpacing}>
        <div className="col-sm-12 col-md-12 col-lg-9 trial_search">
          <div className="container">
            <div className="col-sm-12 col-md-12 col-lg-5 mt-5 find-trial-left">
              <div>
                <h3
                  className="ml-4 mt-4"
                  style={{
                    fontSize: "14px",
                    fontFamily: "LatoBold",
                    fontWeight: "bolder",
                    color: "#095E73",
                  }}
                >
                  SEARCH CLINICAL TRIALS
                </h3>
                <p
                  className="ml-4 mb-0 my-2"
                  style={{
                    fontSize: "26px",
                    fontFamily: "GeorgiaBold",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  I am looking for
                </p>
                <div className="enter_condition inline_block d-flex align-items-center justify-content-center my-2">
                  <input
                    type="text"
                    name="condition"
                    autoComplete="off"
                    onChange={handleChange}
                    onKeyDown={handleSearchKeyDown}
                    value={condition}
                    placeholder="Condition by key word"
                  ></input>
                  <img
                    style={{ float: "right", margin: "10px 0px" }}
                    alt={"search icon"}
                    src={SearchLightIcon}
                  ></img>
                </div>
                {suggestions && suggestions.length > 0 ? 
                  <div style={{position: "absolute",top:'173px',left:'31px', backgroundCcolor: "white",border: '1px solid black'}} className="suggestion-box d-block align-items-start justify-content-center col-lg-10 col-md-10 col-sm-10 col-xs-10 mx-0 px-0">
                  {condition && condition.length > 0 ?
                  suggestions.map(suggestion=>
                    <div onClick={()=>setCondition(suggestion)}>
                        <p className={`px-4 py-2 mb-0 mx-0 ${suggestion === condition ? "selected_suggestion" : ""}`}>{suggestion}</p>
                    </div> 
                  ) : null}
                  </div>
                : null}
                <div
                  style={{ margin: "10px 0px 5px 0px" }}
                  className="inline_block mt-4"
                >
                  <p
                    style={{
                      float: "left",
                      fontSize: 30,
                      fontFamily: "GeorgiaBold",
                      margin: "10px 15px 0px 40px",
                    }}
                  >
                    in
                  </p>
                  {/* <div style={{width:'68%',float:'left',height:'58px',padding:'10px 0px 10px 5px',border:'1px solid black','border-radius':'5px'}} className="inline_block">
                                            <input style={{float:'left',width:'70%',margin:'5px','font-size':'18px',height:'auto',outline:'none','text-decoration':'none',border:'none'}} type="text" name="country" placeholder="Country"></input>
                                            <img style={{float:'right'}} src={Down} alt={"dropdown"}></img>
                                        </div> */}
                  <div className="select_country row border rounded border-dark">
                    <select
                      className="text-center my-2 pl-2"
                      onChange={handleChange}
                      name="country"
                      value={country}
                    >
                      {countries.map((country, i) => (
                        <option
                          key={i}
                          style={{ fontSize: "16px"}}
                          className="text-left"
                          value={country}
                        >
                          {country}
                        </option>
                      ))}
                    </select>
                    <img src={Down} alt={"dropdown"}></img>
                  </div>
                </div>
                <div
                  className={`find_my_trial mx-auto mt-4`}
                  onClick={() => sendInfo(condition, country)}
                >
                  <img src={SearchIcon} alt={"searchicon"}></img>
                  <p
                    className="ml-3 mt-2"
                    style={{
                      margin: 5,
                      fontSize: "16px",
                      fontFamily: "LatoBold",
                      fontWeight: "900",
                      color: "black",
                    }}
                  >
                    FIND MY TRIAL
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-3 p-0 search_right">
          <PopularSearch history={history} onOptionClick={onOptionClick} />
        </div>
      </div>
      <div className="container text-center">
        <div className="text-left">
          <h2
            style={{
              fontSize: "14px",
              fontFamily: "LatoBold",
              fontWeight: "bolder",
              color: "#095E73",
            }}
          >
            HOW IT WORKS
          </h2>
          <p
            style={{
              fontSize: "26px",
              fontFamily: "GeorgiaBold",
              fontWeight: "bold",
              color: "black",
            }}
          >
            PatienTrials Enrollment Process
          </p>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6 m-0 p-0">
            <ProcessStep
              img={step1}
              leftWidth={"col-sm-12 col-md-5 col-lg-5"}
              rightWidth={"col-sm-12 col-md-7 col-lg-7"}
              title={"1.Find a Trial"}
              description={
                "Enter your condition and country, then filter in the search result page based on age, trial phase and others."
              }
            />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6 m-0 p-0">
            <ProcessStep
              img={step2}
              leftWidth={"col-sm-12 col-md-5 col-lg-5"}
              rightWidth={"col-sm-12 col-md-7 col-lg-7"}
              title={"2.Enroll in Study"}
              description={
                "Review the enrollment criteria and self assess if you meet the minimal requirement. Fill out enrollment form, which include medical condition, contact info, family history, etc."
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6 m-0 p-0">
            <ProcessStep
              img={step3}
              leftWidth={"col-sm-12 col-md-5 col-lg-5"}
              rightWidth={"col-sm-12 col-md-7 col-lg-7"}
              title={"3.Eligibility Screening"}
              description={
                "Our clinical research coordinators will review your application carefully and contact you with next steps in email and/or call."
              }
            />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6 m-0 p-0">
            <ProcessStep
              img={step4}
              leftWidth={"col-sm-12 col-md-5 col-lg-5"}
              rightWidth={"col-sm-12 col-md-7 col-lg-7"}
              title={"4.Consented Process"}
              description={
                "Clinical research coordinator will go over the trial details and you will have time to consult your health care providers and loved one before taking the decision."
              }
            />
          </div>
        </div>
        <div style={{ position: "relative", zIndex: 20 }} className="p-0">
          <ProcessStep
            img={step5}
            leftWidth={"col-sm-12 col-md-4 col-lg-2"}
            rightWidth={"col-sm-12 col-md-7 col-lg-9 px-5 ml-2"}
            title={"5.Start the Trial"}
            description={
              "After signing the consent you will be assigned to a professional to answer your questions on the app through out the journey. Trial period will vary based on study."
            }
          />
        </div>
      </div>
      {/* mapsection */}
      <div className="mapdetails">
        <div className="container">
          <div style={{ padding: "187px 0px" }} className="row">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <p style={{ fontSize: "22px", fontFamily: "LatoBold" }}>
                Don't miss the treatment prime time. All trials are free for
                patients and participants
              </p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-8 row text-center">
              <div className="col-sm-12 col-md-12 col-lg-3 p-0 m-0">
                <h2
                  style={{
                    fontSize: "38px",
                    fontFamily: "LatoBold",
                    color: "#12BBE5",
                  }}
                >
                  200 +
                </h2>
                <p
                  style={{
                    fontSize: "18px",
                    fontFamily: "LatoBold",
                    color: "black",
                  }}
                >
                  Trials Sourced
                </p>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-3 p-0 m-0 ">
                <h2
                  style={{
                    fontSize: "38px",
                    fontFamily: "LatoBold",
                    color: "#12BBE5",
                  }}
                >
                  100,000 +
                </h2>
                <p
                  style={{
                    fontSize: "18px",
                    fontFamily: "LatoBold",
                    color: "black",
                  }}
                >
                  Patients applied
                </p>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-3 p-0 m-0 ">
                <h2
                  style={{
                    fontSize: "38px",
                    fontFamily: "LatoBold",
                    color: "#12BBE5",
                  }}
                >
                  3500 +
                </h2>
                <p
                  style={{
                    fontSize: "18px",
                    fontFamily: "LatoBold",
                    color: "black",
                  }}
                >
                  Successful matched
                </p>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-3 p-0 m-0 ">
                <h2
                  style={{
                    fontSize: "38px",
                    fontFamily: "LatoBold",
                    color: "#12BBE5",
                  }}
                >
                  250 +
                </h2>
                <p
                  style={{
                    fontSize: "18px",
                    fontFamily: "LatoBold",
                    color: "black",
                  }}
                >
                  Cities
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* review section */}
      <div className="mx-auto text-center reviews">
        <h3>REVIEWS</h3>
        <h2>What Our Users Are Saying</h2>
        <div className="container">
          <Review {...Reviews[reviewItem]} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            className="row"
          >
            {Reviews.map((dot) =>
              dot.id === reviewItem ? (
                <img
                  onClick={() => dotClick(dot.id)}
                  key={dot.id}
                  style={{ height: "28px", width: "28px", cursor: "pointer" }}
                  className="my-0"
                  src={review_slide_active}
                  alt="active dot"
                ></img>
              ) : (
                <img
                  onClick={() => dotClick(dot.id)}
                  key={dot.id}
                  style={{ height: "28px", width: "28px", cursor: "pointer" }}
                  className="my-0"
                  src={review_slide_inactive}
                  alt="inactive dot"
                ></img>
              )
            )}
          </div>
        </div>
      </div>
      {/* about section */}
      <div className="row">
        <div className="aboutus_left col-sm-12 col-md-12 col-lg-4">
          <div className="container mt-5 px-0">
            <h2
              style={{
                fontSize: "14px",
                fontFamily: "LatoBold",
                fontWeight: "bolder",
                color: "#095E73",
              }}
            >
              ABOUT US
            </h2>
            <h3
              style={{
                fontSize: "26px",
                fontFamily: "GeorgiaBold",
                fontWeight: "bold",
                color: "black",
              }}
            >
              We are the Leading Patient Engagement Platform for Clinical Trials
            </h3>
            <br></br>
            <p
              style={{
                fontSize: "18px",
                fontFamily: "LatoRegular",
                fontWeight: "400",
                color: "black",
              }}
            >
              PatienTrials empowers clinicians and sponsors to run global
              clinical trials virtually or through selected sites, more
              efficiently in a closed community setting over HIPAA & GDPR
              compliant collaboration platform.
            </p>
            <br></br>
            <br></br>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
              className="row"
            >
              <a
                style={{ textDecoration: "none" }}
                href="https://patientrials.com/request-demo/"
              >
                <h2
                  style={{
                    fontFamily: "LatoRegular",
                    fontWeight: "bolder",
                    fontSize: "16px",
                    wordSpacing: "4.5px",
                  }}
                >
                  LEARN MORE ABOUT US
                </h2>
              </a>
              <img
                className="ml-3 mb-2"
                src={arrowRight}
                alt={"arrow right icon"}
              ></img>
            </div>
          </div>
        </div>
        <div className="aboutus_right col-sm-12 col-md-12 col-lg-8 p-0">
          <div className="blur row">
            <img className="img-fluid" src={Iphone} alt="mobile"></img>
            <div
              className="mb-5"
              style={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              <img
                style={{ height: "25px", width: "65px" }}
                src={StoreIcons}
                alt="storeicons"
              ></img>
            </div>
          </div>
        </div>
      </div>
      {/* partners section */}
      {/* <div className="mx-auto py-4 text-center container partners">
        <h3>Backed By Robust Partners</h3>
        <p className="mx-4 mb-0">
          We partnered with the most prestigious organizations to streamline the
          process, and reduce the risk of study.{" "}
        </p>
        <div className="mt-0 row d-flex align-items-center justify-content-center">
          <img
            className="col-md-4 img-fluid"
            src={service_now_logo}
            alt={"partner1"}
          ></img>
          <img
            className="col-md-4 img-fluid"
            src={twilio_logo}
            alt={"partner1"}
          ></img>
          <img
            className="col-md-4 img-fluid"
            src={trialscope_main_logo}
            alt={"partner1"}
          ></img>
        </div>
      </div> */}
      {/* sponsors and clinical trials */}
      <div className="partnering">
        <div className="container">
          <div className="positioning mx-auto text-center row">
            <div className="col-sm-12 col-md-12 col-lg-6 p-0">
              <img
                className="img-fluid"
                src={Partnering}
                alt="partnering"
              ></img>
            </div>
            <div className="partnering-right col-sm-12 col-md-12 col-lg-6 p-0">
              <div className="container mt-3 text-left">
                <h2>PARTNER WITH US</h2>
                <p>Sponsors and CRO's</p>
                <div className="get-in-touch mt-5">
                  <a
                    href="https://patientrials.com/request-demo/"
                    className="btn btn-danger btn-lg"
                  >
                    GET IN TOUCH
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeView;
