import React from "react";
import "../App.css";
import "../bootstrap.css";

const items = [
  "Covid-19",
  "Cancer",
  "Depression",
  "Asthma",
  "Brain Injury",
];
const SuggestedSearches = (props) => {
  const { onSuggestionClick } = props;
  // provide index value so that prop will match with the steps arrayitem
  return (
    <div className="screenflow row d-flex align-items-center justify-content-start my-4 ">
      {items.map((item, i) => (
        <div key={i} className="mr-4" onClick={() =>item === "Depression" ? window.location.href="https://trials.me/study/NCT04423757" : onSuggestionClick(item)}>
          <h2
            style={{ color: "#034EA2", cursor: "pointer", fontFamily: 'NunitoBold' }}
          >{`${"#"} ${item}`}</h2>
        </div>
      ))}
    </div>
  );
};

export default SuggestedSearches;
