import React from "react";
import { DataNotFound, RecruitmentSteps, ScreenFlow } from "../../components";
import { enrollLink, getInclusionCriteria, getExclusionCriteria } from "../../helpers/helper";
import {
  enroll_gender,
  enroll_age,
  enroll_rank,
  upcomingStudyIcon,
} from "../theme/icons";

const Template1 = (props) => {
  const { trial, searchTerm } = props;
  const flowarray = (studyNumber) => {
    return [
      { title: "Home", link: "/" },
      { title: "Find Trials", link: "/#find_trial" },
      {
        title: "Search Results",
        link: `/results?q=${studyNumber}&in=UNITED%20STATES`,
      },
      {
        title: "Trial Details",
        link: `/preview/${studyNumber}?template=${searchTerm}`,
      },
    ];
  };

  return (
    <div>
      {trial === null ? (
        <DataNotFound message="Selected template not found" />
      ) : (
        <div>
          <div
            style={{
              background:
                "linear-gradient(180deg, rgba(196, 196, 196, 0) 12.81%, rgba(118, 214, 238, 0.2) 73.23%)",
            }}
          >
            <div className="container">
              <RecruitmentSteps step={"Review Trials"} />
              <div className="container d-flex align-items-center justify-content-start px-0 mx-0">
                <ScreenFlow
                  flow={flowarray(trial && trial.snow_study_number)}
                />
              </div>
              <div className="row p-0">
                <div className="col-lg-6 p-0 d-flex align-items-center justify-content-start">
                  <img
                    className="upcoming_study_image img-responsive"
                    src={upcomingStudyIcon}
                    alt="study_image"
                  ></img>
                </div>
                <div className="upcoming_paid col-lg-6 p-0 my-auto">
                  <div className="d-block align-items-start justify-content-center mx-auto text-center">
                    <p className="col-lg-8 mx-auto text-center my-3 mb-0">
                      {(trial && trial.brief_title) || "None"}
                    </p>
                    {/* <p className="my-3">
                      Trial Status :{" "}
                      <span style={{ color: "#12BBE5" }}>
                        {(trial && trial.status) || "None"}
                      </span>
                    </p> */}
                    {/* <div>
                      <p className="mb-0">
                        {(trial && trial.site_name) || "None"}
                      </p>
                      <div className="d-flex align-items-center justify-content-center my-0 py-0">
                        <p className="mb-0" style={{ fontWeight: "normal" }}>
                          {(trial && trial.street_address) || "None"},
                        </p>
                        <p className="mb-0" style={{ fontWeight: "normal" }}>
                          {(trial && trial.city) || "None"},
                        </p>
                      </div>
                      <div className="d-flex align-items-center justify-content-center my-0 py-0">
                        <p className="mb-0" style={{ fontWeight: "normal" }}>
                          {(trial && trial.state) || "None"},
                        </p>
                        <p className="mb-0" style={{ fontWeight: "normal" }}>
                          {(trial && trial.country) || "None"},
                        </p>
                        <p className="mb-0" style={{ fontWeight: "normal" }}>
                          {(trial && trial.pin_code) || "None"}
                        </p>
                      </div>
                    </div> */}
                    <button
                      onClick={() =>{
                        if(trial && trial.active === "true" && trial.promote === "true"){
                          enrollLink(trial && trial.snow_study_number)
                        }
                      }
                      }
                      disabled = {(trial && trial.active === "true" && trial.promote === "true") ? false : true}
                      className="upcoming_signup btn btn-lg my-3"
                    >
                      SIGN UP FOR THE STUDY
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            {/* <div className="upcoming_purpose">
              <h2 className="my-3">{(trial && trial.brief_title) || "None"}</h2>
              <p style={{ fontWeight: "normal" }} className="mb-0">
                  Study Phase: {(trial && trial.study_phase) || "None"}
              </p>
            </div> */}
            <div className="upcoming_purpose my-3">
              <h4>Clinical Study Identifier: {(trial && trial.nct_number) || "None"}</h4>
              <h2 className="my-3">Purpose</h2>
              <p style={{ fontWeight: "normal" }} className="mb-0">
                {(trial && trial.brief_summary) || "None"}
              </p>
            </div>
            <br />
            <div className="upcoming_purpose my-3">
              <h2 className="my-3">Description</h2>
              <p style={{ fontWeight: "normal" }} className="mb-0">
                {(trial && trial.detailed_description) || "None"}
              </p>
            </div>
            <div className="upcoming_qualify mb-3">
              <h3 className="my-2">Who Qualifies?</h3>
              <div className="row d-flex align-items-center justify-content-between my-4 px-4">
                <div className="text-center col-lg-3 col-sm-12 col-md-12 col-xs-12">
                  <img src={enroll_gender} alt="gender"></img>
                  <p className="mb-0 my-3">
                    Gender: {(trial && trial.sex) || "None"}
                  </p>
                </div>
                <div className="text-center col-lg-5 col-sm-12 col-md-12 col-xs-12">
                  <img src={enroll_age} alt="age"></img>
                  {/* <div className="my-3">
                      <span className="mb-0 mx-1">Max - 70</span>
                      <span className="mb-0 mx-1">Min - 20</span>
                    </div> */}
                  <div className="my-3">
                    <span className="mb-0 mx-1">
                      Min - {(trial && trial.minimum_age) || "None"} years
                    </span>
                    <span className="mb-0 mx-1">
                      Max - {(trial && trial.maximum_age) || "None"} years
                    </span>
                  </div>
                </div>
                <div className="text-center col-lg-3 col-sm-12 col-md-12 col-xs-12">
                  <img src={enroll_rank} alt="rank"></img>
                  {/* <p className="mb-0 my-3">Conditions: 1</p> */}
                  <p className="mb-0 my-3">
                    Conditions: {(trial && trial.conditions) || "None"}
                  </p>
                </div>
              </div>
            </div>
            <div className="upcoming_purpose">
              <h3 className="my-2 upcoming_criteria">Criteria</h3>
              <h2 className="my-3">Inclusion Criteria</h2>
              <p style={{ fontWeight: "normal" }} className="mb-0">
                {getInclusionCriteria(trial && trial.eligibility)}
              </p>
            </div>
            <div className="my-2 upcoming_purpose">
              <h2 className="my-3">Exclusion Criteria</h2>
              <p style={{ fontWeight: "normal" }} className="mb-0">
                {getExclusionCriteria(trial && trial.eligibility)}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Template1;
