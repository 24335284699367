import React, { useEffect, useState } from 'react';
import '../App.css';
import '../bootstrap.css';


const Navigate = (props) => {
const {message} = props;

useEffect(()=>{
    window.location.href = "https://patientrials.com/request-demo/"
})
        
        return(
            <div className='container my-5 py-5'>
                <div className='d-flex align-items-center justify-content-center'>
                    <h6 style={{fontFamily : 'LatoRegular'}} className='mt-5'>Please wait ... redirecting to our website</h6>
                </div>
            </div>
        )
    }

export default Navigate;