import React from "react";
import { RecruitmentSteps, ScreenFlow } from "../components";
import {
  enrollLink,
  getInclusionCriteria,
  getExclusionCriteria,
} from "../helpers/helper";
import {
  enroll_gender,
  enroll_age,
  enroll_rank,
  upcomingStudyIcon,
  mosaicLogo,
} from "../features/theme/icons";
import Map from "./Map";
import { Google_Map_Key } from "../constants";

const Mosaic = (props) => {
  const { trial, searchTerm } = props;
  const flowarray = (studyNumber) => {
    return [
      { title: "Home", link: "/" },
      { title: "Find Trials", link: "/#find_trial" },
      {
        title: "Search Results",
        link: `/results?q=heart%20disease&in=UNITED%20STATES`,
      },
      {
        title: "Trial Details",
        link: `/mosaic/`,
      },
    ];
  };

  return (
    <div>
      <div
      >
        <div className="container">
          <RecruitmentSteps step={"Review Trials"} />
          <div className="container d-flex align-items-center justify-content-start px-0 mx-0">
            <ScreenFlow flow={flowarray(trial && trial.snow_study_number)} />
          </div>
          <div 
          style={{
          background: "rgb(241 241 241)"
            //"linear-gradient(180deg, rgba(196, 196, 196, 0) 12.81%, rgba(118, 214, 238, 0.2) 73.23%)",
        }} className="row p-3">
            <div className="col-lg-6 p-0 d-flex align-items-center justify-content-start">
              <img
                style={{height: 200, width: '70%' }}
                className="upcoming_study_image img-responsive"
                src={mosaicLogo}
                alt="study_image"
              ></img>
            </div>
            <div className="upcoming_paid col-lg-6 p-0 my-auto">
              <div className="text-left">
                <h3
                  style={{ fontSize: "20px", letterSpacing: 1.5 }}
                  className="col-lg-12 text-left my-3 px-0"
                >
                  Assess the safety and efficacy of the HeartCare platform as a
                  tool to successfully optimize immunosuppressant agents
                </h3>
                <p style={{fontWeight: 'normal'}} className="my-3">
                  Trial Status :{" "}
                  <span style={{ color: "#cb333b", fontWeight: "normal" }}>Not yet recruiting</span>
                </p>
                {/* <div>
                  <p className="mb-0">CareDx Trials Safety Group</p>
                  <div className="d-flex align-items-center justify-content-center my-0 py-0">
                    <p className="mb-0" style={{ fontWeight: "normal" }}>
                      45 Rockefeller Plaza,
                    </p>
                    <p className="mb-0" style={{ fontWeight: "normal" }}>
                      New York,
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-center my-0 py-0">
                    <p className="mb-0" style={{ fontWeight: "normal" }}>
                      New York,
                    </p>
                    <p className="mb-0" style={{ fontWeight: "normal" }}>
                      USA,
                    </p>
                    <p className="mb-0" style={{ fontWeight: "normal" }}>
                      10111
                    </p>
                  </div>
                </div> */}
                {/* <button
                      onClick={() =>
                        enrollLink(trial && trial.snow_study_number)
                      }
                      className="upcoming_signup btn btn-lg my-3"
                    >
                      SIGN UP FOR THE STUDY
                    </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="upcoming_purpose mt-5 mb-0">
          <h2 style={{fontFamily: 'GeorgiaBold',fontSize:26}} className="my-3">Purpose</h2>
          <p style={{ fontWeight: "normal" }} className="mb-0">
            Demonstrate the safety and efficacy of HeartCare as a tool to
            successfully optimize immunosuppressant agents using regular
            surveillance for safe drug minimization. Identify correlation
            between HeartCare and histopathological allograft rejection based on
            all clinical biopsies.Establish temporal relationships between
            HeartCare and allograft injury patterns such as dnDSA formation,
            allograft rejection and CAV. Assessment of all medical events
            throughout the duration of the study.
          </p>
        </div>
        <br />
        <div className="upcoming_purpose my-3">
          <h2 style={{fontFamily: 'GeorgiaBold',fontSize:26}} className="my-3">Description</h2>
          <p style={{ fontWeight: "normal" }} className="mb-0">
            Open-label randomized controlled two-arm interventional trial.
            Transplant centers that utilize standard triple therapy
            immunosuppression (tacrolimus, mycophenolate mofetil and prednisone)
            may participate; eligible patients starting triple maintenance
            therapy post-transplant will be randomized at a 1:1 ratio into the
            HeartCare immuno-optimization (intervention) arm or the
            corresponding observational (control) arm.
            <br />
            <br />
            Eligible patients can be consented on the waitlist, at the time of
            transplantation or within 4 weeks post-transplant. Subjects will be
            randomized at 4-weeks post-transplant, assuming they meet requisite
            clinical/laboratory/histological criteria to proceed. Patients
            enrolled in the study will begin HeartCare testing as specified
            below (Section 8). All centers will use their own induction regimen
            provided that the induction practice represents standard of care.
            Patients that undergo desensitization protocols for high
            immunological risk prior to transplant will not be eligible for
            participation in this study.
            <br />
            <br />
            In the interventional arm, patients will begin stepwise optimization
            of their immunosuppression regimen based on their HeartCare,
            clinical DSA testing, and histology. The weaning schedule,
            clinical/laboratory pre-requisites, and clinician guidance for
            immunosuppression minimization are outlined below (see Section 8).
            For the purpose of this study, clinical DSA testing will be
            performed at local laboratories per standard workflow at
            participating institutions. DSA positivity will be defined by local
            laboratory assessment, interpretation, and reporting. In the
            intervention arm, protocol surveillance EMBs will be performed at
            weeks 2 and 4 post-transplant. Protocol surveillance EMBs are also
            recommended at weeks 8, 12 and month 6 post-transplant at the
            discretion of the treating clinician. Any biopsies performed outside
            of this schedule will be in the for-cause setting and guided by both
            clinical and laboratory findings, including HeartCare at the
            discretion of the treating clinician. In the control arm, EMBs will
            be performed per standard of care practice at participating
            transplant centers.
            <br />
            <br />
            Biopsies may be performed as routine surveillance or in the
            for-cause setting and will be guided by both clinical and laboratory
            findings at the discretion of the treating clinician. This may
            include HeartCare if this represents standard of care
            post-transplant surveillance for individual centers. The indication
            and results of EMBs will be identified in the electronic data
            capture database. The minimization and active surveillance portions
            of the trial will last for two years and will require regular
            patient participation outlined in the schedule of events. In total,
            21 visits are required throughout the study, however many of these
            will be part of the clinical standard of care. Data will be
            collected through an electronic data capture portal where key
            results will be transcribed from the hospital EMR into the portal.
          </p>
        </div>
        <div className="upcoming_qualify mb-3">
          <h3 className="my-2 pt-4">Who Qualifies?</h3>
          <div className="row d-flex align-items-center justify-content-between px-4">
            <div className="text-center col-lg-3 col-sm-12 col-md-12 col-xs-12">
              <img src={enroll_gender} alt="gender"></img>
              <p className="mb-0 my-3">Gender: All</p>
            </div>
            <div className="text-center col-lg-4 col-sm-12 col-md-12 col-xs-12">
              <img src={enroll_age} alt="age"></img>
              {/* <div className="my-3">
                      <span className="mb-0 mx-1">Max - 70</span>
                      <span className="mb-0 mx-1">Min - 20</span>
                    </div> */}
              <div className="my-3">
                <span className="mb-0 mx-1">
                  Minimum Age: 18 years or older
                </span>
                {/* <span className="mb-0 mx-1">
                  Max - {(trial && trial.maximum_age) || "None"} years
                </span> */}
              </div>
            </div>
            <div className="text-center col-lg-4 col-sm-12 col-md-12 col-xs-12">
              <img className="mt-4" src={enroll_rank} alt="rank"></img>
              {/* <p className="mb-0 my-3">Conditions: 1</p> */}
              <p className="mb-0 my-3">
                Conditions: Heart transplant recipients &lt;4 weeks
                post-transplant
              </p>
            </div>
          </div>
        </div>
        <div className="upcoming_purpose">
          <h3 className="my-2 upcoming_criteria">Criteria</h3>
          <h2 className="my-3">Inclusion Criteria</h2>
          <ul>
            <li>Heart transplant recipients &lt;4 weeks post-transplant</li>
            <li>Patients aged 18 years or older</li>
            <li>
              Planned post-transplant maintenance immunosuppression regimen
              consisting of prednisolone, tacrolimus andmycophenolate
            </li>
            <li>
              Female participants of childbearing potential and male
              participants whose partner is of childbearing potential must be
              willing to ensure that they or their partner use effective
              contraception during the trial and for 3 months thereafter
            </li>
            <li>
              Participant is willing and able to give informed consent for
              participation in the trial
            </li>
            <li>
              In the Investigator’s opinion, is able and willing to comply with
              all trial requirements
            </li>
          </ul>
        </div>
        <div className="my-2 upcoming_purpose">
          <h2 className="my-3">Exclusion Criteria</h2>
          <p className="mb-0">
            The participant may not enter the trial if{" "}
            <span style={{ textDecoration: "underline" }}>ANY</span> of the
            following apply:
          </p>
          <ul>
            <li>Multi-visceral transplant recipients</li>
            <li>
              Female participant who is pregnant, lactating or planning
              pregnancy during the trial
            </li>
            <li>
              Heart transplant recipients undergoing desensitization protocols
              prior to transplant based off high immunological risk profiles
              (determined by treating clinician)
            </li>
            <li>
              Chronic steroid use for any reason that cannot be tapered off and
              discontinued
            </li>
            <li>
              Planned post-transplant immunosuppression regimen utilizing
              cyclosporine, azathioprine, mTOR inhibitors, and/or co-stimulatory
              blockers
            </li>
            <li>Contraindication to having AlloSure or AlloMap testing</li>
            <li>
              Participant with life expectancy of less than 6 months or is
              inappropriate for immuno-optimization (including those patients at
              increased risk of primary disease recurrence w/ reduction in
              post-transplant immunosuppression) 24 Month Assessment: eGFR,
              dnDSA, IVUS, TTE and events/interventions at year month 24 12
              Month Assessment: eGFR, dnDSA, IVUS, TTE and events/interventions
              at month 12
            </li>
            <li>
              Any other significant disease or disorder which, in the opinion of
              the Investigator, may either put the participants at risk because
              of participation in the trial, or may influence the result of the
              trial, or the participant’s ability to participate in the trial.
              This includes clinical events that would significantly impact
              post-transplant immunosuppression such as major infectious
              complications or significant rejection episodes within the first
              month post-transplant.
            </li>
            <li>
              Participants who are currently or have previously participated in
              another research trial involving an investigational immunological
              drug in the past 12 weeks
            </li>
            <li>Any condition that would preclude protocol biopsies</li>
            <li>
              Any of the following criteria at 4-weeks post-transplant (time of
              randomization):
              <ol>
                <li>
                  Maintenance immunosuppression that includes cyclosporine,
                  azathioprine, mTOR inhibitors, and/or costimulatory blockers
                </li>
                <li>
                  Any episodes of biopsy-proven acute rejection (ACR 2R or AMR*)
                  since the time of transplant
                </li>
                <li>Abnormal molecular profile defined as AlloSure &gt;0.2%</li>
                <li>Allograft dysfunction defined as LVEF &lt;45%</li>
                <li>eGFR &lt;30mL/min</li>
                <li>
                  Presence of DSA (persistence of pre-transplant DSA or dnDSA)
                </li>
              </ol>
            </li>
          </ul>
        </div>
        <div id="map" className="container my-3">
          <h3 style={{fontFamily: 'GeorgiaBold',fontSize:26}} className="pt-4">Locations</h3>
          <Map
            isMarkerShown={true}
            toEnroll={false}
            position={[
              {
                actual_address: "Plano TX United States of America",
                address: "Plano, TX, USA",
                location: { lat: 33.0198431, lng: -96.6988856 },
                //studyId: "STU01667",
              },
              {
                actual_address: "10701 Corporate Dr, Stafford, TX 77477",
                address: "10701 Corporate Dr #310, Stafford, TX 77477, USA",
                location: { lat: 29.6326033, lng: -95.59397779999999 },
                //studyId: "STU01619",
              },
              {
                actual_address: "Shreveport, TX,",
                address: "Shreveport, TX, USA",
                location: { lat: 32.523659, lng: -93.763504 },
                //studyId: "STU01619",
              },
              {
                actual_address: "Phoenix, Arizona, TX,",
                address: "Phoenix, Arizona, TX,",
                location: { lat: 33.448376, lng: -112.074036 },
                //studyId: "STU01619",
              },
              {
                actual_address:
                  "4 Pennsylvania Plaza, New York, NY 10001, United States",
                address:
                  "Madison square garden, 4 Pennsylvania Plaza, New York, NY 10001, United States",
                location: { lat: 40.7505, lng: -73.9934 },
                //studyId: "STU01619",
              },
              {
                actual_address:
                  "5721 S Maryland Ave, Chicago, IL 60637, United States",
                address:
                  "5721 S Maryland Ave, Chicago, IL 60637, United States",
                location: { lat: 41.881832, lng: -87.623177 },
                //studyId: "STU01619",
              },
              {
                actual_address:
                  "UCSF Medical Center, 1975 4th St, San Francisco, CA 94158, United States",
                address:
                  "UCSF Medical Center, 1975 4th St, San Francisco, CA 94158, United States",
                location: { lat: 37.755634, lng: -122.403748 },
                //studyId: "STU01619",
              },
            ]}
            showMap={true}
            containerElement={
              <div className="map_dimensions" />
            }
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${Google_Map_Key}`}
            loadingElement={<div style={{ height: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
      </div>
    </div>
  );
};

export default Mosaic;
