
const environment = "PROD";
const localDevelopment = false;
const Debug = localDevelopment;
const Domain = environment;
const Google_Map_Key = "AIzaSyB7GuSujxY5Arxe9QcI3fWDeCeip1c-P1c";
const Recaptcha_site_key = "6Le8iXUUAAAAADgMmIwVjU66PIDM3ChtbR3Sehvc";

export { Domain, Debug, environment, Google_Map_Key, Recaptcha_site_key };

