import React, { useEffect, useState } from 'react';
import '../App.css';
import '../bootstrap.css';
import { GroupNotFound } from '../features/theme/icons';


const DataNotFound = (props) => {
const {message} = props;
        
        return(
            <div className="p-5 m-5 datanotfound mx-auto text-center">
                <img style={{ width: "50vw"}} src={GroupNotFound} alt="not found"></img>
                <h3 className="">{message && message.length > 0 ? message : "Not Found"}</h3>
            </div>
        )
    }

export default DataNotFound;