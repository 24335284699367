import React from "react";
import { log } from "../../helpers/helper";
import {
  blog_fb,
  blog_twitter,
  blog_linkedin,
  blog_insta,
  blog_google,
} from "../theme/icons";
import moment from "moment";

const media = [
  { media: "fb", active: true, img: blog_fb },
  { media: "twitter", active: true, img: blog_twitter },
  { media: "linkedin", active: true, img: blog_linkedin },
  { media: "instagram", active: false, img: blog_insta },
  { media: "google", active: false, img: blog_google },
];

const BlogDetail = (props) => {
  log(props, "brece");
  const { blog, list, share, goToStudy } = props;
  const Detail = (blog) => {
    let {
      title,
      author,
      date,
      category,
      detailed_image,
      sections,
      link,
      references,
      border_color,
      font_color,
      promoted_title,
      alignment
    } = blog;
    return (
      <div>
        <div
          style={{
            backgroundImage: `url(${detailed_image})`,
          }}
          className={`bg-image blog_background ${alignment}`}
        >
          <div style={{border:`2px solid ${border_color}`}} className="text-center m-3 p-5">
            <h1
              style={{ color: font_color}}
              className="mb-0 blog_promoted_title"
            >
              {promoted_title}
            </h1>
          </div>
        </div>
        {/* <div className="text-center">
          <img
            className="blog_detail_img img-responsive col-lg-12 col-sm-12 col-md-12 col-xs-12 p-0"
            alt="blog_img"
            src={detailed_image}
          ></img>
        </div> */}
        {/* <div className="d-flex align-items-center justify-content-end">
          <div className="mx-2 blog_details">
            <img
              style={{ height: 10 }}
              className="mx-1"
              src={blog_chat}
              alt="comments_icon"
            ></img>
            <span>{comments && comments.length}</span>
          </div>
          <div className="mx-2 blog_details">
            <img
              style={{ height: 10 }}
              className="mx-1"
              src={blog_like}
              alt="comments_icon"
            ></img>
            <span>{likes && likes.length}</span>
          </div>
        </div> */}
        <div className="d-flex align-items-center justify-content-end my-3">
          <span className="blog_detail_description">Share :</span>
          {media.map((icon,i) =>
            icon.active === true ? (
              <div
                key={i}
                style={{ cursor: "pointer" }}
                onClick={() => share(icon.media, title)}
                className="mx-2"
              >
                <img
                  className="img-responsive my-1"
                  src={icon.img}
                  alt="social_media_icon"
                ></img>
              </div>
            ) : null
          )}
        </div>
        <h2 className="my-2 blog_title">{title}</h2>
        <div className="blog_details my-1">
          <span className="mx-1 col-lg-2 col-md-2 col-sm-2 col-xs-2 p-0">
          {moment(date).format('MMMM Do YYYY')}
          </span>
          <span
            style={{
              borderLeft: "2px solid black",
              borderRight: "2px solid black",
            }}
            className="mx-1 col-lg-2 col-md-2 col-sm-2 col-xs-2"
          >
            By {author}
          </span>
          <span
            style={{ color: "#BC5405" }}
            className="mx-1 col-lg-2 col-md-2 col-sm-2 col-xs-2"
          >
            Category, {category}
          </span>
        </div>
        {/* <p className="my-1 mb-0 blog_description">
          {description.split("-*-") && description.split("-*-").length === 0 ? (
            <p style={{ fontFamily: "LatoRegular" }} className="my-2">
              {description}
            </p>
          ) : (
            list(description.split("-*-"))
          )}
        </p> */}
        {sections && sections.length > 0
          ? sections.map((section) => (
              <div className="my-4">
                <h3 className="blog_detail_subtitle">{section.subtitle}</h3>
                {section.images && section.images.length > 0
                  ? section.images.map((simg) => (
                      <img
                        className="col-lg-6 col-md-12 col-sm-12 col-xs-12"
                        src={simg}
                        alt="sub"
                      ></img>
                    ))
                  : null}
                <p className="mb-0 blog_detail_description">
                  {section.description.split("-*-") &&
                  section.description.split("-*-").length === 0 ? (
                    <p
                      style={{ fontFamily: "LatoRegular" }}
                      className="my-2 blog_detail_description"
                    >
                      {section.description}
                    </p>
                  ) : (
                    list(section.description.split("-*-"))
                  )}
                </p>
                <p className="mb-0 blog_detail_description">
                  {section.additional_content &&
                  section.additional_content.length > 0 ? (
                    section.additional_content.split("-*-") &&
                    section.additional_content.split("-*-").length === 0 ? (
                      <p
                        style={{ fontFamily: "LatoRegular" }}
                        className="my-2 blog_detail_description"
                      >
                        {section.additional_content}
                      </p>
                    ) : (
                      list(section.additional_content.split("-*-"))
                    )
                  ) : null}
                </p>
              </div>
            ))
          : null}
        <div>
          <h3 className="blog_detail_subtitle">
            {references && references.length > 0 ? "References :" : null}
          </h3>
          <ul>
            {references && references.length > 0
              ? references.map((ref) => (
                  <li
                    style={{
                      textDecoration: "underline",
                      textDecorationColor: "dodgerblue",
                      cursor: "pointer",
                      "overflow-wrap": "break-word",
                      "word-wrap": "break-word",
                    }}
                    onClick={() => window.open(ref, "_blank")}
                    className="col-10"
                  >
                    {ref}
                  </li>
                ))
              : null}
          </ul>
        </div>
        <div
          style={{ position: "fixed", zIndex: 10, top: 200, right: 10 }}
          className="d-flex align-items-center justify-content-center"
        >
          <button
            onClick={() => goToStudy(link)}
            style={{
              fontSize: 20,
              fontFamily: "LatoRegular",
              backgroundColor: "white",
              color: "dodgerblue",
              borderRadius: 15,
            }}
            className="btn btn-warning"
          >
            Sign Up for the Study
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="container-fluid p-0 m-0">
      <div className="container p-0 pt-4">
        <div className="row">
          {blog && blog.length > 0 ? (
            blog.map((blogarticle,i) => (
              <div key={i} className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                {Detail(blogarticle)}
              </div>
            ))
          ) : (
            <h4 className="mx-auto text-center">{"No details found"}</h4>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
