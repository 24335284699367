/* 
-*- for lists
--br-- for line breaks
*/
import {
  bnewImg1,
  bnewImg5,
  blogDetail2,
  blogDetail3,
  blog_gsk,
  blog_tbi,
  blog_astra,
  blog_enroll
} from "../theme/icons";
import { Domain, environment } from "../../constants";

const blogArticles = [
  {
    id: 1,
    title:
      "COPD patients join AstraZeneca sponsored clinical trial for a better cure",
    description:
      "AstraZeneca is a science-led biopharmaceutical company that does business across the globe. AstraZeneca invented various medicines that are globally available",
    author: "PatienTrials",
    date: "2 Apr 2021",
    category: "COPD",
    image: blog_astra,
    detailed_image:blog_astra,
    border_color:"#BC5405",
    font_color:"black",
    promoted_title:"You may be eligible for a COPD clinical trial",
    alignment:"d-flex align-items-center justify-content-center",
    video: "",
    likes: [],
    comments: [],
    sections: [
      {
        subtitle: "Overview",
        description:
          "AstraZeneca is a science-led biopharmaceutical company that does business across the globe. AstraZeneca invented various medicines that are globally available. Millions of patients worldwide use these medicines to treat different disease conditions. AstraZeneca is not only involved in pharmaceutical sales, but also focuses on drug discovery and development. The company has conducted many clinical trials and formulated safe and efficacious dosage forms. Currently, a phase three clinical trial has been sponsored by this biopharmaceutical company. The study aim is potentially reducing moderate-to-severe symptom flare-ups for some people living with COPD.",
        images: [],
        videos: "",
        additional_content: "",
      },
      {
        subtitle: "Study details",
        description:
          "The study requires an enrollment of 237 participants for 56 weeks or more duration. It will be conducted in the United States. This Phase III study indicates that it is the final round to confirm the safety and efficacy of the investigational medicine before getting approval for launching in the pharmaceutical market. The investigational medicine is Benralizumab that can be administered through subcutaneous injection. It is a placebo-controlled clinical trial. This means the eligible study participants are divided into groups. One study group may receive the investigational medicine, while another study group may receive a placebo (a simulated treatment).",
        images: [],
        videos: "",
        additional_content: "",
      },
      {
        subtitle: "Why should COPD patients apply to join this study?",
        description: `Chronic obstructive pulmonary disease (COPD) is a significant pulmonary disorder and is a major public health concern worldwide. The treatment guidelines for COPD management can be modified based on the latest results obtained from double-blind, placebo-controlled, randomized controlled trials (RCTs). 
                --br--
                The successful conduct of clinical trials depends upon the participation of patients that are engaged and retained for the entire duration of the study. Small differences in the collected data from the study participants could impact the clinical trial results. Therefore, the study design of this clinical trial protocol includes a sufficient number of participants to be observed over a relatively extended period to obtain statistically reliable results. Patients who will participate in the study would help investigators to correctly and consistently identify a true treatment effect. 
                In this study, all study-related medications, analysis, clinic visits, and procedures will be provided at no cost. Compensation and travel reimbursement may be available and can be discussed with study staff.
                `,
        images: [],
        videos: "",
        additional_content: "",
      },
      {
        subtitle: "Does every COPD applicant participate in this study?",
        description: `No, not every applicant is eligible for participation in a clinical trial. Every study has some unique eligibility criteria. Therefore, participants who want to join the clinical trial must qualify by meeting the inclusion and exclusion criteria. These criteria may be age or gender-specific or include the type and stage of a disease, previous treatment history, and other medical conditions. The criteria are different for each clinical trial.
                --br--
                Inclusion criteria for this study
                -*- The study participants must be age between 40-85 years old
                -*- Study participants have a diagnosis of COPD by a medical professional
                -*- Study participants be a current smoker or past smoker
                -*- Study participants have experienced three or more moderate to severe exacerbations.
                --br--
                Exclusion criteria for this study
                -*- The study participants have asthma (except childhood diagnosed asthma)
                -*- The study participants have active liver disease (Hep B or C)
                -*- The study participants have tuberculosis
                -*- The study participants have cirrhosis
                --br--
                Talk to your doctor if you are interested in participating in a clinical trial. Individuals who read this information can encourage their friends or family members who have COPD to apply for this clinical trial as a participant. Study staff will contact the applicants for inquiries about the eligibility criteria. During this session, applicants can also ask additional questions related to the study.
                `,
        images: [],
        videos: "",
        additional_content: "",
      },
    ],
    link: Domain + "study/NCT04053634",
    references: [
      "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5034631/",
      "https://www.lung.org/research/clinical-trials",
      "https://erj.ersjournals.com/content/45/6/1692",
      "https://antidote.me/prescreener/s/en-US/chronic_obstructive_pulmonary_disease-133/study-details",
    ],
  },
  {
    id: 2,
    title: "Negative Symptoms of Schizophrenia",
    description:
      "Schizophrenia is a chronic, disabling disorder. Schizophrenic patients often have cognitive, emotional, and socio-occupational deficits.",
    author: "PatienTrials",
    date: "5 Apr 2021",
    category: "Schizophrenia",
    image: bnewImg1,
    detailed_image:blogDetail2,
    border_color:"#da8529",
    font_color:"#16495a",
    promoted_title:"Negative Symptoms of Schizophrenia Study",
    alignment:"d-flex align-items-start justify-content-center p-5",
    video: "",
    likes: [],
    comments: [],
    sections: [
      {
        subtitle: "Overview",
        description: `Schizophrenia is a chronic, disabling disorder. Schizophrenic patients often have cognitive, emotional, and socio-occupational deficits. Both positive and negative symptoms are collectively providing characteristic features to schizophrenia. Clinical observations have distinguished positive and negative symptoms related to schizophrenia. The National Institute of Mental Health Measurement and Treatment Research identified five negative symptoms associated with schizophrenia, which are:
                -*- blunted affect (diminish facial expression and emotional feelings)
                -*- alogia (reduce verbal interaction or verbal articulateness)
                -*- asociality (withdrawal from social activity)
                -*- avolition (lack of self-motivation to initiate purposeful act due to dropping of interests, desires, and goals and a deleterious behavioral outcome)
                -*- anhedonia (positive stimuli unable to provide pleasure experience)
                `,
        images: [],
        videos: "",
        additional_content: "",
      },
      {
        subtitle: "Prevalence of Negative symptoms in schizophrenic patients",
        description: `Negative symptoms are common findings for schizophrenic patients, though the advent of these symptoms varies from patient to patient. Clinicians observed that 60% of schizophrenic patients have prominent negative symptoms during their illness and need treatment. Almost 35% to70% of schizophrenic patients who have undergone treatment also experienced significant persisted negative symptoms.
                `,
        images: [],
        videos: "",
        additional_content: "",
      },
      {
        subtitle: "Available treatment management",
        description: `Available antipsychotic medications are generally effective to treat positive symptoms, but limited treatment options are available for negative symptoms and remain an unmet medical need. Moreover, to date, no agent is approved by the FDA for the treatment of negative symptoms. Currently, clinicians carefully monitor and actively manage all clinically relevant negative symptoms in the clinic by optimizing available medications for stabilizing positive symptoms, minimizing medication side effects that trigger negative symptoms, dose adjustment, or switching medication. 
                `,
        images: [],
        videos: "",
        additional_content: "",
      },
      {
        subtitle:
          "Why does clinical trial conduction utmost need to manage Negative symptoms?",
        description: `To date, very few prospective, large-scale, randomized, double-blind clinical trials have been prospectively designed to assess the effect of treatment on persistent negative symptoms. In clinical trials, researchers evaluate the safety and efficacy of a new treatment (for example, a new drug or diet or medical device) in people. The aim to conduct the clinical trials is to establish that the new treatment is more effective and/or has less harmful side effects than the standard treatment.  Less number of participants in small-scale clinical trials does not assure efficacy in a large number of populations. Therefore, a maximum number of schizophrenic patients should be participated in well-designed clinical trials to wager the efficacy of a treatment for negative symptoms.
                --br--
                Currently, academia, research funders, clinicians, the pharmaceutical industry, financiers, and regulators work together to evaluate the safety, efficacy, and tolerability of a novel treatment (AVP-786) to address this major public health issue.
                `,
        images: [],
        videos: "",
        additional_content: "",
      },
      {
        subtitle: "Benefits to participating in Clinical trials",
        description: `Schizophrenic patients who enroll their name for participating in this clinical trial can gain a more active role in health care systems by helping researchers find better treatments for others in the future. During a clinical trial, participants possibly receive the newest treatment and have the additional care and attention from the clinical trial staff. Participation in clinical trials is a cultural shift. Patients need to break the cultural barriers that holding back the change process. Currently, the conduction of clinical trials becomes more organized and regulated. Patients are well-informed about the clinical trial process before they participate in the clinical trials. The principles of autonomy and non-maleficence are stringently followed in physician/investigator–patient relationships.`,
        images: [],
        videos: "",
        additional_content: "",
      },
    ],
    link: Domain + "study/NCT03896945",
    references: [
      "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5479085/#:~:text=The%20National%20Institute%20of%20Mental,expressiveness)%2C%20asociality%20(lack%20of",
      "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7041437/",
      "https://www.nia.nih.gov/health/what-are-clinical-trials-and-studies",
    ],
  },
  {
    id: 3,
    title: "Traumatic Brain Injury",
    description:
      "Traumatic Brain Injury (TBI) represents a wide range of central nervous system disorders.",
    author: "PatienTrials",
    date: "7 Apr 2021",
    category: "Brain Injury",
    image: blog_tbi,
    detailed_image:blog_tbi,
    border_color:"#da8529",
    font_color:"#16495a",
    promoted_title:"TBI Behaviour Change Study",
    alignment:"d-flex align-items-end justify-content-end p-5",
    video: "",
    likes: [],
    comments: [],
    sections: [
      {
        subtitle: "Overview",
        description: `Traumatic Brain Injury (TBI) represents a wide range of central nervous system disorders. It includes mild changes in consciousness to a state of coma and death. Inflammation of the brain tissue caused by diffuse brain injury damages the entire brain and leads to a severe form of TBI. The treatment approach of TBI depends upon the severity of the brain injury. Different treatment modalities that are available include regular cognitive therapy sessions to radical surgery like bilateral decompressive craniectomies. 
                --br--
                Regardless of initial TBI severity, behavioral disorders are often associated with traumatic brain injury. The behavioral disorder is a complex health issue that comes under neuropsychology, psychiatry, neurology, physical medicine, and rehabilitation. Behavioral disorders may appear immediately after the awakening period or may gradually appear within three months post-TBI and may remain at five years post-trauma
                `,
        images: [],
        videos: "",
        additional_content: "",
      },
      {
        subtitle: "Prevalence",
        description:
          "The care management of behavioral disorders after traumatic brain injury is critical. TBI patients have a long-term risk to develop behavioral disorders as post-traumatic symptoms. In 49% of TBI cases, behavioral disorders occur immediately after the awakening period. Whereas, 60% of cases have experienced behavioral disorders at three months post-TBI, and 74% of TBI patients are suffering from behavioral disorders at five years.",
        images: [],
        videos: "",
        additional_content: "",
      },
      {
        subtitle: "Behavioral disorders symptoms associated with TBI",
        description: `Irritability, bad temper, tiredness, depression, rapid mood change, anxiety, and the threat of violence are some concerning symptoms of behavioral disorders or psychological changes associated with TBI. 
                --br--
                In general, the following are some significant symptoms of behavioral disorders: 
                -*- Alteration or loss of consciousness frequently occurs. The duration of such events may last for a few seconds to few hours
                -*- Hard to awaken because of a reduced level of consciousness
                -*- Mild to intense disorientation or confusion 
                -*- Difficulty in concentrating, memorizing, and decision making 
                -*- Altered sleep patterns like difficulty in falling asleep, increased sleep duration, stay asleep for a longer period, and inability to wake up
                -*- Irritability and frustration
                `,
        images: [],
        videos: "",
        additional_content: "",
      },
      {
        subtitle:
          "Treatment regimen is not the same for every individual with TBI",
        description: `Age and genes are two considerable factors for clinicians to make treatment decisions for TBI patients.`,
        images: [],
        videos: "",
        additional_content: "",
      },
      {
        subtitle: "Age",
        description: `Age is a critical factor that impacts recovery. It is necessary to adjust treatment regimens for TBI patients based on age as elderly patients have more medical issues. The treatment regimen for older people with TBI involves multiple medications as they often have comorbidity. 
                --br--
                Children and younger adults have developed behavioral and mood alterations associated with chronic traumatic encephalopathy, whereas older aged people (more than 50 years old) have faced cognitive difficulties due to CTE. Older adults have less complete recovery compared to younger adults with the same TBI severity`,
        images: [],
        videos: "",
        additional_content: "",
      },
      {
        subtitle: "Genes",
        description: `Certain identified genetic factors also have a role in patient recovery rate. Apolipoprotein E-ε4 (ApoE4) is one identified genetic variant which can worsen health outcomes by inducing Alzheimer’s disease in TBI patients. However, more research is required to explore the influence of genetic factors in TBI recovery.`,
        images: [],
        videos: "",
        additional_content: "",
      },
      {
        subtitle:
          "Available treatment and the requirement for clinical research",
        description: `Cognitive rehabilitation therapy (CRT) is a conventional treatment strategy to help individuals with TBI to regain their normal brain function. An individualized training program is designed for coping with persistent deficiencies related to memory, problem-solving, and thinking skills. The success rate of CRT programs varies from individual to individual. Currently, the research community is working to collect essential data by conducting clinical trials to confidently offer potential new treatments for TBI patients who are facing behavioral disorders. 
                Individuals with TBI can come forward to confirm their eligibility to participate in clinical trials. Patient initiations help researchers to gain knowledge of TBI. Also, provide the opportunity to find out a better way for the safe treatment of the disease. Patients with TBI who are participating in a clinical study can greatly benefit the lives of those living with this disorder and actively participate in their health care. TBI patients who are participating in clinical trials may also receive new treatments before it is available for the general population. Frequent and close monitoring of all the health parameters can improve the scope of general well-being. Additional information during research participation helps patients and their families to understand the details of the disease. Participation can further help them to serve mankind.
                `,
        images: [],
        videos: "",
        additional_content: "",
      },
    ],
    link: Domain + "study/NCT03095066",
    references: [
      "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5657730/",
      "https://www.sciencedirect.com/science/article/pii/S1877065715005783",
      "https://www.ninds.nih.gov/Disorders/Patient-Caregiver-Education/Hope-Through-Research/Traumatic-Brain-Injury-Hope-Through",
    ],
  },
  {
    id: 4,
    title:
      "Participation opportunity in clinical trial for HER2 positive colorectal cancer patients",
    description:
      "Colorectal cancer is one of the most common malignant cancers and a leading cause of cancer-related death worldwide. Adenocarcinomas are the most frequently found colorectal cancer types.",
    author: "PatienTrials",
    date: "12 Apr 2021",
    category: "colorectal cancer",
    image: bnewImg5,
    detailed_image:blogDetail3,
    border_color:"#cf8a46",
    font_color:"white",
    promoted_title:"Colorectal Cancer",
    alignment:"d-flex align-items-center justify-content-start p-5",
    video: "",
    likes: [],
    comments: [],
    sections: [
      {
        subtitle: "Overview",
        description: `Recently a clinical trial enrollment has been started to determine the efficacy of a novel cancer regimen. This is for colorectal cancer with human epidermal growth factor receptor 2 (HER2) positive patients. This trial aims to determine if the researchers can treat this serious health condition with tucatinib, a novel investigational drug by participating in a clinical trial,
           --br--
          Trial ID: NCT03043313
          `,
        images: [],
        videos: "",
        additional_content: "",
      },
      {
        subtitle:
          "What is the necessity to consider participating in such clinical trials?",
        description:
          "Colorectal cancer is one of the most common malignant cancers and a leading cause of cancer-related death worldwide. Adenocarcinomas are the most frequently found colorectal cancer types. Still, very limited chemotherapy treatment options are available for colorectal cancer care. The healthcare sector urgently requires a more effective therapeutic regimen for colorectal cancer treatment. Patients with colorectal cancers can participate in ongoing clinical trials to assist clinical researchers to discover an effective treatment for the future. They can also get the opportunity to treat their serious health condition with a novel therapeutic regimen under close supervision.",
        images: [],
        videos: "",
        additional_content: "",
      },
      {
        subtitle: "Why researchers target HER2 to treat colorectal cancer?",
        description: `The HER2 gene is a proto-oncogene that is located on 17q21 encoding HER2 (ErbB-2) chromosome and takes part in cell proliferation and differentiation. The HER2 protein is a member of the epidermal growth factor receptor (EGFR). HER2 activation leads to tumor progression by taking part in cell proliferation, apoptosis inhibition, and cell differentiation. The RAS and BRAF gene mutation activations are considered wild-type metastatic colorectal cancer due to poor prognosis. The amplification of HER2 has been linked with RAS and BRAF wild-type metastatic colorectal cancers.  
        --br--
        Targeting HER2 oncogene in breast and gastric cancers has already shown effective treatment outcomes. A small percentage of colorectal cancer cases overexpress the HER2 oncogene. Previous researches have found that an anti-EGFR monoclonal antibody treatment gives the successful results to improve progression-free survival in advanced colorectal cancer patients. Therefore, researchers want to explore the HER2 pathway to determine the efficacy against colorectal cancer.
        --br--
        The HER2 pathway as a bypass signaling pathway is responsible for the resistance of anti-EGFR receptor antibody therapy in both the first line and rescue settings. Therefore, targeting HER2 has the potential to validate as a therapeutic target. Researchers are excited to explore the application of anti-HER2 therapy as an additional treatment option to treat metastatic colorectal cancer.
        `,
        images: [],
        videos: "",
        additional_content: "",
      },
      {
        subtitle:
          "How does the investigational drug act against colorectal cancer?",
        description: `Tucatinib is an FDA-approved drug molecule. Clinical research studies have already confirmed that the patients with HER2-positive metastatic breast cancer and brain metastasis had better progression-free survival and overall survival outcomes by adding tucatinib to trastuzumab and capecitabine combination therapy. Therefore, Tucatinib has the efficacy to act as an anti- HER2 therapy. The finding of this result creates great clinical interest to determine if HER2 blockade may be a useful clinical strategy in other human cancers including colorectal cancer.
        Tucatinib is a tyrosine kinase inhibitor and exerts anti-tumor activity. This drug has sufficient bioavailability in the oral route of administration. Tucatinib inhibits human epidermal growth factor receptor tyrosine kinase ErbB-2 that is also known as HER2. This drug has antineoplastic activity. This drug potentially reduces the size of breast cancer tumors in patients with positive HER2.        
        --br--
        Tucatinib precisely binds HER2 to block the phosphorylation of HER2. This may prevent the activation of HER2 signal transduction pathways. Thus, this drug molecule inhibits the growth and kills HER2 positive tumor cells.
        `,
        images: [],
        videos: "",
        additional_content: "",
      },
    ],
    link: Domain + "study/NCT03043313",
    references: [
      "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4039475/",
      "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6475672/",
      "https://pubmed.ncbi.nlm.nih.gov/30100092/",
      "https://www.fda.gov/drugs/resources-information-approved-drugs/fda-approves-tucatinib-patients-her2-positive-metastatic-breast-cancer",
      "https://pubmed.ncbi.nlm.nih.gov/30100092/",
      "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6475672/",
      "https://pubchem.ncbi.nlm.nih.gov/compound/Tucatinib#section=Pharmacology-and-Biochemistry",
    ],
  },
  {
    id: 5,
    title: "Why consider enrolling in clinical trials on trials.me?",
    description:
      "Participation in clinical trials may benefit patients where the standard of care is falling short, but it has the potential to positively impact the health of many others",
    author: "PatienTrials",
    date: "14 Apr 2021",
    category: "General",
    image: blog_enroll,
    detailed_image:blog_enroll,
    border_color:"#BC5405",
    font_color:"black",
    promoted_title:"Why to enroll in Clinical Trials?",
    alignment:"d-flex align-items-center justify-content-center",
    video: "",
    likes: [],
    comments: [],
    sections: [
      {
        subtitle: "",
        description: `
        Participation in clinical trials may benefit patients where the standard of care is falling short, but it has the potential to positively impact the health of many others. Taking part in the advancement of medical science and helping clinical investigators to gain detailed knowledge of the disease, medicine, medical device, and diagnostic tests as applicable opens up the hope for better healthcare for all. Clinical trials allow for the development of novel therapies that are safe and effective, leading to the treatment strategies for successful treatment outcomes. Participation in a clinical trial may also allow for the exploration of the comparison of existing treatments to evaluate better safety and efficacy between them, leading to the development of standard treatment strategies. Participation in a clinical trial also allows the patients to access potentially better treatments. Ultimately, the discovery and development of new treatment modalities is helpful for achieving better treatment outcomes in the future.
        --br--
        Trials.me is a technology platform with a singular focus on enabling recruitment and retention of patients on clinical trials that are essential for the advancement of medical science. Successful conduct of clinical trials is not an easy task and among several challenges, research organizations often face the common challenges of patient recruitment and retention. Participation in a clinical trial is a completely voluntary decision for the participants, but it is important that participants fully understand the clinical trial process, the risks and benefits associated with participation, and should make a careful and informed decision along with their families before enrolling in a clinical trial.
        `,
        images: [],
        videos: "",
        additional_content: "",
      },
      {
        subtitle: "Patient as ‘first class citizens’ on trials.me",
        description: `
        -*- Trials.me has a carefully thought out, systematic strategy to help sponsors of clinical trials as well as the clinical research sites that are selected for recruitment to publish and promote Institutional Review Board (IRB) approved content. A clear and concise write-up by medical professionals on the specific indication being researched, further helps participants to understand the context of the clinical trial
        -*- The infrastructure extends support for decentralized clinical trials, allowing patients to participate in clinical trials from the comfort of their homes where feasible and/or visit their local provider’s clinics for certain aspects of the assessments as required
        -*- Study teams have the ability to conduct a more thorough eligibility screening process by engaging with participants via telehealth sessions and explaining the study risks and benefits. Access to the participant’s electronic health records post their consent further allows study teams to understand the medical history, medications, allergies, immunization records and other information to more precisely determine their eligibility. This reduces the burden on the participants as well as the research sites while allowing them to schedule virtual appointments based on mutual convenience
        -*- Participants considering enrollment in clinical trials through Trials.me are presented with a well laid out informed consent process. In this process, researchers provide information about a clinical study to the potential and enrolled participants. This information helps participants to decide whether they want to enroll or continue their participation in the trial.
        -*- The informed consent process in Trials.me is intended to protect participants by describing the risks, potential benefits, and alternatives to the trial  
        -*- The informed consent process in Trials.me stringently follows all the necessary documentation that may involve Q&A sessions, activities to access participant understanding, verbal instructions, and recruitment materials. The platform allows for remote video consenting and/or in-person consenting either digitally or using paper while validating the participant’s identity and allowing legally authorized representatives to sign off as well. This documentation proves that the participant was thoroughly informed about the risks, potential benefits, and alternatives and that he/she understood it properly
        -*- The ease of onboarding and engaging study teams and patients in the secure context of the clinical trial is very unique to Trials.me. Effective communication with participants helps overcome the significant barrier that restricts them to participate or continue in the trial. Additionally, gamification techniques to enhance engagement and a messaging based interface to asynchronously communicate with the study team makes it extremely easy and convenient for participants to get their concerns addressed in a timely manner
        `,
        images: [],
        videos: "",
        additional_content: "",
      },
    ],
    link:
      Domain +
      `enroll/${environment === "PROD" ? "STU01009" : "STU0001002"}`,
    references: [],
  },
  {
    id: 6,
    title:
      "Volunteers require GlaxoSmithKline-sponsored clinical trial participation for testing Mepolizumab against COPD",
    description:
      "GlaxoSmithKline is a well-known science-led global healthcare company that involves in the research, development, and manufacture of innovative pharmaceutical medicines, vaccines, and consumer healthcare products.",
    author: "PatienTrials",
    date: "19 Apr 2021",
    category: "COPD",
    image: blog_gsk,
    detailed_image:blog_gsk,
    border_color:"#BC5405",
    font_color:"white",
    promoted_title:"See if you may qualify for a COPD clinical trial",
    alignment:"d-flex align-items-center justify-content-start",
    video: "",
    likes: [],
    comments: [],
    sections: [
      {
        subtitle: "Overview",
        description: `
        GlaxoSmithKline is a well-known science-led global healthcare company that involves in the research, development, and manufacture of innovative pharmaceutical medicines, vaccines, and consumer healthcare products. Worldwide millions of people get health benefits by using the healthcare products of GlaxoSmithKline. 
        --br--
        In 2015, European Union has approved Mepolizumab for patients with severe eosinophilic asthma. Randomized controlled clinical trials have established that the mepolizumab reduces exacerbations rate, oral corticosteroid (OCS) dependence, and improves asthma control, lung function, and quality of life in patients with severe eosinophilic asthma compared with placebo. The experts are reviewing different clinical studies to check the safety and efficacy of mepolizumab against eosinophilic asthma. The scientific review report concluded that mepolizumab could be an add-on treatment in combination with systemic glucocorticoid therapy for patients with severe eosinophilic asthma.
        `,
        images: [],
        videos: "",
        additional_content: "",
      },
      {
        subtitle: "How Mepolizumab improves lung functionality?",
        description: `
        An increased level of eosinophils occurs in inflammatory conditions like asthma, atopic dermatitis, and hypereosinophilic syndrome. Higher than 200 cells/mcL blood level of eosinophil is a sign of asthma patients. IL-5 is a cytokine that takes part in eosinophils' development and activation. Mepolizumab is a human-made monoclonal antibody used as targeted therapy. Mepolizumab specifically targets IL-5 for preventing its attachment with the IL-5 receptor sited on the eosinophil surface. 
        `,
        images: [],
        videos: "",
        additional_content: "",
      },
      {
        subtitle:
          "The objective of conduction of GlaxoSmithKline sponsored clinical trial?",
        description: `
        GlaxoSmithKline is providing high-quality, differentiated, and required healthcare products to a maximum number of populations.
        --br--
        The purpose of conducting clinical trials is to find improved ways to prevent, monitor for detection, or treat a disease in a regulated manner through research studies. Sometimes clinical trials are done to establish safety and efficacy of an already registered drug for a newer condition.
        --br--
        Mepolizumab is already an established medicine for severe eosinophilic asthma. But this current clinical study aims to investigate the safety and effectiveness of this medicine for potentially reducing moderate-to-severe symptom flare-ups for some people living with COPD.
        `,
        images: [],
        videos: "",
        additional_content: "",
      },
      {
        subtitle: "Why join this clinical trial?",
        description: `This study requires a uniform and well-characterized COPD patient population to supervise through careful monitoring, and control of factors that may confound or modify any potential effects related to the disease condition. The design of this clinical trial has a purpose to answer specific questions about this treatment's benefits.`,
        images: [],
        videos: "",
        additional_content: "",
      },
      {
        subtitle: "What are the study specifications?",
        description: `This study is a phase 3 study. The duration of this study is approximately 12 months and requires up to 14 clinic visits. The participants need not require an overnight stay in the clinical set-up during the study. The study result will be published after the completion of the trial. Participants cannot continue to take the investigational medicine after completion of the study.
        All study-related medications, analysis, clinic visits, and procedures will be provided at no cost. Travel reimbursement may be available and can be discussed with study staff.
        `,
        images: [],
        videos: "",
        additional_content: "",
      },
      {
        subtitle: "Who can participate in this clinical trial?",
        description: `
        120 US-based study participants can join in this study. The eligibility criteria for participating in this trial are:
        -*- be 40 years or older
        -*- have been diagnosed by a medical professional with COPD
        -*- have had at least 2 moderate or 1 severe COPD flare-ups within the past year
        -*- must be currently taking COPD medications
        --br--
        Participants must not:
        -*- have had cancer in the past year (excluding skin carcinoma or cervical)
        -*- have liver disease
        `,
        images: [],
        videos: "",
        additional_content: "",
      },
    ],
    link: Domain + "study/NCT04133909",
    references: [
      "https://erj.ersjournals.com/content/55/6/1902345",
      "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5046999/",
      "https://www.gsk.com/en-gb/home/",
      "https://antidote.me/prescreener/s/en-US/chronic_obstructive_pulmonary_disease-134/study-details",
    ],
  },
];

export { blogArticles };
