import React from "react";
import { RecruitmentSteps, ScreenFlow } from "../components";
import {
  enrollLink,
  getInclusionCriteria,
  getExclusionCriteria,
} from "../helpers/helper";
import {
  enroll_gender,
  enroll_age,
  enroll_rank,
  upcomingStudyIcon,
  vluLogo,
} from "../features/theme/icons";
import Map from "./Map";
import { Google_Map_Key } from "../constants";

const Vlu = (props) => {
  const { trial, searchTerm } = props;
  const flowarray = () => {
    return [
      { title: "Home", link: "/" },
      { title: "Find Trials", link: "/#find_trial" },
      {
        title: "Search Results",
        link: `/results?q=venous%20ulcers&in=UNITED%20STATES`,
      },
      {
        title: "Trial Details",
        link: `/vlu/`,
      },
    ];
  };

  return (
    <div>
      <div>
        <div className="container">
          <RecruitmentSteps step={"Review Trials"} />
          <div className="container d-flex align-items-center justify-content-start px-0 mx-0">
            <ScreenFlow flow={flowarray()} />
          </div>
          <div
            style={{
              background: "rgb(241 241 241)",
              //"linear-gradient(180deg, rgba(196, 196, 196, 0) 12.81%, rgba(118, 214, 238, 0.2) 73.23%)",
            }}
            className="row p-3"
          >
            <div className="col-lg-6 p-0 d-flex align-items-center justify-content-start">
              <img
                style={{ height: 270, width: "90%" }}
                className="upcoming_study_image img-responsive"
                src={vluLogo}
                alt="study_image"
              ></img>
            </div>
            <div className="upcoming_paid col-lg-6 p-0 my-auto">
              <div className="text-left">
                <h3
                  style={{ fontSize: "20px", letterSpacing: 1.5 }}
                  className="col-lg-12 text-left my-3 px-0"
                >
                  Efficacy of TR 987, Beta-1,3-1,6-D-glucan, in the Treatment of
                  Chronic Venous Insufficiency Ulcers
                </h3>
                <p style={{ fontWeight: "normal" }} className="my-3">
                  Trial Status :{" "}
                  <span style={{ color: "#cb333b", fontWeight: "normal" }}>
                    Not yet recruiting
                  </span>
                </p>
                {/* <div>
                  <p className="mb-0">CareDx Trials Safety Group</p>
                  <div className="d-flex align-items-center justify-content-center my-0 py-0">
                    <p className="mb-0" style={{ fontWeight: "normal" }}>
                      45 Rockefeller Plaza,
                    </p>
                    <p className="mb-0" style={{ fontWeight: "normal" }}>
                      New York,
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-center my-0 py-0">
                    <p className="mb-0" style={{ fontWeight: "normal" }}>
                      New York,
                    </p>
                    <p className="mb-0" style={{ fontWeight: "normal" }}>
                      USA,
                    </p>
                    <p className="mb-0" style={{ fontWeight: "normal" }}>
                      10111
                    </p>
                  </div>
                </div> */}
                {/* <button
                      onClick={() =>
                        enrollLink(trial && trial.snow_study_number)
                      }
                      className="upcoming_signup btn btn-lg my-3"
                    >
                      SIGN UP FOR THE STUDY
                    </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="upcoming_purpose mt-5 mb-0">
          <h2
            style={{ fontFamily: "GeorgiaBold", fontSize: 26 }}
            className="my-3"
          >
            Purpose
          </h2>
          <p style={{ fontWeight: "normal" }} className="mb-0">
            The safety and efficacy of beta glucan products, and specifically TR
            987, in the treatment of chronic venous insufficiency ulcers has
            been established. This study is designed to determine the most
            efficient method of treatment.
          </p>
        </div>
        <br />
        <div className="upcoming_purpose my-3">
          <h2
            style={{ fontFamily: "GeorgiaBold", fontSize: 26 }}
            className="my-3"
          >
            Description
          </h2>
          <p style={{ fontWeight: "normal" }} className="mb-0">
            This is a multi-center, randomized, double-blind, placebo-controlled
            study to evaluate the effectiveness of TR 987 gel. It is a two-arm,
            design: One group will receive twice-weekly applications of 0.1% TR
            987 in a gel base plus SoC for the first 4 weeks. The other group
            will receive twice-weekly applications of placebo gel base plus SoC
            for the same period of time. After 4 weeks, both groups will receive
            once weekly applications of their assigned treatment for the
            remaining 8 weeks of the trial. The Standard of Care therapy in this
            study is multi-layer compression therapy.
          </p>
        </div>
        <div className="upcoming_qualify mb-3">
          <h3 className="my-2 pt-4">Who Qualifies?</h3>
          <div className="row d-flex align-items-center justify-content-between px-4">
            <div className="text-center col-lg-3 col-sm-12 col-md-12 col-xs-12">
              <img src={enroll_gender} alt="gender"></img>
              <p className="mb-0 my-3">Gender: All</p>
            </div>
            <div className="text-center col-lg-4 col-sm-12 col-md-12 col-xs-12">
              <img src={enroll_age} alt="age"></img>
              {/* <div className="my-3">
                      <span className="mb-0 mx-1">Max - 70</span>
                      <span className="mb-0 mx-1">Min - 20</span>
                    </div> */}
              <div className="my-3">
                <span className="mb-0 mx-1">
                  Minimum Age: 18 years or older
                </span>
                {/* <span className="mb-0 mx-1">
                  Max - {(trial && trial.maximum_age) || "None"} years
                </span> */}
              </div>
            </div>
            <div className="text-center col-lg-4 col-sm-12 col-md-12 col-xs-12">
              <img className="mt-4" src={enroll_rank} alt="rank"></img>
              {/* <p className="mb-0 my-3">Conditions: 1</p> */}
              <p className="mb-0 my-3">
                Conditions: Venous Leg Ulcer, Healthy Volunteers not accepted
              </p>
            </div>
          </div>
        </div>
        <div className="upcoming_purpose">
          <h3 className="my-2 upcoming_criteria">Criteria</h3>
          <h2 className="my-3">Inclusion Criteria</h2>
          <ul>
            <li>Subject is at least 18 years old</li>
            <li>Female subjects are not pregnant or breastfeeding.</li>
            <li>
              Study ulcer has been present for at least one month and has
              undergone more than 2 weeks, but less than 12 months of continuous
              high-strength compression with less than 40% healing.
            </li>
            <li>
              Study ulcer is a minimum of 2.0 cm2 and a maximum of 20.0 cm2,
              extending through the full thickness of the skin, but not down to
              muscle, tendon, or bone at the randomization visit.
            </li>
            <li>
              Study ulcer has a clean, granulating base with minimal adherent
              slough at the randomization visit.
            </li>
            <li>
              If more than one ulcer is present on the same leg, they must be
              more than 2 cm apart and only the larger ulcer will be included in
              the study.
            </li>
            <li>
              Adequate arterial flow, as measured by an Ankle Brachial Pressure
              Index (ABI) of greater than 0.75. (Calculations will be made using
              measurements from both posterior tibial and dorsalis pedis
              arteries, as well as both arms) and/or Skin Perfusion Pressure
              (SPP) &gt;30.
            </li>
            <li>
              Subject understands and is willing to participate in the clinical
              study and can comply with weekly visits and the follow-up regimen.
            </li>
            <li>
              Patient understands and is willing to participate in the clinical
              study and can comply with weekly visits and the follow-up regimen.
            </li>
            <li>
              Patient has read and signed the IRB/IEC approved Informed Consent
              Form before screening procedures are undertaken.
            </li>
          </ul>
        </div>
        <div className="my-2 upcoming_purpose">
          <h2 className="my-3">Exclusion Criteria</h2>
          <p className="mb-0">
            The participant may not enter the trial if{" "}
            <span style={{ textDecoration: "underline" }}>ANY</span> of the
            following apply:
          </p>
          <ul>
            <li>
              Study ulcer deemed by the Investigator to be caused by a medical
              condition other than venous insufficiency.
            </li>
            <li>
              Study ulcer exhibits clinical signs and symptoms of infection.
            </li>
            <li>
              Study ulcer requires enzymatic debridement during the study.
            </li>
            <li>
              Study ulcer has undergone 12 or more months of continuous
              high-strength compression therapy over its duration.
            </li>
            <li>Study ulcer is less than 2.0 cm2 or greater than 20.0 cm2.</li>
            <li>Study ulcer extends more than 50% below the malleolus.</li>
            <li>
              Study ulcer is treated with a topical antibiotic during the
              screening phase.
            </li>
            <li>
              Study ulcer has been treated with tissue engineered material
              (e.g., Apligraf® or Dermagraft®) or other scaffold materials
              (e.g., Oasis or Matristem) within 30 days prior to the TV1
              Randomization visit.
            </li>
            <li>
              Study ulcer requiring negative pressure wound therapy or
              hyperbaric oxygen during the course of the trial.
            </li>
            <li>History of radiation at the study ulcer site.</li>
            <li>
              Study ulcer decreases in area by 30% or more during the 14 days
              screening period.
            </li>
            <li>
              Subjects who are unable to understand the aims and objectives of
              the trial or has a known history of poor adherence with medical
              treatment.
            </li>
            <li>
              Presence of any condition(s) that seriously compromises the
              subject's ability to complete this study.
            </li>
            <li>
              All females of childbearing potential who are not using a highly
              effective method of birth control (failure rate less than 1% per
              year), such implants, injectables, combined oral contraceptives,
              some IUDs, sexual abstinence or vasectomized partner.
            </li>
          </ul>
        </div>
        <div id="map" className="container my-3">
          <h3
            style={{ fontFamily: "GeorgiaBold", fontSize: 26 }}
            className="pt-4"
          >
            Locations
          </h3>
          <Map
            isMarkerShown={true}
            toEnroll={false}
            position={[
              // {
              //   actual_address: "United States, California",
              //   address: "United States, California",
              //   location: { lat: 36.7783, lng: -119.4179 },
              //   //studyId: "STU01667",
              // },
              {
                actual_address:
                  "New Hope Podiatry Clinic	Los Angeles, California, United States, 90063",
                address:
                  "New Hope Podiatry Clinic	Los Angeles, California, United States, 90063",
                location: { lat: 34.1916407, lng: -118.5355619 },
                //studyId: "STU01619",
              },
              // {
              //   actual_address: "United States, Florida",
              //   address: "United States, Florida",
              //   location: { lat: 27.6648274, lng: -81.5157535},
              //   //studyId: "STU01619",
              // },
              {
                actual_address:
                  "Barry University Clinical Research North Miami Beach, Florida, United States, 33169",
                address:
                  "Barry University Clinical Research North Miami Beach, Florida, United States, 33169",
                location: { lat: 25.9293219, lng: -80.2039838 },
                //studyId: "STU01619",
              },
              {
                actual_address:
                  "Royal Research Corp Pembroke Pines, Florida, United States, 33027",
                address:
                  "Royal Research Corp Pembroke Pines, Florida, United States, 33027",
                location: { lat: 26.01203, lng: -80.183213 },
                //studyId: "STU01619",
              },
              // {
              //   actual_address: "United States, New Jersey",
              //   address: "United States, New Jersey",
              //   location: { lat: 40.0583238, lng: -74.4056612 },
              //   //studyId: "STU01619",
              // },
              {
                actual_address:
                  "Deborah Heart and Lung Center Browns Mills, New Jersey, United States, 08015",
                address:
                  "Deborah Heart and Lung Center Browns Mills, New Jersey, United States, 08015",
                location: { lat: 39.977618, lng: -74.584293 },
                //studyId: "STU01619",
              },
              // {
              //   actual_address:
              //     "United States, Ohio",
              //   address:
              //     "United States, Ohio",
              //   location: { lat: 40.4172871, lng: -82.90712300000001 },
              //   //studyId: "STU01619",
              // },
              {
                actual_address:
                  "Cleveland Foot and Ankle Clinic Cleveland, Ohio, United States, 44103",
                address:
                  "Cleveland Foot and Ankle Clinic Cleveland, Ohio, United States, 44103",
                location: { lat: 41.5039984, lng: -81.64120799999999 },
                //studyId: "STU01619",
              },
              // {
              //   actual_address:
              //     "United States, Pennsylvania",
              //   address:
              //     "United States, Pennsylvania",
              //   location: { lat: 41.2033216, lng: -77.1945247 },
              //   //studyId: "STU01619",
              // },
              {
                actual_address:
                  "The Foot and Ankle Wellness Center Ford City, Pennsylvania, United States, 16226",
                address:
                "The Foot and Ankle Wellness Center Ford City, Pennsylvania, United States, 16226",
                location: { lat: 40.770897, lng: -79.5317145 },
                //studyId: "STU01619",
              },
              {
                actual_address:
                  "Armstrong County Memorial Hospital	Kittanning, Pennsylvania, United States, 16201",
                address:
                  "Armstrong County Memorial Hospital	Kittanning, Pennsylvania, United States, 16201",
                location: { lat: 40.8085788, lng: -79.56145769999999 },
                //studyId: "STU01619",
              },
              {
                actual_address:
                  "SerenaGroup Research Institute	Pittsburgh, Pennsylvania, United States, 15222",
                address:
                "SerenaGroup Research Institute	Pittsburgh, Pennsylvania, United States, 15222",
                location: { lat: 40.442239, lng: -80.004639 },
                //studyId: "STU01619",
              },
              {
                actual_address:
                  "Martin Foot and Ankle York, Pennsylvania, United States, 17402",
                address:
                "Martin Foot and Ankle York, Pennsylvania, United States, 17402",
                location: { lat: 39.9869565, lng: -76.68430149999999 },
                //studyId: "STU01619",
              }
            ]}
            showMap={true}
            containerElement={<div className="map_dimensions" />}
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${Google_Map_Key}`}
            loadingElement={<div style={{ height: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
      </div>
    </div>
  );
};

export default Vlu;
