import React from "react";
import "../App.css";
import "../bootstrap.css";
import {
  infoLight,
  infoDark,
  chevronRightDark,
  chevronRightLight,
} from "../features/theme/icons";

const steps = ["Find a trial", "Review Trials", "Self Assessment", "Enroll"];

const RecruitmentSteps = (props) => {
  // provide index value so that prop will match with the steps arrayitem
  return (
    <div>
      <div className="recruitment_steps py-3 container border-bottom d-flex align-items-center justify-content-center">
        <div className="container">
          <div className="row d-flex align-items-center justify-content-between mx-5">
            {steps.map((step, i) => (
              <div
                key={i}
                className="d-flex align-items-center justify-content-around mx-3 text-left"
              >
                <h2
                  style={{
                    fontFamily: "LatoRegular",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "14px",
                    textTransform: "uppercase",
                    color:
                      step === steps.filter((item) => item === props.step)[0]
                        ? "#000000"
                        : "#BDBDBD",
                  }}
                  className="mt-2"
                >
                  {step === steps.filter((item) => item === props.step)[0]
                    ? steps.filter((item) => item === props.step)[0]
                    : step}
                </h2>
                <div className="mx-2 row">
                  <img
                    className="w-50"
                    src={
                      step === steps.filter((item) => item === props.step)[0]
                        ? infoDark
                        : infoLight
                    }
                    alt={"info"}
                  ></img>
                  <img
                    className="w-50"
                    src={
                      step === steps.filter((item) => item === props.step)[0]
                        ? chevronRightDark
                        : chevronRightLight
                    }
                    alt={"right arrow"}
                  ></img>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecruitmentSteps;
