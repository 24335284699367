import React, { useEffect, useState } from "react";
import "../../../App.css";
import {
  RecruitmentSteps,
  ScreenFlow,
  Loading,
  Map,
} from "../../../components";
import { withRouter } from "react-router-dom";
import { getLocations, getZipcodes } from "../../../helpers/index";
import ShowRequirements from "../enrollment requirements/EnrollmentRequirements";
import { saveButton, greenDot } from "../../../features/theme/icons";
import { log } from "../../../helpers/helper";
import { Google_Map_Key } from "../../../constants";

// const suggestedSearches = [
//   "Covid-19",
//   "Cancer & Oncology",
//   "Cardiology",
//   "Chronic Condition",
//   "Therapeutic",
// ];

const CTView = (props) => {
  const {
    isLoading,
    studyId,
    trial,
    showRequirements,
    city,
    state,
    country,
    zipcode,
    queryCondition,
    queryCountry,
    hideSteps,
  } = props;

  const flowarray = [
    { title: "Home", link: "/" },
    { title: "Find Trials", link: "/#find_trial" },
    {
      title: "Search Results",
      link: `/results?q=${queryCondition}&in=${queryCountry}`,
    },
    {
      title: "Trial Details",
      link: `/study/${studyId}/?q=${queryCondition}&in=${queryCountry}`,
    },
  ];

  const [coordinates, setCoordinates] = useState([]);
  const [map, setMap] = useState(false);
  useEffect(() => {
    let sites = [];
    if (getLocationInfo(trial) && getLocationInfo(trial).length > 0) {
      //log(`${getLocationInfo(trial)}, "locs"`);
      let zipcodes =
        getLocationInfo(trial) && getLocationInfo(trial).length > 0
          ? getLocationInfo(trial).map((location) => location.zip)
          : [];
      //log(`${zipcodes},'zips'`);
      if (zipcodes && zipcodes.length > 0) {
        getZipcodes(
          queryCountry === "UNITED%20STATES" ? "United States of America" : queryCountry,
          zipcodes
        )
          .then((success) => {
            //log(`${success},'got nearestzipcodes'`);
            if (success && success.length > 0) {
              success.map((d) => log(parseInt(d.Distance), "distance"));
              // log(
              //   `${success.sort((a, b) => {
              //     return a.Distance - b.Distance;
              //   })}`
              // );
            }
          })
          .catch((fail) => {
            //log(`${fail},'failure with nearest zipcodes'`);
          });
      }
      getLocationInfo(trial).map((location) => {
        //log(`${location.city},${location.state},${location.country},'details'`);
        return getLocations(
          location.city + " " + location.state === undefined
            ? ""
            : location.state + " " + location.country + " " + location.zip
        )
          .then((gotLocations) => {
            sites.push({
              location: gotLocations.results[0].geometry.location,
              address: gotLocations.results[0].formatted_address,
            });
          })
          .catch((errLocation) => {
            //log(`${errLocation}, "err with geocoding"`);
          });
      });
      setCoordinates(sites);
      setTimeout(() => {
        setMap(true);
      }, 1500);
    }
  }, []);

  const getContactInfo = (trial) => {
    let role = trial["central contact role"];
    let name = trial["Central contact name"];
    let email = trial["Central contact email"];
    let phone = trial["Central contact phone"];
    let contacts = [];
    if (role && role.length > 0) {
      role.split(',').map((item, i) => {
        let obj = {
          name: name.split(',')[i],
          email: email.split(',')[i],
          role: item,
          phone: phone.split(',')[i],
        };
        contacts.push(obj);
      });
      //log(contacts);
      return contacts;
    } else {
      return [];
    }
  };
  const getLocationInfo = (trial) => {
    let country = trial["LocationCountry"];
    let state = trial["LocationState"];
    let city = trial["LocationCity"];
    let zip = trial["LocationZip"];
    let locations = [];
    if (zip && zip.length > 0) {
      zip.split(',').map((item, i) => {
        let obj = {
          country: country.split(',')[i],
          state: state.split(',')[i],
          city: city.split(',')[i],
          zip: item,
        };
        locations.push(obj);
      });
      //log(locations);
      return locations;
    } else {
      return [];
    }
  };
  const TrialDetails = () => {
    const {
      NCTId,
      Condition,
      BriefTitle,
      BriefSummary,
      EligibilityCriteria,
      StudyType,
      Gender,
      OfficialTitle,
    } = trial;
    return (
      <div className="container p-0 pt-3">
        <div className="trialdetails row">
          <div className="col-sm-12 col-md-9 col-lg-9">
            {hideSteps === true ? null : (
              <div className="mt-2 d-flex align-items-start justify-content-between">
                <ScreenFlow flow={flowarray} />
                <img src={saveButton} alt="save icon"></img>
              </div>
            )}
            <div className="mt-1 d-flex align-items-start justify-content-between">
              <div>
                <img className="mx-2" src={greenDot} alt="greendot"></img>
                <span className="mx-1">{NCTId}</span>
                <span className="mx-1">{"|"}</span>
                {Condition && Condition.length > 0 ? (
                  Condition.length < 2 ? (
                    Condition.map((disease, i) => (
                      <span key={i} className="mx-1">
                        {disease}
                      </span>
                    ))
                  ) : (
                    <span>
                      {Condition[0]}, {Condition.length - 1}
                      {"more ..."}
                    </span>
                  )
                ) : (
                  <span className="mx-1">{"None"}</span>
                )}
              </div>
              {/* <div>
                <h3 className="updated_date">Last updated on July 2020</h3>
              </div> */}
            </div>
            <div className="details m-2">
              <h2 className="title">{BriefTitle}</h2>
              <h3 className="py-4">
                Clinical Study Identifier:{" "}
                <span style={{ color: "#006D9C" }} className="mx-4">
                  {NCTId}
                </span>
              </h3>
              {showRequirements ? (
                <ShowRequirements
                  criteria={EligibilityCriteria}
                  type={"ts"}
                  sysId={NCTId}
                  city={city}
                  country={country}
                  state={state}
                  zipcode={zipcode}
                  queryCountry={queryCountry}
                  queryCondition={queryCondition}
                />
              ) : (
                <div>
                  <div className="row">
                    <div className="goal-sponsor col-sm-12 col-md-6 col-lg-6 ml-0 pl-0 text-left">
                      <h2>Gender</h2>
                      <p
                        className="mb-0"
                        style={{
                          color: "#006D9C",
                          textDecoration: "none",
                        }}
                      >
                        {Gender}
                      </p>
                    </div>
                    <div className="goal-sponsor col-sm-12 col-md-6 col-lg-6 ml-0 pl-0 text-left">
                      <h2>Study Type</h2>
                      <p
                        style={{
                          color: "#006D9C",
                          textDecoration: "none",
                        }}
                      >
                        {StudyType}
                      </p>
                    </div>
                  </div>
                  <div className="purpose-timeframe">
                    <h2 style={{ fontWeight: "bold" }} className="py-1">
                      Official Title
                    </h2>
                    <p className="py-1">{OfficialTitle}</p>
                  </div>
                  <div className="purpose-timeframe">
                    <h2 style={{ fontWeight: "bold" }} className="py-1">
                      Description
                    </h2>
                    <p className="py-1">{BriefSummary}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-3 col-sm-12 col-md-3 h-75">
            <div className="closestlocation col-sm-12 p-4 mt-5 text-left">
              {trial && trial.LocationZip && trial.LocationZip.length === 0 ? (
                <h2>{"Location details not found"}</h2>
              ) : (
                <div>
                  <h2>THE CLOSEST LOCATION</h2>
                  <h2>
                    {trial.LocationCountry.split(',')[0] +
                      "," +
                      trial.LocationCity.split(',')[0] +
                      "," +
                      trial.LocationZip.split(',')[0]}
                  </h2>
                  <div className="text-center">
                    <div
                      // onClick={() => props.onEnrollForLocation()}
                      style={{
                        backgroundColor: "#BDBDBD",
                        borderRadius: "3px",
                      }}
                    >
                      <button
                        style={{ backgroundColor: "#BDBDBD", border: "none" }}
                        className="enroll text-center btn btn-lg"
                        disabled={true}
                      >
                        Sign Up
                      </button>
                    </div>
                    {/* <p className="my-2">View all Locations</p> */}
                  </div>
                  <div className="notification text-left">
                    <h2>GET NOTIFIED</h2>
                    <br></br>
                    <h2>
                      Sign up to receive email notification when clinical trials
                      are posted in the medical category of interest to you.{" "}
                    </h2>
                    {/* <div style={{ border: "1px solid #E57D24", borderRadius: "3px" }}>
                            <a
                              href="#"
                              className="turn-on-notification d-flex align-items-center justify-content-center border rounded btn btn-lg"
                            >
                              TURN ON NOTIFICATION
                            </a>
                          </div> */}
                  </div>
                </div>
              )}
            </div>
            <div className="closestlocation col-sm-12 p-4 mt-5 text-left mb-3">
              {getContactInfo(trial) && getContactInfo(trial).length > 0
                ? getContactInfo(trial).map((contact, i) => (
                    <div key={i} className="my-2">
                      <h2>{contact.role}</h2>
                      <p
                        style={{ textDecoration: "none", color: "black" }}
                        className="mb-0"
                      >
                        {contact.name}
                      </p>
                      <p
                        style={{ textDecoration: "none", color: "black" }}
                        className="mb-0"
                      >
                        {contact.email}
                      </p>
                      <p
                        style={{ textDecoration: "none", color: "black" }}
                        className="mb-0"
                      >
                        {contact.phone}
                      </p>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
        {/* <div className="container my-3 px-3">
          {map === true ? (
            <Map
              isMarkerShown={true}
              position={coordinates}
              showMap={map}
              containerElement={<div style={{ height: `400px`}} />}
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${Google_Map_Key}&v=3.exp&libraries=geometry,drawing,places`}
              loadingElement={<div style={{ height: `100%` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          ) : null}
        </div> */}
      </div>
    );
  };

  return (
    <div>
      {hideSteps===true ? null : <RecruitmentSteps step={"Review Trials"} />}
      {isLoading ? <TrialDetails hideSteps={hideSteps} /> : <Loading />}
    </div>
  );
};
export default withRouter(CTView);
