import React from "react";
import {
  Warning,
  RecruitmentSteps,
  ScreenFlow,
} from "../../components";
import { questions } from "./questions";

import {
  firstStep,
  secondStep,
  thirdStep,
  covid_19,
  completePhase,
  overallTeam,
  faqPlus,
  cmvCloseIcon,
  greenCheck,
  redUncheck,
} from "../theme/icons";
import { environment } from "../../constants";
import { covidFaqs } from "../../helpers";
import { log } from "../../helpers/helper";

const StudyID = environment === "PROD" ? "STU01011" : "STU01617";
const flowarray = [
  { title: "Home", link: "/" },
  { title: "Find Trials", link: "/#find_trial" },
  {
    title: "Search Results",
    link: `/results?q=covid-19&in=UNITED%20STATES`,
  },
  {
    title: "Trial Details",
    link: `/results?q=covid-19&in=UNITED%20STATES`,
  },
];
const LocationString = `
Woodland, California, US -*-
Colorado Springs, Colorado, US -*-
Pomona, California, US -*-
Little Rock, Arkansas, US -*-
Anaheim, California, US
Fayetteville, North Carolina, US -*-
Lenexa, Kansas, US -*-
Metairie, Louisiana, US -*-
Iowa City, Iowa, US -*-
Atlanta, Georgia, US -*-
New York, New York, US -*-
Meridian, Idaho, US -*-
Evansville, Indiana, US -*-
Norfolk, Nebraska, US -*-
Omaha, Nebraska, US -*-
Tampa, Florida, US -*-
Houston, Texas, US -*-
Memphis, Tennessee, US -*-
McAllen, Texas, US -*-
Anderson, South Carolina, US -*-
Cincinnati, Ohio, US -*-
Medford, Oregon, US -*-
Plano, Texas, US -*-
Cleveland, Ohio, US -*-
Stockbridge, Georgia, US -*-
Jacksonville, Florida, US -*-
Los Alamitos, California, US -*-
Austin, Texas, US -*-
Vista, California, US -*-
Tempe, Arizona, US -*-
Vestavia Hills, Alabama, US -*-
Sacramento, California, US -*-
Columbia, Missouri, US -*-
Lakeland, Florida, US -*-
Gulfport, Mississippi, US -*-
Chicago, Illinois, US -*-
Fort Worth, Texas, US -*-
Cheney, Washington, US -*-
San Antonio, Texas, US -*-
Los Angeles, California, US -*-
Orlando, Florida, US -*-
Commack, New York, US -*-
Gainesville, Florida, US -*-
Phoenix, Arizona, US -*-
West Palm Beach, Florida, US -*-
Oklahoma City, Oklahoma, US -*-
Boston, Massachusetts, US -*-
North Hollywood, California, US -*-
Columbus, Georgia, US -*-
Banning, California, US -*-
Newport News, Virginia, US -*-
Durham, North Carolina, US -*-
Baton Rouge, Louisiana, US -*-
Saint Louis, Missouri, US -*-
Raleigh, North Carolina, US -*-
San Diego, California, US -*-
Chapel Hill, North Carolina, US -*-
Hollywood, Florida, US -*-
Aurora, Colorado, US -*-
DeLand, Florida, US
`;
class NCT04611802 extends React.Component {
  constructor(props) {
    super();
    this.obj = {};
    this.state = {
      hideWarning: true,
      uncheck: true,
      token: null,
      location: "",
      city: "",
      state: "",
      country: "",
      criteria: null,
      showFaq: true,
      faqIcon: faqPlus,
      faqid: 0,
      checked:false
    };
  }

  goToEnroll = () => {
    window.location.href = `/enroll/${StudyID}/ps?city=${this.state.city}&state=${this.state.state}&country=${this.state.country}`;
  };
  onCheckboxChange = (e, qid) => {
    this.setState({checked:null})
    if (e.target.checked) {
        log(`${e.target.checked}, ${e.target.value}, "checking inputs"`);      if (e.target.type === "checkbox") {
        this.obj.hasOwnProperty(qid)
          ? this.obj[qid].push(e.target.value)
          : (this.obj[qid] = [e.target.value]);
      } else {
        this.obj.hasOwnProperty(qid)
          ? (this.obj[qid] = [e.target.value])
          : (this.obj[qid] = [e.target.value]);
      }
    } else {
      if (e.target.type === "checkbox") {
        log(`${e.target.checked}, ${e.target.value}, "checking inputs"`);
        if (this.obj.hasOwnProperty(qid)) {
          let arr = this.obj[qid].indexOf(e.target.value);
          this.obj[qid].splice(arr, 1);
        }
      }
    }
    log(this.obj+"in covid oncheckbox");
  };

  onQualify = () => {
    let qualify = [],
      secondQues,
      thirdQues;
    Object.keys(this.obj).map(uq => {
      if (
        uq ===
        "Have you been diagnosed with any of the following medical conditions that put you at increased risk of COVID-19 infection?"
      ) {
        let thirdAns = [];
        //this.checkForOneQuestion(uq).map((tans) => thirdAns.push(tans));
        thirdQues = thirdAns;
      } else if (uq === "How do you identify your race or ethnicity?") {
        let secondAns = [];
        this.checkForOneQuestion(uq).map((sans) => secondAns.push(sans));
        secondQues = secondAns;
      } else {
        log(this.checkForOneQuestion(uq)+"got uq");
        qualify.push(this.checkForOneQuestion(uq));
      }
    });
    if (secondQues && secondQues.length > 0) {
      let falseSecond = secondQues.filter((ans) => ans.result === false);
      if (falseSecond && falseSecond.length > 0) {
        qualify = qualify.concat(thirdQues);
      } else {
        qualify = qualify.concat(secondQues);
      }
    }
    log(qualify+"qualify array");
    if (qualify && qualify.length > 0) {
      let disqualify = qualify.filter((item) => item.result === false);
      if (disqualify && disqualify.length > 0) {
        alert(
          "Unfortunately, based on your answers to the questions, you're not eligible to participate in this trial."
        );
      } else {
        log("user profile matched");
        this.setState({checked:false},()=>{
          this.obj={}
        })
        this.goToEnroll();
      }
    }
  };

  checkForOneQuestion = (question) => {
    log(question+"q");
    let gotQues = questions.filter((cmvq) => cmvq.question === question);
    if (gotQues && gotQues.length > 0) {
      if (gotQues && gotQues[0].type === "checkbox") {
        let checkEligibility = gotQues[0].answers.filter((checkboxans) =>
          this.obj[question].includes(checkboxans.option)
        );
        log(checkEligibility+"for checkbox type");
        return checkEligibility;
      } else {
        let checkEligibility = gotQues[0].answers.filter(
          (ans) => ans.option === this.obj[question][0]
        );
        log(checkEligibility+"qualify check");
        return checkEligibility[0];
      }
    }
  };
  onPromoSubmit = () => {
    let ckeys = Object.keys(this.obj);
    let cvalues = Object.values(this.obj);
    if (ckeys && ckeys.length > 0) {
      if (ckeys && ckeys.length < questions.length) {
        alert(
          "Please answer all the eligibility criteria questions. If you press back button, Please reload the page and retry. Thank you!"
        );
      } else {
        if (cvalues && cvalues.length === 0) {
          alert(
            "Please answer all the eligibility criteria questions. If you press back button, Please reload the page and retry. Thank you!"
          );
        } else {
          let val = Object.values(this.obj).filter(
            (qid) => qid && qid.length === 0
          );
          if (val && val.length === 0) {
            this.onQualify();
            this.setState({ criteria: JSON.stringify(this.obj) }, () => {
              log(JSON.stringify(this.obj));
              localStorage.setItem("criteria", JSON.stringify(this.obj));
            });
          } else {
            alert(
              "Please answer all the eligibility criteria questions. If you press back button, Please reload the page and retry. Thank you!"
            );
          }
        }
      }
    } else {
      alert(
        "Please answer all the eligibility criteria questions. If you press back button, Please reload the page and retry. Thank you!"
      );
    }
  };

  onExpandFaq = (id) => {
    this.setState({ faqid: id, showFaq: true });
    if (id === this.state.faqid && this.state.showFaq === false) {
      this.setState({ showFaq: true });
    } else if (id === this.state.faqid) {
      this.setState({ showFaq: false });
    }
  };
  faqAnswerList = (list) => {
    let [first, ...remainingList] = list;
    return (
      <div className="faq">
        <h3 style={{ fontFamily: "LatoRegular" }} className="my-2">
          {first}
        </h3>
        {remainingList.map((listitem) => (
          <ul className="pl-4">
            <li style={{ fontFamily: "LatoRegular" }}>{listitem}</li>
          </ul>
        ))}
      </div>
    );
  };
  onLocationSelect = (location) => {
    this.setState({ location: location }, () => {
      let splitLocation = this.state.location.split(",");
      let city = splitLocation[0];
      let splitLocationRemaining = splitLocation[1].split(" ");
      log(splitLocationRemaining+"remaining");
      let state = splitLocationRemaining[1];
      let country = splitLocationRemaining[2];
      this.setState({
        city: city,
        state: state,
        country: country,
      });
    });
  };

  hideWarning = () => {
    this.setState({ hideWarning: false });
  };
  gotoCheckElig = () => {
    window.location.href = "/study/NCT04611802#criteria";
  };
  componentDidMount() {
    log(questions+"questions for criteria");
  }

  // Preview = () => {
  //   return (
  //     <div className="preview_mode py-3 align-items-center justify-content-center row">
  //       <img
  //         className="warning_icon mx-3 img-fluid"
  //         src={previewWarning}
  //         alt="warning"
  //       ></img>
  //       <div className="preview_content mx-3 text-center">
  //         <div className="d-flex align-items-center justify-content-between">
  //           <h5>Preview Mode</h5>
  //           <img
  //             onClick={() => this.hideWarning()}
  //             className="warning_close_icon"
  //             src={warningCloseIcon}
  //             alt="close"
  //           ></img>
  //         </div>
  //         <p className="mb-0">
  //           Because this trial hasn't started recruiting via connect yet. This
  //           page isn't publically available. It will become publically availabe
  //           on 11/16/2020
  //         </p>
  //       </div>
  //     </div>
  //   );
  // };
  render() {
    const { showFaq, faqid, checked } = this.state;
    return (
      <div>
        {this.props.location &&
        this.props.location.search &&
        this.props.location.search.indexOf("embed=true") !== -1 ? null : (
          <>
            <RecruitmentSteps step={"Review Trials"} />
            <div className="container d-flex align-items-center justify-content-start">
              <ScreenFlow flow={flowarray} />
            </div>
          </>
        )}
        <div className="container-fluid p-0">
          <div className="container p-0">
            <Warning
              subtitle={
                "This trial has completed recruitment on this platform, and is no longer accepting new referrals."
              }
            />
            {/* vaccine trial */}
            <div className="vaccine my-2 pt-1 row">
              <div className="left pt-5 text-left col-md-7 col-sm-12 col-lg-7 align-items-center justify-content-center">
                <h2 className="pt-3">A vaccine trial for COVID-19</h2>
                <h3 className="py-3">
                  COVID-19 has become a global health threat, and the need for a
                  safe and effective vaccine is critical.
                </h3>
                <p>Trial ID: 2019nCov-301</p>
              </div>
              <div className="right col-md-5 col-sm-12 col-lg-5 p-0">
                <img className="img-fluid" alt="covid" src={covid_19}></img>
              </div>
            </div>
            {/* Novavax commitment */}
            <div className="novavax">
              <h2>Novavax Commitment to Diversity & Inclusion</h2>
              <div className="row my-4">
                <div className="left col-md-6 col-sm-12 col-lg-6 pl-1">
                  <img
                    className="img-fluid"
                    alt="novavax"
                    src={overallTeam}
                  ></img>
                </div>
                <div className="col-md-6 col-sm-12 col-lg-6 mt-1">
                  <h3>
                    This clinical trial is testing whether the investigational
                    NVX-CoV2373 vaccine candidate will prevent COVID-19
                  </h3>
                  <br></br>
                  <p className="mb-0">
                    With over 100 study sites, we are looking for up to 30,000
                    participants from various backgrounds to take part in this
                    clinical trial. We have partnered with study sites who are
                    dedicated to reaching individuals who by virtue of their
                    life circumstances (i.e. living or working conditions) are
                    considered at substantial risk of exposure to and infection
                    with COVID-19. Within this high-risk population of
                    individuals, prioritization will be given to the enrollment
                    of important groups that have been disproportionately
                    impacted by COVID-19.
                  </p>
                </div>
                <p className="mb-0 my-2 px-1">
                  These groups include members of the Black/African American,
                  LatinX or Native American communities, people living with
                  medical conditions commonly associated with complications
                  arising from COVID-19 disease (e.g., obesity, chronic kidney
                  or lung disease, cardiovascular disease and Type 2 diabetes),
                  and the elderly.
                </p>
              </div>
            </div>
            {/* check eligibility */}
            <div className="my-5 promo_eligibility p-4">
              <h2 className="my-3">Check Your Eligibility</h2>
              <h3 className="my-3">
                We’ll check to see if you’re eligible by asking you some
                questions
              </h3>
              <button
                onClick={() => this.gotoCheckElig()}
                className="promo_get_started btn btn-lg"
              >
                GET STARTED
              </button>
            </div>
            {/* trial details */}
            <div className="promo_trial_details px-1">
              <h2 className="my-3">Trial Details</h2>
              <p className="mb-0 my-2">
                This COVID-19 clinical trial is testing whether the
                investigational NVX-CoV2373 vaccine candidate will prevent
                COVID-19. It will compare this vaccine with a placebo, a
                substance that contains no active medication
              </p>
              <h3 className="my-3">
                Participants who qualify for the study will receive:
              </h3>
              <div className="row my-3 d-flex align-items-start justify-content-start">
                <div className="col-md-4 col-sm-12 col-lg-3 mx-3 text-left">
                  <img
                    className="img-fluid"
                    alt="firstStep"
                    src={firstStep}
                  ></img>
                  <p className="my-4 mb-0">
                    Study-related medical tests throughout the clinical trial
                  </p>
                </div>
                <div className="col-md-4 col-sm-12 col-lg-3 mx-3 text-left">
                  <img
                    className="img-fluid"
                    alt="secondStep"
                    src={secondStep}
                  ></img>
                  <p className="my-4 mb-0">The study vaccine or placebo</p>
                </div>
                <div className="col-md-4 col-sm-12 col-lg-3 mx-3 text-left">
                  <img
                    className="img-fluid"
                    alt="thirdStep"
                    src={thirdStep}
                  ></img>
                  <p className="my-4 mb-0">
                    Study-related monitoring of health and follow-up care
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* enrollment section */}
          <div className="enrollment_section container-fluid">
            <div className="container">
              <div className="row">
                <div className="mt-4 col-md-6 col-sm-12 col-lg-6 pl-0">
                  <h3 className="mt-5">Estimated Enrollment</h3>
                  <h2 className="mt-2">30,000 participants</h2>
                  <p className="mt-2">
                    Given that certain populations can respond differently to
                    medical therapies, it is extremely important that this
                    clinical trial include people from all races and ethnicities
                    to ensure the vaccine is safe and effective for everyone to
                    use.
                  </p>
                </div>
                <div className="py-2 col-md-6 col-lg-6 col-sm-12">
                  <img
                    className="img-fluid"
                    src={completePhase}
                    alt="phase"
                  ></img>
                </div>
              </div>
            </div>
          </div>
          {/* eligibility criteria */}
          <div className="my-3 covid_eligibility_criteria container">
            <h2 className="my-2">Eligibility Criteria</h2>
            <div className="row">
              <div className="covid_must col-sm-12 col-md-12 col-lg-6 p-0">
                <h3 className="my-4">Participants must:</h3>
                <div className="my-2">
                  <img
                    alt="green_check_icon"
                    className="mx-3"
                    src={greenCheck}
                    style={{ height: 15, width: 15 }}
                  ></img>
                  <span>Be 18 years of age and older</span>
                </div>
                <div className="my-2">
                  <img
                    alt="green_check_icon"
                    className="mx-3"
                    src={greenCheck}
                    style={{ height: 15, width: 15 }}
                  ></img>
                  <span>Have never tested positive for COVID-19</span>
                </div>
                <div className="my-2 d-flex align-items-center justify-content-between">
                  <img
                    alt="green_check_icon"
                    className="mx-3"
                    src={greenCheck}
                    style={{ height: 15, width: 15 }}
                  ></img>
                  <span>
                    Have a greater risk of becoming infected with COVID-19.
                    Examples include:
                  </span>
                </div>
                <ul className="pl-4 ml-5">
                  <li>
                    Represent a racial and ethnic group that has been greatly
                    impacted by the pandemic such as African American, LatinX,
                    Native American
                  </li>
                  <li>Live in crowded conditions such as shared housing</li>
                  <li>
                    Work in close contact with the public or other workers
                    including “essential work” settings: healthcare facilities,
                    farms, factories, grocery stores, restaurants, daycares, and
                    public safety/transportation
                  </li>
                  <li>Have underlying medical conditions</li>
                  <li>Are 65 or older</li>
                </ul>
              </div>
              <div className="covid_not_must col-sm-12 col-md-12 col-lg-6 p-0">
                <h3 className="my-4">Participants are not eligible if they:</h3>
                <div className="my-2">
                  <img
                    alt="red_uncheck_icon"
                    className="mx-3"
                    src={redUncheck}
                    style={{ height: 15, width: 15 }}
                  ></img>
                  <span>Have previously tested positive for COVID-19</span>
                </div>
                <div className="my-2">
                  <img
                    alt="red_uncheck_icon"
                    className="mx-3"
                    src={redUncheck}
                    style={{ height: 15, width: 15 }}
                  ></img>
                  <span>Are pregnant or breastfeeding</span>
                </div>
                <div className="my-2">
                  <img
                    alt="red_uncheck_icon"
                    className="mx-3"
                    src={redUncheck}
                    style={{ height: 15, width: 15 }}
                  ></img>
                  <span>
                    Have participated in another clinical trial in the past
                    month
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            {/* commitments from you */}
            <div className="commitments">
              <h2 className="my-3">Commitments from you</h2>
              <h3 className="my-3" style={{ color: "#12BBE5" }}>
                What's Involved
              </h3>
              <h3 className="my-3">
                Participation for this clinical trial is divded into 3 periods:
              </h3>
              <ul className="pl-4">
                <li className="my-3">
                  Screening:{" "}
                  <span>
                    The study team will determine if you are eligible to take
                    part in the study
                  </span>
                </li>
                <li className="my-3">
                  Vaccine:{" "}
                  <span>
                    : All participants will get 2 injections of the study
                    vaccine or placebo on the first day and again 3 weeks later
                  </span>
                </li>
                <li className="my-3">
                  Follow-up:{" "}
                  <span>
                    The study team will monitor your health until 2 years after
                    the last injection
                  </span>
                </li>
              </ul>
              <h3 className="my-3">
                You will be randomly assigned (by chance) to receive either the
                study vaccine or placebo. A placebo does not contain any active
                ingredient. Neither you nor the study doctor will know which
                group you are in or which injection you receive. Each
                participant will receive 2 injections, 21 days apart.{" "}
              </h3>
            </div>
            {/* locations */}
            <div className="my-5 locations">
              <h3 className="my-3">
                You will need to travel to one of up to{" "}
                {LocationString.split("-*-") &&
                  LocationString.split("-*-").length}{" "}
                sites in the U.S. taking part in this clinical trial. U.S. sites
                are located in:{" "}
              </h3>
              <div className="all_locations border border-light border-rounded">
                <ul>
                  {LocationString.split("-*-").map((location, i) =>
                    this.state.location === location ? (
                      <li
                        key={i}
                        style={{
                          backgroundColor: "darkorange",
                          color: "white",
                        }}
                        // onClick={() => {
                        //   this.setState({ location: location });
                        // }}
                      >
                        {location}
                      </li>
                    ) : (
                      <li
                        key={i}
                        // onClick={() => this.onLocationSelect(location)}
                      >
                        {location}
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
            {/* FAQ's */}
            <div className="my-3 cmv_faq">
              <h2 className="my-5">Frequently Asked Questions (FAQ'S)</h2>
              {covidFaqs.map((faq) => (
                <div
                  onClick={() => this.onExpandFaq(faq.id)}
                  key={faq && faq.id}
                  className="faq border-bottom my-2 py-3"
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <h2>{faq.question}</h2>
                    <img
                      onClick={() => this.onExpandFaq(faq.id)}
                      style={{ height: 25, width: 25 }}
                      src={showFaq && faqid === faq.id ? cmvCloseIcon : faqPlus}
                      alt="plus"
                    ></img>
                  </div>
                  {showFaq && faqid === faq.id ? (
                    <div>
                      {faq.answer.split("-*-") &&
                      faq.answer.split("-*-").length === 0 ? (
                        <h3
                          style={{ fontFamily: "LatoRegular" }}
                          className="my-2"
                        >
                          {faq.answer}
                        </h3>
                      ) : (
                        this.faqAnswerList(faq.answer.split("-*-"))
                      )}
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
            {/* Take Survey */}
            <div id="criteria" className="promo_criteria">
              <h2>Take Survey</h2>
              {questions.map((question) => (
                <div key={question.id} className="promo_question my-3">
                  <h2>{question.question}</h2>
                  {question.answers.map((answer, i) => (
                    <div key={i} className="promo_answer">
                      <input
                        className="mx-1 mt-2"
                        type={question.type}
                        onChange={(e) =>
                          this.onCheckboxChange(e, question.question)
                        }
                        checked={checked}
                        name={question.question}
                        value={answer.option}
                      />
                      <span className="mx-2">{answer.option}</span>
                    </div>
                  ))}
                </div>
              ))}
            </div>
            {environment === 'DEV' ? (
              <div className="my-3 d-flex align-items-center justify-content-end">
                <button
                  style={{ backgroundColor: "#BDBDBD" }}
                  onClick={() => this.onPromoSubmit()}
                  className="promo_next btn btn-lg"
                  disabled={true}
                >
                  Next
                </button>
              </div>
            ) : (
              <div className="my-3 d-flex align-items-center justify-content-end">
                <button
                  style={{ backgroundColor: "#BDBDBD" }}
                  className="promo_next btn btn-lg"
                  disabled={true}
                >
                  Next
                </button>
              </div>
            )} 
          </div>
        </div>
      </div>
    );
  }
}

export default NCT04611802;
