import React from "react";
import "../../../App.css";
import {
  RecruitmentSteps,
  ScreenFlow,
  Loading,
} from "../../../components";
import { withRouter } from "react-router-dom";

import ShowRequirements from "../enrollment requirements/EnrollmentRequirements";
import { saveButton, greenDot } from "../../../features/theme/icons";
import { disableEnrollButton } from "../../../helpers";

// const suggestedSearches = [
//   "Covid-19",
//   "Cancer & Oncology",
//   "Cardiology",
//   "Chronic Condition",
//   "Therapeutic",
// ];

const ScopeView = (props) => {
  const {
    isLoading,
    studyId,
    trial,
    showRequirements,
    type,
    city,
    state,
    country,
    zipcode,
    queryCondition,
    queryCountry,
  } = props;

  const flowarray = [
    { title: "Home", link: "/" },
    { title: "Find Trials", link: "/#find_trial" },
    {
      title: "Search Results",
      link: `/results?q=${queryCondition}&in=${queryCountry}`,
    },
    {
      title: "Trial Details",
      link: `/trialdetails/${studyId}/${type}?q=${queryCondition}&in=${queryCountry}`,
    },
  ];

  const TrialDetails = () => {
    const {
      phase,
      conditions,
      title,
      description,
      criteria,
      nctId,
      studyType,
      gender,
    } = trial;
    return (
      <div className="container p-0 pt-3">
        <div className="trialdetails row">
          <div className="col-sm-12 col-md-9 col-lg-9">
            <div className="mt-2 d-flex align-items-start justify-content-between">
              <ScreenFlow flow={flowarray} />
              <img src={saveButton} alt="save icon"></img>
            </div>
            <div className="mt-1 d-flex align-items-start justify-content-between">
              <div>
                <img className="mx-2" src={greenDot} alt="greendot"></img>
                <span className="mx-1">{phase}</span>
                <span className="mx-1">{"|"}</span>
                {conditions && conditions.length > 0 ? (
                  conditions.length < 2 ? (
                    conditions.map((disease, i) => (
                      <span key={i} className="mx-1">
                        {disease}
                      </span>
                    ))
                  ) : (
                    <span>{`${JSON.parse(conditions)[0]}, ${
                      JSON.parse(conditions).length - 1
                    } more ...`}</span>
                  )
                ) : (
                  <span className="mx-1">{"None"}</span>
                )}
              </div>
              {/* <div>
                <h3 className="updated_date">Last updated on July 2020</h3>
              </div> */}
            </div>
            <div className="details m-2">
              <h2 className="title">{title}</h2>
              <h3 className="py-4">
                Clinical Study Identifier:{" "}
                <span style={{ color: "#006D9C" }} className="mx-4">
                  {studyId}
                </span>
              </h3>
              {showRequirements ? (
                <ShowRequirements
                  criteria={criteria}
                  type={"ts"}
                  sysId={nctId}
                  city={city}
                  country={country}
                  state={state}
                  zipcode={zipcode}
                  queryCountry={queryCountry}
                  queryCondition={queryCondition}
                />
              ) : (
                <div>
                  <div className="row">
                    <div className="goal-sponsor col-sm-12 col-md-6 col-lg-6 ml-0 pl-0 text-left">
                      <h2>Gender</h2>
                      <p
                        className="mb-0"
                        style={{
                          color: "#006D9C",
                          "text-decoration": "none",
                        }}
                      >
                        {gender}
                      </p>
                    </div>
                    <div className="goal-sponsor col-sm-12 col-md-6 col-lg-6  ml-0 pl-0 text-left">
                      <h2>Study Type</h2>
                      <p
                        style={{
                          color: "#006D9C",
                          "text-decoration": "none",
                        }}
                      >
                        {studyType}
                      </p>
                    </div>
                  </div>
                  <div className="purpose-timeframe">
                    <h2 style={{ "font-weight": "bold" }} className="py-1">
                      Purpose
                    </h2>
                    <p className="py-1">{description}</p>
                  </div>
                  <div className="purpose-timeframe">
                    <h2 style={{ "font-weight": "bold" }} className="py-1">
                      Timeframe
                    </h2>
                    <p className="py-1">{description}</p>
                  </div>
                  {/* <div className="text-center">
                    <p>Need to implement google map with locations</p>
                  </div> */}
                </div>
              )}
            </div>
          </div>
          <div className="closestlocation h-75 col-sm-12 col-md-3 col-lg-3 p-4 mt-5 text-left">
            {trial && trial.sites && trial.sites.length === 0 ? (
              <h2>{"Location details not found"}</h2>
            ) : (
              <div>
                <h2>THE CLOSEST LOCATION</h2>
                {/* <h3>the api is not showing the site name</h3> */}
                <h2>
                  {trial.sites[0].country +
                    "," +
                    trial.sites[0].state +
                    "," +
                    trial.sites[0].city +
                    "," +
                    trial.sites[0].zipCode}
                </h2>
                <div className="text-center">
                  {disableEnrollButton ? (
                    <div
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#BDBDBD",
                        borderRadius: "3px",
                      }}
                    >
                      <button
                        style={{backgroundColor:'#BDBDBD',border:'none'}}
                        onClick={() => props.onEnrollForLocation()}
                        disabled={true}
                        className="enroll text-center btn btn-lg"
                      >
                        Sign Up
                      </button>
                    </div>
                  ) : (
                    <div
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#BC5405",
                        borderRadius: "3px",
                      }}
                    >
                      <button
                        onClick={() => props.onEnrollForLocation()}
                        className="enroll text-center btn btn-lg"
                      >
                        Sign Up
                      </button>
                    </div>
                  )}
                  {/* <p className="my-2">View all Locations</p> */}
                </div>
                {/* <div className="notification text-left">
                    <h2>GET NOTIFIED</h2>
                    <br></br>
                    <h2>
                      Sign up to receive email notification when clinical trials are
                      posted in the medical category of interest to you.{" "}
                    </h2>
                    <div style={{ border: "1px solid #E57D24", borderRadius: "3px" }}>
                      <a
                        href="#"
                        className="turn-on-notification d-flex align-items-center justify-content-center border rounded btn btn-lg"
                      >
                        TURN ON NOTIFICATION
                      </a>
                    </div>
                  </div> */}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <RecruitmentSteps step={"Review Trials"} />
      {isLoading ? <TrialDetails /> : <Loading />}
    </div>
  );
};
export default withRouter(ScopeView);
