import React from "react";
import "../../App.css";
import { RecruitmentSteps, ScreenFlow } from "../../components";

import {
  enroll_gender,
  enroll_age,
  enroll_rank,
  diabetesStudy,
} from "../../features/theme/icons";
import { environment } from "../../constants";

const NCT04628481 = (props) => {
  
  const flowarray = [
    { title: "Home", link: "/" },
    { title: "Find Trials", link: "/#find_trial" },
    {
      title: "Search Results",
      link: `/results?q=${"upcoming studies"}&in=${"UNITED%20STATES"}`,
    },
    {
      title: "Trial Details",
      link: "/upcoming-research",
    },
  ];

  const enrollLink = () => {
    window.location.href = `/enroll/${
      environment === "PROD" ? "STU01651" : "STU01651"
    }`;
  };
  const TrialDetails = () => {
    return (
      <div className="p-0">
        <div className="container d-flex align-items-center justify-content-start">
          <ScreenFlow flow={flowarray} />
        </div>
        {/* paid clinical trials */}
        <div
          style={{
            background:
              "linear-gradient(180deg, rgba(196, 196, 196, 0) 12.81%, rgba(118, 214, 238, 0.2) 73.23%)",
          }}
        >
          <div className="container">
            <div className="row p-0">
              <div className="col-lg-6 p-0">
                <img
                  className="img-responsive col-lg-11"
                  src={diabetesStudy}
                  alt="study_image"
                ></img>
              </div>
              <div className="upcoming_paid col-lg-5 p-0 my-auto">
                <div className="d-block align-items-center justify-content-center mx-auto text-center">
                  <h3 style={{textTransform:'capitalize'}} className="mx-auto text-center my-3">
                  Delaying the progression of type 1 diabetes (T1D) trial
                  </h3>
                  <p className="my-3">
                    Estimated Enrollment :{" "}
                    <span style={{ color: "#12BBE5" }}>327 participants</span>
                  </p>
                  <button
                    onClick={() => enrollLink()}
                    className="upcoming_signup btn btn-lg my-3"
                  >
                    SIGN UP FOR THE STUDY
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 p-0">
              <div className="upcoming_purpose my-3">
                <h2 className="my-1">Summary</h2>
                <p style={{fontWeight:'normal'}} className="mb-0">
                  {" "}
                  The objective of this clinical trial is to assess whether
                  ladarixin treatment is effective in preserving beta-cell
                  function and delaying the progression of type 1 diabetes (T1D)
                  in adolescent and adult patients. The safety of ladarixin in
                  the specific clinical setting will be also evaluated.
                </p>
              </div>
              <div className="upcoming_purpose my-3">
                <h2 className="my-1">Description</h2>
                <p style={{fontWeight:'normal'}} className="mb-0">
                  This is a phase 3, multicenter, double-blind,
                  placebo-controlled study. It has been designed to further
                  evaluate whether ladarixin is effective in preserving
                  beta-cell function and slowing-down the progression of T1D) in
                  patients with a more severe disease presentation. The study is
                  planned to be performed at about 40 study centers in EU, US
                  and in other countries, if appropriated. At each study center,
                  the Principal Investigator (PI) will be responsible for
                  ensuring that the investigation is conducted according to the
                  signed Investigator agreement, the protocol, GCP guidelines,
                  and local regulations. The study is planned to involve -327
                  patients with new-onset T1D, to include about 200 adolescents
                  (14-17 years). Patients will be randomly (2:1) assigned to
                  receive either ladarixin treatment (400 mg b.i.d. for 13
                  cycles of 14 days on/14 days off - treatment group) or matched
                  placebo (control group). The two groups will be balanced
                  within centers.
                </p>
              </div>
              <div className="upcoming_qualify mb-3">
                <h3 className="my-2">Who Qualifies?</h3>
                <div className="row d-flex align-items-start justify-content-center my-4 px-4">
                  <div className="text-center col-lg-4 col-sm-12 col-md-12 col-xs-12">
                    <img src={enroll_gender} alt="gender"></img>
                    <p className="mb-0 my-3">Gender: All</p>
                  </div>
                  <div className="text-center col-lg-4 col-sm-12 col-md-12 col-xs-12">
                    <img src={enroll_age} alt="age"></img>
                    <div className="my-3">
                      <span className="mb-0 mx-1">Max - 45 years</span>
                      <span className="mb-0 mx-1">Min - 14 years</span>
                    </div>
                  </div>
                  <div className="text-center col-lg-4 col-sm-12 col-md-12 col-xs-12">
                    <img src={enroll_rank} alt="rank"></img>
                    <p className="mb-0 my-3">Conditions: New-onset type1 Diabetes</p>
                  </div>
                </div>
              </div>
              <div className="upcoming_purpose">
                <h3 className="my-2 upcoming_criteria">Criteria</h3>
                <h2 className="my-3">Inclusion Criteria</h2>
                <ul>
                    <li>Male and female patients aged 14-45 years, inclusive</li>
                    <li>New-onset T1D (1st IMP dose within 100 days from 1st insulin administration)</li>
                    <li>Positive for at least one diabetes-related auto-antibody (anti-GAD; IAA, if obtained within 10 days of the onset of insulin therapy; IA-2 antibody; ZnT8)</li>
                    <li>Require, or has required at some time, insulin therapy through multiple daily injections (MDI) or Continuous Subcutaneous Insulin Infusion (CSII)</li>
                    <li>{`Fasting C peptide < 0.205nmol/L`}</li>
                    <li>{`Residual beta-cell function as per peak stimulated (MMTT) C-peptide level >0.2nmol/L; MMTT should not be performed within one week of resolution of a diabetic ketoacidosis event`}</li>
                </ul>
              </div>
              <div className="my-2 upcoming_purpose">
                <h2 className="my-3">Exclusion Criteria</h2>
                <ul>
                    <li>Any other chronic disease, including type 2 diabetes, apart from autoimmune hypothyroidism requiring thyroid hormone replacement only; patients with severe (myxedema) disease potentially requiring immunosuppressive therapy will be excluded</li>
                    <li>Moderate to severe renal impairment as per estimated Glomerular Filtration Rate (eGFR) 60 mL/min/1.73m2, as determined using Chronic Kidney Disease Epidemiology Collaboration (CKD-EPI) creatinine equation (see Appendix 14.4.3)</li>
                    <li>{`Hepatic dysfunction defined by increased ALT/AST > 3 x upper limit of normal (ULN) and increased total bilirubin > 3 mg/dL [>51.3 μmol/L]`}</li>
                    <li>{`Hypoalbuminemia defined as serum albumin < 3 g/dL`}</li>
                    <li>{`QTcF > 470 msec`}</li>
                    <li>Occurrence of an episode of ketoacidosis or hypoglycemic coma in the past 2 weeks</li>
                    <li>A history of significant cardiovascular disease/abnormality</li>
                    <li>Known hypersensitivity to non-steroidal anti-inflammatory drugs</li>
                </ul>
              </div>
              <div className="d-flex align-items-center justify-content-end my-3">
                  <button
                        onClick={() => enrollLink()}
                        className="upcoming_signup btn btn-lg"
                      >
                        SIGN UP FOR THE STUDY
                  </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="mt-5 pt-5">
      <RecruitmentSteps step={"Review Trials"} />
      <TrialDetails />
    </div>
  );
};
export default NCT04628481;
