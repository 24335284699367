import React from "react";
import Helmet from "react-helmet";

const ChatScript = () => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>Clinical Trials Search</title>
      <script
        src="https://assets.flex.twilio.com/releases/flex-webchat-ui/2.9.1/twilio-flex-webchat.min.js"
        integrity="sha512-yBmOHVWuWT6HOjfgPYkFe70bboby/BTj9TGHXTlEatWnYkW5fFezXqW9ZgNtuRUqHWrzNXVsqu6cKm3Y04kHMA=="
        crossorigin
      ></script>
    </Helmet>
  );
};
export default ChatScript;
