import React, { useState, useEffect } from "react";
import "../../App.css";
import {
  greenDot,
  step3,
  step4,
  step5,
  arrowRight,
  Iphone,
  logo,
  PlayStore,
  AppStore,
  QR,
  shareMedIcon,
} from "../theme/icons";
import { getPhase, getTitle, getConditions } from "../../helpers";
import { RecruitmentSteps, PopularSearch } from "../../components";
import { log } from "../../helpers/helper";
import { useParams } from "react-router-dom";

const backToSearch = () => {
  window.location.href = "/";
};

const DeleteProspect = (props) => {
  const { hideSteps, studyId } = props;
  const clearCriteria = localStorage.removeItem("criteria");
  const clearCurrentPage = localStorage.removeItem("currentPage");
  //const { pt } = useParams();
  //log(`${clearCriteria},${clearCurrentPage},'clear'`);


  const [ptNumber, setPtNumber] = useState("");
  const [lastname, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [showNext, setShowNext] = useState(false)

  const handleChange = (e) =>{
    if(e.target.name === "ptNumber"){
        setPtNumber(e.target.value)
    }else if(e.target.name === "lastname"){
        setLastName(e.target.value)
    }else if(e.target.name === "dob"){
        setDob(e.target.value)
    }
  }

//   useEffect(() => {
//     window.scrollTo(0, 0);
//     //submitPatient()
//   }, []);

const onDelete = () =>{
    let checkEmptyValues = [
        ptNumber, lastname, dob
      ];
      let check = checkEmptyValues.filter(
        (item) => item === "" || null || undefined
      );
      if (check && check.length > 0) {
        //log(`${check}, "check"`);
        alert("please enter all required values");
      } else {
        setShowNext(true)
      }
}

  return (
    <div className="container my-5 py-5">
      {/* <ChatScript /> */}
      {hideSteps === true ? null : <RecruitmentSteps step={"Enroll"} />}
      <div className="d-flex align-items-center justify-content-center">
        {showNext === true ? 
        <div className="col-lg-6 px-0 mx-0 my-5">
            <div style={{border : '1px solid dodgerblue', borderRadius:15, height:120}} className="d-flex align-items-center justify-content-center">
                <h6>Your account has been successfully deleted</h6>
            </div>
        </div>
        :
        <div className="col-lg-6 px-0 mx-0">
            <h5 className="ml-3">Please enter your details below to verify the account</h5>
            <div className="col-lg-12 my-4">
                <h6>Patient ID<span style={{color:'red'}}>*</span></h6>
                <input className="p-2 col-12 form-control" style={{height:40}} type="text" name="ptNumber" value={ptNumber} onChange={handleChange} placeholder="Enter Patient ID" />
            </div>
            <div className="col-lg-12 my-4">
                <h6>Last Name<span style={{color:'red'}}>*</span></h6>
                <input className="p-2 col-12 form-control" style={{height:40}} type="text" name="lastname" value={lastname} onChange={handleChange} placeholder="Enter Last Name" />
            </div>
            <div className="col-lg-12 my-4">
                <h6>Date Of Birth<span style={{color:'red'}}>*</span></h6>
                <input className="p-2 col-12 form-control" style={{height:40}} type="date" name="dob" value={dob} onChange={handleChange} placeholder="Enter Date of Birth" />
            </div>
            <button
                onClick={() => onDelete()}
                style={{ backgroundColor: "#BC5405" }}
                className="next btn btn-danger btn-md my-3 ml-3"
                >
                Submit
            </button>
        </div>}
      </div>
    </div>
  );
};
export default DeleteProspect;
