import React from "react";
import { withRouter } from "react-router-dom";
import ChatScript from "../features/enroll/chat";
import { chevronRightDark } from "../features/theme/icons";
import {
  fetchHealthSystems,
  fetchRecords,
  createFile,
} from "../helpers/pthelper";
import HealthSystemModal from "./HealthSystemModal";
import Loading from "./Loading";
import { log } from "../helpers/helper";


class EMR extends React.Component {
  constructor(props) {
    super();
    this.state = {
      prospect: props.match.params.prospect,
      healthSystems: "",
      isLoading: "",
      initiateProcess: true,
      showSystem: false,
      recordsShared: false,
      token: "",
      gender: "",
      dob: "",
      records: "",
      city: props.match.params.city,
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  openSystem = (url) => {
    this.setState({ showSystem: true });
  };

  closeSystem = () => {
    this.setState({ showSystem: false });
  };

  getHealthSystems = async () => {
    this.setState({ isLoading: false, initiateProcess: false });
    window.localStorage.setItem("user", this.state.prospect);
    window.localStorage.setItem("city", this.state.city);
    try {
      let healthSystems = await fetchHealthSystems(
        this.state.prospect,
        this.state.city
      );
      log(healthSystems, "got health systems");
      this.setState({ healthSystems: healthSystems, isLoading: true });
    } catch (errSystems) {
      log(errSystems, "err with health systems");
      this.setState({ healthSystems: [] }, () => {
        this.setState({ isLoading: true });
      });
    }
  };

  openPopup = (url) => {
    let popup = window.open(
      url,
      "googleChrome",
      "left=100,top=100,width=900,height=420"
    );
    popup.onload = () => {
      setTimeout(() => {
        alert(popup.window.location.href);
      }, 1500);
    };
  };

  componentDidMount() {
    let user = window.localStorage.getItem("user");
    let terms = this.props.location && this.props.location.search;
    let dob = terms.slice(5, 15);
    let gender = terms.split("&gender=") && terms.split("&gender=")[1];
    log(terms, gender, dob, "terms");
    this.setState({ dob: dob, gender: gender }, () => {
      window.localStorage.setItem("dob", this.state.dob);
      window.localStorage.setItem("gender", this.state.gender);
    });
    if ( user !== null) {
      this.setState({ isLoading: false });
      fetchRecords(user)
        .then((records) => {
          log(records, "records");
          this.setState({ records: records }, () => {
            this.setState({ isLoading: true });
          });
        })
        .catch((errRecords) => {
          log(errRecords, "err with records");
          this.setState({ records: [] }, () => {
            this.setState({ isLoading: true });
          });
        });
    } else {
      log(user, " no user found");
      this.getHealthSystems();
    }
  }

  shareRecords = async () => {
    this.setState({ isLoading: false });
    let nctid = window.localStorage.getItem("nctId");
    try {
      let result = await createFile(
        this.state.prospect,
        this.state.records,
        this.state.dob,
        this.state.gender,
        nctid
      );
      log(result, "result");
      if (result.error === false) {
        this.setState({
          recordsShared: true,
          isLoading: true,
          healthSystems: "",
        });
        window.localStorage.removeItem("user");
        window.localStorage.removeItem("city");
        window.localStorage.removeItem("dob");
        window.localStorage.removeItem("gender");
        window.localStorage.removeItem("nctId");
        window.localStorage.removeItem("chat_status");
        window.localStorage.removeItem("prospectID")
      } else {
        window.localStorage.removeItem("user");
        window.localStorage.removeItem("city");
        window.localStorage.removeItem("dob");
        window.localStorage.removeItem("gender");
        window.localStorage.removeItem("nctId");
        window.localStorage.removeItem("chat_status");
        window.localStorage.removeItem("prospectID")
      }
    } catch (err) {
      log(err, " err with file");
      this.setState({ recordsShared: false, isLoading: true });
      alert("Error while sharing records");
      window.localStorage.removeItem("user");
      window.localStorage.removeItem("city");
      window.localStorage.removeItem("dob");
      window.localStorage.removeItem("gender");
      window.localStorage.removeItem("nctId");
      window.localStorage.removeItem("chat_status");
      window.localStorage.removeItem("prospectID")
    }
  };

  render() {
    let { city, isLoading, healthSystems, recordsShared, records } = this.state;
    return (
      <div className="my-5 py-5 container px-2">
        <div className="d-flex align-items-center justify-content-between my-2">
          <h4 className="mb-0">Electronic Medical Records</h4>
          {/* <div className="col-lg-6 d-flex align-items-center justify-content-center">
                <div className="d-flex align-items-center justify-content-center">
                    <div style={{ borderRadius : 5 }} className="border p-2 mr-2 active_step">
                        <h6>Register</h6>
                    </div>
                    <img src={chevronRightDark} alt="right icon"></img>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                    <div style={{ borderRadius : 5 }} className = {`border p-2 mr-2 ${records && records.length > 0 ? "active_step" : ""}`}>
                        <h6>Health Systems</h6>
                    </div>
                    <img src={chevronRightDark} alt="right icon"></img>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                    <div style={{ borderRadius : 5 }} className={`border p-2 mr-2 ${records && records.length > 0 ? "active_step" : ""}`}>
                        <h6>Share Records</h6>
                    </div>
                    <img src={chevronRightDark} alt="right icon"></img>
                </div>
                <div style={{ borderRadius : 5 }} className={`border p-2 mr-2 ${recordsShared === true ? "active_step" : ""}`}>
                    <h6>Complete</h6>
                </div>
            </div> */}
          <div className="col-lg-8 d-flex align-items-center justify-content-start px-0">
            <div className="">
              <div
                style={{
                  height: 50,
                  width: 50,
                  borderRadius: 25,
                  backgroundColor: "#f1f3f4",
                }}
                className="mr-3 d-flex align-items-center justify-content-center"
              >
                <h6>01</h6>
              </div>
              <p style={{ fontWeight: "bold" }} className="mb-0">
                Register
              </p>
            </div>
            <div
              style={{
                height: "5px",
                width: "154px",
                backgroundColor: "#f1f3f4",
                position: "relative",
                top: "-11px",
                right: "19px",
                bottom: "0px",
              }}
            ></div>
            <div className="">
              <div
                style={{
                  height: 50,
                  width: 50,
                  borderRadius: 25,
                  backgroundColor: "#f1f3f4",
                  position: "relative",
                  right: "22px",
                }}
                className="mr-3 d-flex align-items-center justify-content-center"
              >
                <h6>02</h6>
              </div>
              <p
                style={{
                  fontWeight: "bold",
                  position: "relative",
                  right: "20px",
                }}
                className="mb-0"
              >
                Systems
              </p>
            </div>
            <div
              style={{
                height: "5px",
                width: "154px",
                backgroundColor: "#f1f3f4",
                position: "relative",
                top: "-11px",
                right: "42px",
                bottom: "0px",
              }}
            ></div>
            <div className="">
              <div
                style={{
                  height: 50,
                  width: 50,
                  borderRadius: 25,
                  backgroundColor: "#f1f3f4",
                  position: "relative",
                  right: "44px",
                }}
                className="mr-3 d-flex align-items-center justify-content-center"
              >
                <h6>03</h6>
              </div>
              <p
                style={{
                  fontWeight: "bold",
                  position: "relative",
                  right: "40px",
                }}
                className="mb-0"
              >
                Share EMR
              </p>
            </div>
            <div
              style={{
                height: "5px",
                width: "154px",
                backgroundColor: "#f1f3f4",
                position: "relative",
                top: "-11px",
                right: "63px",
                bottom: "0px",
              }}
            ></div>
            <div className="">
              <div
                style={{
                  height: 50,
                  width: 50,
                  borderRadius: 25,
                  backgroundColor: "#f1f3f4",
                  position: "relative",
                  right: "66px",
                }}
                className="mr-3 d-flex align-items-center justify-content-center"
              >
                <h6>04</h6>
              </div>
              <p
                style={{
                  fontWeight: "bold",
                  position: "relative",
                  right: "70px",
                }}
                className="mb-0"
              >
                Complete
              </p>
            </div>
          </div>
        </div>
        {/* <p className="mb-0">Please enter your city below</p> */}
        {/* <div className="d-flex align-items-center justify-content-start">
          <input
            placeholder="City or State name"
            className="form-control col-lg-3"
            type="text"
            name="city"
            value={city}
            onChange={this.handleChange}
          ></input>
          <button
            onClick={() => this.getHealthSystems()}
            className="btn-small btn btn-primary mx-5"
          >
            submit
          </button>
        </div> */}
        {records === "" ? (
          <div className="my-3">
            {isLoading === false ? (
              <Loading />
            ) : (
              <div style={{ height: 450, overflowY: "scroll" }} className="">
                {healthSystems && healthSystems.length > 0
                  ? healthSystems.map((system) => (
                      <div
                        style={{
                          borderColor: "1px solid lightgrey",
                          backgroundColor: "#E5E5E5",
                        }}
                        key={system.id}
                        className="col-lg-8 row border mr-3 my-2"
                      >
                        <div className="col-lg-2 px-0">
                          {system && system.image_url === null ? (
                            <div
                              style={{ backgroundColor: "white" }}
                              className="d-flex align-items-center justify-content-start p-5"
                            >
                              <h5>{system.subtitle.charAt("0")}</h5>
                            </div>
                          ) : (
                            <img
                              style={{ height: 150, width: 150 }}
                              src={system.image_url}
                              alt="hs icon"
                            ></img>
                          )}
                        </div>
                        <div className="col-lg-8 px-0 mx-5">
                          <h6>{system.subtitle}</h6>
                          <button
                            className="btn btn-small btn-primary"
                            onClick={() => (window.location.href = system.url)} //this.openPopup(system.url)}
                          >
                            {system.title}
                          </button>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            )}
          </div>
        ) : records && records.length > 0 ? (
          recordsShared === true ? (
            <div className="py-5 my-5">
              <h6>
                Thank you for sharing your records. You will receive further
                instructions to your email.
              </h6>
              <button
                onClick={() => {
                  window.localStorage.clear()
        window.localStorage.removeItem('twilio-flex-cf')
        window.localStorage.removeItem('loglevel:twilio-sync')
        window.localStorage.removeItem('loglevel:twilsock')
        window.localStorage.removeItem('loglevel:twilio-flex-webchat-ui')
        window.localStorage.removeItem('loglevel')
        window.localStorage.removeItem('loglevel:twilio-chat')
        window.localStorage.setItem("chat_status" , "completed");
        window.localStorage.removeItem("prospectID" , this.state.patient_id );
        window.localStorage.removeItem("f_name" , this.state.firstname );
        window.localStorage.removeItem("city" , this.state.city);
        window.localStorage.removeItem("dob" , this.state.dob );
        window.localStorage.removeItem("gender" , this.state.gender );
        window.sessionStorage.clear()
                  window.location.href = "/"
                }
                  }
                className="btn btn-lg btn-primary my-4"
              >
                Back to Search
              </button>
            </div>
          ) : (
            <div>
              <div className="row my-3">
                <div className="col-lg-6 px-0">
                  <h6>
                    Immunizations :{" "}
                    {records[0]["immunizations"] &&
                      records[0]["immunizations"].length}
                  </h6>
                  <div
                    style={{ height: 350, overflowY: "scroll" }}
                    className="my-2"
                  >
                    {records[0]["immunizations"] &&
                    records[0]["immunizations"].length > 0
                      ? records[0]["immunizations"].map((immunization) => (
                          <div
                            style={{ backgroundColor: "#fbf8f3" }}
                            key={immunization.id}
                            className="border p-2 mr-3 my-1"
                          >
                            <h6>{immunization.name || "None"}</h6>
                            <p className="mb-0">
                              {immunization.date || "None"}
                            </p>
                            <p className="mb-0">
                              {immunization.manufacturer || "None"}
                            </p>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
                <div className="col-lg-6 px-0">
                  <h6>
                    Allergies :{" "}
                    {records[2]["allergies"] && records[2]["allergies"].length}
                  </h6>
                  <div
                    style={{ height: 350, overflowY: "scroll" }}
                    className="my-2"
                  >
                    {records[2]["allergies"] &&
                    records[2]["allergies"].length > 0
                      ? records[2]["allergies"].map((allergy) => (
                          <div
                            style={{ backgroundColor: "#ebf9fc" }}
                            key={allergy.id}
                            className="border p-2 mr-3 my-1"
                          >
                            <h6>{allergy.name || "None"}</h6>
                            <p className="mb-0">{allergy.date || "None"}</p>
                            <p className="mb-0">{allergy.category || "None"}</p>
                            <p className="mb-0">{allergy.severity || "None"}</p>
                            <p className="mb-0">{allergy.status || "None"}</p>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </div>
              <div className="row my-3">
                <div className="col-lg-6 px-0">
                  <h6>
                    Medications :{" "}
                    {records[1]["medications"] &&
                      records[1]["medications"].length}
                  </h6>
                  <div
                    style={{ height: 350, overflowY: "scroll" }}
                    className="my-2"
                  >
                    {records[1]["medications"] &&
                    records[1]["medications"].length > 0
                      ? records[1]["medications"].map((medication) => (
                          <div
                            style={{ backgroundColor: "#fbf5f6" }}
                            key={medication.id}
                            className="border p-2 mr-3 my-1"
                          >
                            <h6>{medication.name || "None"}</h6>
                            <p className="mb-0">{medication.date || "None"}</p>
                            <p className="mb-0">
                              {medication.dosage || "None"}
                            </p>
                            <p className="mb-0">{medication.taken || "None"}</p>
                            <p className="mb-0">
                              {medication.status || "None"}
                            </p>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
                <div className="col-lg-6 px-0">
                  <h6>
                    Conditions :{" "}
                    {records[3]["conditions"] &&
                      records[3]["conditions"].length}
                  </h6>
                  <div
                    style={{ height: 350, overflowY: "scroll" }}
                    className="my-2"
                  >
                    {records[3]["conditions"] &&
                    records[3]["conditions"].length > 0
                      ? records[3]["conditions"].map((condition) => (
                          <div
                            style={{ backgroundColor: "#f3f8f5" }}
                            key={condition.id}
                            className="border p-2 my-1 mr-3"
                          >
                            <h6>{condition.name || "None"}</h6>
                            <p className="mb-0">{condition.date || "None"}</p>
                            <p className="mb-0">
                              {condition.verificationstatus || "None"}
                            </p>
                            <p className="mb-0">
                              {condition.clinicalstatus || "None"}
                            </p>
                            <p className="mb-0">
                              {condition.onsetdatetime || "None"}
                            </p>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end">
                <button
                  style={{ color: "white", backgroundColor: "#BC5405" }}
                  onClick={() => this.shareRecords()}
                  className="btn btn-sm"
                >
                  Share Medical Records
                </button>
              </div>
            </div>
          )
        ) : (
          <p className="mb-0">No medical records found</p>
        )}
      </div>
    );
  }
}

export default withRouter(EMR);
