import React from "react";
import { ScreenFlow, RecruitmentSteps } from "../../components";

const flowarray = [
  { title: "Home", link: "/" },
  { title: "Find Trials", link: "/#find_trial" },
  {
    title: "Search Results",
    link: `/results?q=NCT04133909&in=UNITED%20STATES`,
  },
  {
    title: "Trial Details",
    link: `/study/NCT04133909`,
  },
];

class NCT04133909 extends React.Component {
  render() {
    return (
      <div>
        {this.props.location &&
        this.props.location.search &&
        this.props.location.search.indexOf("embed=true") !== -1 ? null : (
          <>
            <RecruitmentSteps step={"Review Trials"} />
            <div className="container d-flex align-items-center justify-content-start">
              <ScreenFlow flow={flowarray} />
            </div>
          </>
        )}
        <div className="container">
          <iframe
            style={{ height: 2000, width: "100%", border: "none" }}
            title="NCT04133909"
            src="https://antidote.me/prescreener/s/en-US/chronic_obstructive_pulmonary_disease-134?utm_source=patientrials&utm_medium=referral&utm_campaign=E134"
          ></iframe>
        </div>
      </div>
    );
  }
}

export default NCT04133909;
