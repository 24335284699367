import React from "react";
import { BlogHeader } from ".";
import { log } from "../../helpers/helper";
import {
  blog_calendar,
  blog_human,
} from "../theme/icons";
import { blogArticles } from "./articles";
import BlogDetail from "./blogdetail";
import moment from "moment";

const Blog = (blog) => {
  let {
    id,
    title,
    author,
    description,
    date,
    category,
    image,
    onBlog,
    promoted_title,
  } = blog;
  return (
    <div style={{ cursor: "pointer" }} onClick={() => onBlog(promoted_title.replace('?','').trim().split(' ').join('-'))}>
      <img
        className="blog_main_img img-responsive col-12 p-0"
        alt="blog_img"
        src={image}
      ></img>
      {/* <div className="d-flex align-items-center justify-content-end">
        <div className="mx-2 blog_details">
          <img
            style={{ height: 15 }}
            className="mx-1"
            src={blog_chat}
            alt="comments_icon"
          ></img>
          <span>{comments && comments.length}</span>
        </div>
        <div className="mx-2 blog_details">
          <img
            style={{ height: 15 }}
            className="mx-1"
            src={blog_like}
            alt="comments_icon"
          ></img>
          <span>{likes && likes.length}</span>
        </div>
      </div> */}
      <h2 className="my-2 blog_title">
        {title && title.length > 38 ? title.slice(0, 38) + "..." : title}
      </h2>
      <div className="blog_details my-1">
        <span className="mx-1 col-lg-2 col-md-12 col-sm-12 col-xs-12 p-0">
          {moment(date).format('MMMM Do YYYY')}
        </span>
        <span
          className="blog_author mx-1 col-lg-2 col-md-12 col-sm-12 col-xs-12"
        >
          By {author}
        </span>
        <span
          style={{ color: "#BC5405"}}
          className="mx-1 col-lg-2 col-md-12 col-sm-12 col-xs-12"
        >
          Category, {category}
        </span>
      </div>
      <p className="my-1 mb-0 blog_description">
        {description && description.length > 150
          ? description.slice(0, 150) + "..."
          : description}
      </p>
      <div className="my-4 d-flex align-items-center justify-content-start">
        <button
          style={{ color: "black", border: "none" }}
          className="blog_rmore btn btn-primary"
          onClick={() => onBlog(id)}
        >
          READ MORE
        </button>
      </div>
    </div>
  );
};

const RecentPosts = (post) => {
  let {
    id,
    title,
    author,
    description,
    date,
    category,
    image,
    likes,
    comments,
    onBlog,
  } = post;
  log(`${typeof description}, "des"`);
  //let description_content = description && description.length > 150 ? description.slice(0,150)+'...' : description
  return (
    <div style={{ cursor: "pointer" }} onClick={() => onBlog(id)}>
      <img
        className="blog_recent_img img-responsive col-12 p-0"
        src={image}
        alt="blog_main_img"
      ></img>
      <div className="d-lg-flex d-md-block d-sm-block d-xs-block align-items-center justify-content-start">
        <div
          // style={{ borderRight: "2px solid black" }}
          className="my-2 blog_recent_details col-lg-5 col-md-12 col-sm-12 col-xs-12 px-0"
        >
          <img
            style={{ height: 15 }}
            className="mx-2"
            src={blog_calendar}
            alt="calendar_icon"
          ></img>
          <span>{date}</span>
        </div>
        <div style={{border:'none'}} className="blog_recent_details col-lg-7 col-md-12 col-sm-12 col-xs-12 px-3">
          <img
            style={{ height: 15 }}
            className="mx-1"
            src={blog_human}
            alt="author_icon"
          ></img>
          <span>{author}</span>
        </div>
      </div>
      <h2 className="my-2 recent_post_title">{title}</h2>
      <p className="my-1 mb-0 blog_description">
        {description && description.length > 100
          ? description.slice(0, 100) + "..."
          : description}
      </p>
    </div>
  );
};

class Blogs extends React.Component {
  constructor(props) {
    super();
    this.state = {
      blogid: "",
      blogs: this.filterRecentBlogsByDate(blogArticles),
      blog: [],
      keywords: "",
      showBlog: false,
      showAll: false,
    };
  }
  componentDidMount() {
    log(`${this.props.match.params.blogid}, "props"`);
    let blog_id = this.props.match.params.blogid;
    if (blog_id === " " || blog_id === null || blog_id === undefined) {
      this.setState({ showBlog: false });
    } else if (blogArticles && blogArticles.length > 0) {
      let blog = blogArticles.filter((article) => article.promoted_title.replace('?','').trim().split(' ').join('-') == blog_id);
      log(`${blog}, "blog"`);
      if (blog && blog.length > 0) {
        this.setState({ blog: blog }, () => {
          this.setState({ showBlog: true });
        });
      } else {
        this.setState({ blog: [], showBlog: true });
      }
    }
  }

  goToStudy = (link) => {
    window.location.href = link;
  };

  share = (media, title) => {
    if (media && media.length > 0) {
      if (media === "fb") {
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,
          "_blank"
        );
      } else if (media === "twitter") {
        window.open(
          `https://twitter.com/intent/tweet?url=${window.location.href}&text=${title}`,
          "_blank"
        );
      } else if (media === "linkedin") {
        window.open(
          `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&title=${title}`,
          "_blank"
        );
      }
    } else {
      log("no media received");
    }
  };

  list = (list) => {
    let [first, ...remainingList] = list;
    return (
      <div className="blog_faq">
        <h3 style={{ fontFamily: "LatoRegular" }} className="my-2">
          {first.split("--br--") && first.split("--br--").length > 0
            ? this.insertBreak(first.split("--br--"))
            : first}
        </h3>
        {remainingList.map((listitem) => (
          <ul className="pl-4">
            <li style={{ fontFamily: "LatoRegular" }}>
              {listitem.split("--br--") && listitem.split("--br--").length > 0
                ? this.insertBreak(listitem.split("--br--"))
                : listitem}
            </li>
          </ul>
        ))}
      </div>
    );
  };

  showAllBlogs = () => {
    this.setState({ blogs: blogArticles, showAll: true });
  };

  insertBreak = (lines) => {
    let [first, ...remainingList] = lines;
    return (
      <div className="blog_faq">
        <h3 style={{ fontFamily: "LatoRegular" }} className="mb-0">
          {first}
        </h3>
        {remainingList.map((listitem) => (
          <div>
            <br />
            <h3 className="mb-0">{listitem}</h3>
          </div>
        ))}
      </div>
    );
  };

  filterRecentBlogsByDate=(blogs)=>{
    return blogs.filter(b=>b.date).sort((a,b)=> new Date(b.date) - new Date(a.date))
  }

  filterByTag = (tag) => {
    let filteredBlogs = blogArticles.filter(
      (blog) =>
        blog.category.includes(tag.toLowerCase()) ||
        blog.category.includes(tag.toUpperCase()) ||
        blog.category.includes(tag) ||
        blog.title.includes(tag.toLowerCase()) ||
        blog.title.includes(tag.toUpperCase()) ||
        blog.title.includes(tag) ||
        blog.description.includes(tag.toLowerCase()) ||
        blog.description.includes(tag.toUpperCase()) ||
        blog.description.includes(tag)
    );
    log(`${filteredBlogs}, ${tag}, "fblogs"`);
    this.setState({ blogs: this.filterRecentBlogsByDate(filteredBlogs), showBlog: false });
  };

  handleInputChange = (e) => {
    log(`${e.target.value}, "keywords input"`);
    this.setState({ keywords: e.target.value });
  };

  onSearch = () => {
    this.filterByTag(this.state.keywords);
    this.setState({ showBlog: false });
  };

  onEnter = (e) => {
    if (e.key === "Enter") {
      this.filterByTag(this.state.keywords);
      this.setState({ showBlog: false });
    }
  };

  filterByDate = (date) => {
    let filteredBlogs = blogArticles.filter((blog) => blog.date === date);
    log(`${filteredBlogs}, ${date}, "date fblogs"`);
    this.setState({ blogs: filteredBlogs, showBlog: false });
  };

  onDateChange = (e) => {
    log(`${e.target.value}, "input fdate"`);
    this.filterByDate(e.target.value);
  };

  onInputChange = (e) => {
    log(`${e.target.value}, "input keywords"`);
    this.setState({ keywords: e.target.value });
  };

  onBlogClick(id) {
    log(`${id}, "bid"`);
    window.location.href = "/blog/" + id;
  }
  render() {
    const { blog,blogs, keywords, showBlog } = this.state;
    return (
      <div className="container-fluid pt-5 mt-5 m-0">
        {/* popular tags */}
        <BlogHeader
          filterByTag={this.filterByTag}
          onDateChange={this.onDateChange}
          filterRepeatedDates={this.filterRepeatedDates}
          onInputChange={this.onInputChange}
          onEnter={this.onEnter}
          keywords={keywords}
          onSearch={this.onSearch}
        />
        {showBlog === false ? (
          <div>
            <div className="container p-0">
              <div className="row">
                {blogs && blogs.length > 0 ? (
                  blogs.map((post,i) => (
                    <div key={i} className="col-lg-6 col-md-12 col-xs-12 col-sm-12 my-2">
                      <Blog {...post} onBlog={this.onBlogClick} />
                    </div>
                  ))
                ) : (
                  <h4 className="mx-auto text-center">{"No blogs found"}</h4>
                )}
              </div>
            </div>
            {/* recent posts */}
            {/* {showAll === false ? (
              <div className="container p-0 my-3">
                <div className="d-flex align-items-center justify-content-between px-3">
                  <h2 className="recent_posts_heading">Recent Posts</h2>
                  <p
                    style={{ cursor: "pointer" }}
                    onClick={() => this.showAllBlogs()}
                    className="recent_view_all mb-0"
                  >
                    view all
                  </p>
                </div>
                <div className="row">
                  {blogArticles && blogArticles.length > 0 ? (
                    blogArticles.map((post) => (
                      <div className="col-lg-4 col-md-12 col-xs-12 col-sm-12 my-3">
                        <RecentPosts {...post} onBlog={this.onBlogClick} />
                      </div>
                    ))
                  ) : (
                    <h4 className="mx-auto text-center">{"No blogs found"}</h4>
                  )}
                </div>
              </div>
            ) : null} */}
          </div>
        ) : (
          <div>
            <BlogDetail
              blog={blog}
              list={this.list}
              share={this.share}
              goToStudy={this.goToStudy}
            />
          </div>
        )}
      </div>
    );
  }
}

export default Blogs;
