import React from "react";
import {
  ScreenFlow,
  RecruitmentSteps,
} from "../../components";

const flowarray = [
  { title: "Home", link: "/" },
  { title: "Find Trials", link: "/#find_trial" },
  {
    title: "Search Results",
    link: `/results?q=NCT03095066&in=UNITED%20STATES`,
  },
  {
    title: "Trial Details",
    link: `/study/NCT03095066`,
  },
];

class NCT03095066 extends React.Component {
  
  render() {
    return (
      <div>
        {this.props.location &&
        this.props.location.search &&
        this.props.location.search.indexOf("embed=true") !== -1 ? null : (
          <>
            <RecruitmentSteps step={"Review Trials"} />
            <div className="container d-flex align-items-center justify-content-start">
              <ScreenFlow flow={flowarray} />
            </div>
          </>
        )}
        <div className="container">
          <iframe style={{height:3000,width:'100%',border:'none'}} title="NCT03095066" src="https://connect.trialscope.com/studies/890f1bac-9f32-4b28-9565-df2c208ad30f?embed=1"></iframe>
        </div>
      </div>
    );
  }
}

export default NCT03095066;
