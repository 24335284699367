import React, { useEffect } from 'react' ; 
import { withRouter } from 'react-router-dom';
import ChatScript from '../features/enroll/chat';
import { fetchRecords } from '../helpers/pthelper';
import { log } from '../helpers/helper';

const EHR = () =>{

    useEffect(()=>{
        let user = window.localStorage.getItem('user');
        let city = window.localStorage.getItem('city');
        let dob = window.localStorage.getItem('dob');
        let gender = window.localStorage.getItem('gender');
        log(user, ' got user');
        if(user !== null){
            window.location.href = `/medical-records/${user}/${city}?dob=${dob}&gender=${gender}`
        } else{
            log(user, 'no user found')
        }
    },[])

return (
    <div className='my-5 py-5 container'>
        <ChatScript />
        <div className='d-flex align-items-center justify-content-center mt-5 pt-5'>
        <h6>Thank you. Please close this window.</h6>
        </div>
    </div>
)
}

export default withRouter(EHR)