import React from "react";
import {
  ScreenFlow,
  RecruitmentSteps,
} from "../../components";

const flowarray = [
  { title: "Home", link: "/" },
  { title: "Find Trials", link: "/#find_trial" },
  {
    title: "Search Results",
    link: `/results?q=NCT04813796&in=UNITED%20STATES`,
  },
  {
    title: "Trial Details",
    link: `/study/NCT04813796`,
  },
];

class NCT04813796 extends React.Component {
  
  render() {
    return (
      <div>
        {this.props.location &&
        this.props.location.search &&
        this.props.location.search.indexOf("embed=true") !== -1 ? null : (
          <>
            <RecruitmentSteps step={"Review Trials"} />
            <div className="container d-flex align-items-center justify-content-start">
              <ScreenFlow flow={flowarray} />
            </div>
          </>
        )}
        <div className="container">
          <iframe style={{height:2500,width:'100%',border:'none'}} title="NCT04813796" src="https://connect.trialscope.com/studies/7edbec5e-aba4-4eff-852c-7773935eedf1?embed=1"></iframe>
        </div>
      </div>
    );
  }
}

export default NCT04813796;
