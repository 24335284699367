import React, { useState } from "react";
import {
  cmvCloseIcon,
  IconWarning,
} from "../features/theme/icons";
const InfoWarning = ({ subtitle }) => {
  const [show, hide] = useState(true);
  const onHide = () => {
    hide(false);
  };

  const Preview = () => {
    return (
      <div className="info_preview_mode border my-3 py-3 align-items-center justify-content-center row">
        <img
          className="warning_icon mx-3 img-fluid"
          src={IconWarning}
          alt="warning"
        ></img>
        <div className="col-lg-10 preview_content mx-3 text-center">
          <div className="d-flex align-items-center justify-content-between">
            <p
              style={{ color:'black' }}
              className="mb-0 text-left mx-3"
            >
              {subtitle}
            </p>
            <img
              style={{cursor:'pointer'}}
              onClick={() => onHide()}
              className="warning_close_icon"
              src={cmvCloseIcon}
              alt="close"
            ></img>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {show ? (
        <Preview />
      ) : // <div className="warning d-flex align-items-center justify-content-between px-5">
      //   <div className="row ml-2 mt-2 d-flex align-items-center">
      //     <h2 className="col-sm-3 col-xs-6 col-md-3 col-lg-3">Beta Release!</h2>
      //     <h3 className="col-sm-8 col-xs-8 col-md-8 col-lg-8">
      //       "Please DO NOT submit your personal information. Please send feedback to hello@patientrials.com. Thank you!"
      //     </h3>
      //   </div>
      //   <img
      //     onClick={() => onHide()}
      //     src={close_icon}
      //     alt="warning_close_icon"
      //   ></img>
      // </div>
      null}
    </div>
  );
};

export default InfoWarning;
