import {withRouter} from 'react-router-dom';
import React from "react";
import "../App.css";
import "../bootstrap.css";
import {
  Cancer,
  Cardiology,
  ChronicCondition,
  Therapeutic,
  arrowRight,
  Covid,
  Lymphoma,
  Dipression,
} from "../features/theme/icons";
const options = [
  { color: "#F6BFBF", title: "Mycobacterium avium complex (MAC)", icon: Dipression,link:"/study/NCT04677569 "},
  // { color: "#8CD2A9", title: "Traumatic Brain Injury", icon: Cardiology ,link:"/study/NCT03992404"},
  { color: "#C9AFD2", title: "Cytomegalovirus (CMV)", icon: ChronicCondition ,link:"/study/NCT05085366"},
  // { color: "#8CD2A9", title: "Cytomegalovirus", icon: Cardiology,link:"/study/NCT04232280" },
  { color: "#76D6EE", title: "Lung Cancer", icon: Cancer ,link:"/study/NCT03974022"},
  { color: "#F2D888", title: "Liver Cirrhosis", icon: Lymphoma,link:"/study/NCT05071716" },
];

const Option = ({ color, title, icon , link , next}) => {
  return (
    // <div onClick={()=>next(link)} className="options">
    //   <div className="option d-flex">
    //     <div style={{ backgroundColor: color }} className="icon_bg">
    //       <img src={icon} alt={"condition"}></img>
    //     </div>
    //     <p>{title}</p>
    //   </div>
    //   <span>
    //     <img src={arrowRight} alt="arrow right"></img>
    //   </span>
    // </div>
    <div onClick={()=>next(link)} className="d-flex align-items-center justify-content-between options">
        <div className="option d-flex align-items-center justify-content-center">
           <div style={{ backgroundColor: color }} className="icon_bg">
             <img src={icon} alt={"condition"}></img>
           </div>
           <p className='mb-0'>{title}</p>
        </div>
        <img src={arrowRight} alt="arrow right"></img>
    </div>
  );
};


const PopularSearch = (props) => {

  const next =(link)=>{
    window.location.href = link
  }

  return (
    <div className="container">
      <p className="pop_text">POPULAR SEARCH</p>
      {options.map((item,i) => (
        <Option key={i} color={item.color} title={item.title} icon={item.icon} link={item.link} next = {next} />
      ))}
    </div>
  );
};

export default withRouter(PopularSearch);
