import React from "react";
import "../../App.css";
import "../../bootstrap.css";
import { withRouter } from "react-router-dom";
import { SearchLightIcon, mappin } from "../../features/theme/icons";
import { countries, getSuggestions } from "../../helpers";
import { log } from "../../helpers/helper";

class Sidebar extends React.Component {
  constructor(props) {
    super();
    this.state = {
      country: "UNITED STATES",
      condition: "",
      suggestions:[],
      cursor:0,
    };
  }

  onInputChange = (e) => {
    //log(`${e.target.name},${e.target.value},'sidebar'`)
    this.setState({ [e.target.name]: e.target.value },()=>{
      if(this.state.condition && this.state.condition.length > 0){
        getSuggestions(this.state.condition).then(gotSuggestions=>{
          //log(gotSuggestions,'got suggestions');
          if(gotSuggestions && gotSuggestions.length > 0){
          this.setState({ suggestions: gotSuggestions})
          }else{
            this.setState({ suggestions: [],cursor: 0})
          }
        }).catch(errSuggestions=>{
          //log(errSuggestions,'got suggestions');
          this.setState({ suggestions: [],cursor: 0})
        })
      }else{
        this.setState({ suggestions : [],cursor: 0})
      }
    });
  };
  handleSearchKeyDown=(e)=>{
    if(e.key === "ArrowDown"){
      let previous_cursor = this.state.cursor
      if(previous_cursor === this.state.suggestions.length){
        this.setState({cursor: previous_cursor},()=>{
          this.setState({ condition: this.state.suggestions[this.state.cursor - 1]})
        })
      }else{
        this.setState({cursor: previous_cursor + 1},()=>{
          this.setState({ condition: this.state.suggestions[this.state.cursor - 1]})
        })
      }
    }else if(e.key === "ArrowUp"){
      let previous_cursor = this.state.cursor;
      if(previous_cursor === 1){
        this.setState({ cursor: previous_cursor},()=>{
          this.setState({ condition: this.state.suggestions[this.state.cursor - 1]})
        })
      }else{
        this.setState({ cursor: previous_cursor - 1},()=>{
          this.setState({ condition: this.state.suggestions[this.state.cursor - 1]})
        })
      }
    } else if (e.key === "Enter") {
      this.onSearchClick();
    }
  }
  onSearchClick = () => {
    const { condition, country } = this.state;
    if (
      condition === null ||
      condition === undefined ||
      condition === "" ||
      country === null ||
      country === undefined ||
      country === ""
    ) {
      alert(
        "Please enter search condition and select country to get clinical trials"
      );
    } else {
      window.location.href = `/results?q=${condition}&in=${country}`;
    }
  };
  componentDidMount() {
    const { location } = this.props;
    let decodeString = decodeURIComponent(location.search);
    let condition = decodeString.split("?q=")[1].split("&")[0],
      country = decodeString.split("&in=")[1].split("&")[0] || "UNITED STATES";
    this.setState({ condition: condition, country: country }, () => {});
  }

  setCondition=(condition)=>{
    this.setState({
      condition:condition, suggestions:[]
    })
  }

  render() {
    const { country, condition, suggestions } = this.state;
    const { filterCheckboxChange, selectedFilters, applyFiltersChange, clearAllSidebarFilters } = this.props;
    return (
      <div className="px-3 ">
        {/* <div className="ml-3">
          <div className="textinput my-2">
            <p className="mb-0">Iam looking for</p>
            <input
              className="col-sm-12 col-md-9 col-lg-9"
              type="text"
              onKeyDown={this.handleSearchKeyDown}
              onChange={this.onInputChange}
              autoComplete="off"
              name="condition"
              value={condition}
            ></input>
            {suggestions && suggestions.length > 0 ? 
                  <div style={{position:'absolute',top:80,left:17,zIndex: 2}} className="suggestion-box d-block align-items-start justify-content-center col-lg-8 col-md-10 col-sm-10 col-xs-10 mx-0 px-0">
                  {condition && condition.length > 0 ?
                  suggestions.map(suggestion=>
                    <div onClick={()=>this.setCondition(suggestion)}>
                        <p className={`px-4 py-2 mb-0 mx-0 ${suggestion === condition ? "selected_suggestion" : ""}`}>{suggestion}</p>
                    </div> 
                  ) : null}
                  </div>
                : null}
          </div>
          <div className="textinput my-2">
            <p className="mb-0">Preferred location:</p>
            <select
              className="col-sm-12 col-md-9 col-lg-9"
              //onKeyDown={this.handleSearchKeyDown}
              onChange={this.onInputChange}
              name="country"
              value={country}
            >
              {countries.map((nation, i) => (
                <option key={i} value={nation}>
                  {nation}
                </option>
              ))}
            </select>
          </div>
          <div
            onClick={() => this.onSearchClick()}
            className="search-button d-flex align-items-center justify-content-center"
          >
            <p className="mb-0">SEARCH</p>
          </div>
        </div> */}
        <div className="row px-0 mx-0">
          <div className="col-lg-7 pl-1">
            <div className="search_input d-flex align-items-center justify-content-center p-1">
              <img
                style={{ margin: "0px 0px", height: 20, width: 20 }}
                alt={"search icon"}
                src={SearchLightIcon}
              ></img>
              <input
                style={{ width: "100%", border: "none", outline: "none" }}
                type="text"
                name="condition"
                autoComplete="off"
                onChange={this.onInputChange}
                onKeyDown={this.handleSearchKeyDown}
                value={condition}
                placeholder="Condition by keyword"
              ></input>
            </div>
            {/* {suggestions && suggestions.length > 0 ? (
              <div className="suggestion-box d-block align-items-start justify-content-center col-lg-10 col-md-10 col-sm-10 col-xs-10 mx-0 px-0">
                {condition && condition.length > 0
                  ? suggestions.map((suggestion) => (
                      <div onClick={() => this.setCondition(suggestion)}>
                        <p
                          className={`px-4 py-2 mb-0 mx-0 ${
                            suggestion === condition
                              ? "selected_suggestion"
                              : ""
                          }`}
                        >
                          {suggestion}
                        </p>
                      </div>
                    ))
                  : null}
              </div>
            ) : null} */}
            {suggestions && suggestions.length > 0 ? (
              <div className="suggestion-box d-block align-items-start justify-content-center col-lg-11 col-md-11 col-sm-11 col-xs-11 mx-0 px-0">
                {condition && condition.length > 0
                  ? suggestions.map((suggestion) => (
                      <div onClick={() => this.setCondition(suggestion)}>
                        <p
                          className={`px-4 py-2 mb-0 mx-0 ${
                            suggestion === condition
                              ? "selected_suggestion"
                              : ""
                          }`}
                        >
                          {suggestion}
                        </p>
                      </div>
                    ))
                  : null}
              </div>
            ) : null}
          </div>
          <div className="col-lg-3 search_input">
            <p className="mb-0">Country</p>
            <select
              style={{ width: "100%", border: "none", outline: "none" }}
              className="text-center"
              onChange={this.onInputChange}
              name="country"
              value={country}
            >
              {countries.map((country, i) => (
                <option
                  key={i}
                  style={{ fontSize: "16px" }}
                  className="text-left"
                  value={country}
                >
                  {country}
                </option>
              ))}
            </select>
          </div>
          <div className="col-lg-2 pr-0">
            <button
              onClick={() => this.onSearchClick()}
              rel="noopener noreferrer"
              className="gn_btn btn btn-primary btn-lg w-100 search-button"
            >
              SEARCH
            </button>
          </div>
        </div>
        {/* <div className="col-lg-9 px-2">
            <div className="sidebar_filter my-3">
              <input
                style={{ height: "auto", width: "auto" }}
                className="mx-1 mt-2"
                type={"checkbox"}
                checked={selectedFilters && selectedFilters["Clear All"] && selectedFilters["Clear All"].includes("Clear All") === true ? true : false}
                onChange={(e)=>filterCheckboxChange(e)}
                name={"Clear All"}
                value={"Clear All"}
              />
              <span className="mx-2">Clear All Filters</span>
            </div>
          <div className="my-3">
            <h6
              style={{ fontWeight: "bold", borderBottom: "1px solid #BDBDBD" }}
              className="py-2"
            >
              AGE
            </h6>
            <div className="sidebar_filter">
              <input
                style={{ height: "auto", width: "auto" }}
                className="mx-1 mt-2"
                type={"checkbox"}
                checked={selectedFilters && selectedFilters["age"] && selectedFilters["age"].includes("18+") === true ? true : false}
                onChange={(e)=>filterCheckboxChange(e)}
                name={"age"}
                value={"18+"}
              />
              <span className="mx-2">18+</span>
            </div>
            <div className="sidebar_filter">
              <input
                style={{ height: "auto", width: "auto" }}
                className="mx-1 mt-2"
                type={"checkbox"}
                checked={selectedFilters && selectedFilters["age"] && selectedFilters["age"].includes("65+") === true ? true : false}
                onChange={(e)=>filterCheckboxChange(e)}
                name={"age"}
                value={"65+"}
              />
              <span className="mx-2">65+</span>
            </div>
          </div>
          <div className="my-3">
            <h6
              style={{ fontWeight: "bold", borderBottom: "1px solid #BDBDBD" }}
              className="py-2"
            >
              GENDER
            </h6>
            <div className="sidebar_filter">
              <input
                style={{ height: "auto", width: "auto" }}
                className="mx-1 mt-2"
                type={"checkbox"}
                checked={selectedFilters && selectedFilters["gender"] && selectedFilters["gender"].includes("All") === true ? true : false}
                onChange={(e) => filterCheckboxChange(e)}
                name={"gender"}
                value={"All"}
              />
              <span className="mx-2">All</span>
            </div>
            <div className="sidebar_filter">
              <input
                style={{ height: "auto", width: "auto" }}
                className="mx-1 mt-2"
                type={"checkbox"}
                checked={selectedFilters && selectedFilters["gender"] && selectedFilters["gender"].includes("Male") === true ? true : false}
                onChange={(e) => filterCheckboxChange(e)}
                name={"gender"}
                value={"Male"}
              />
              <span className="mx-2">Male</span>
            </div>
            <div className="sidebar_filter">
              <input
                style={{ height: "auto", width: "auto" }}
                className="mx-1 mt-2"
                type={"checkbox"}
                checked={selectedFilters && selectedFilters["gender"] && selectedFilters["gender"].includes("Female") === true ? true : false}
                onChange={(e) => filterCheckboxChange(e)}
                name={"gender"}
                value={"Female"}
              />
              <span className="mx-2">Female</span>
            </div>
          </div>
          <div className="my-3">
            <h6
              style={{ fontWeight: "bold", borderBottom: "1px solid #BDBDBD" }}
              className="py-2"
            >
              Study Type
            </h6>
            <div className="sidebar_filter">
              <input
                style={{ height: "auto", width: "auto" }}
                className="mx-1 mt-2"
                type={"checkbox"}
                checked={selectedFilters && selectedFilters["StudyType"] && selectedFilters["StudyType"].includes("Interventional") === true ? true : false}
                onChange={(e) => filterCheckboxChange(e)}
                name={"StudyType"}
                value={"Interventional"}
              />
              <span className="mx-2">Interventional</span>
            </div>
            <div className="sidebar_filter">
              <input
                style={{ height: "auto", width: "auto" }}
                className="mx-1 mt-2"
                type={"checkbox"}
                checked={selectedFilters && selectedFilters["StudyType"] && selectedFilters["StudyType"].includes("Observational") === true ? true : false}
                onChange={(e) => filterCheckboxChange(e)}
                name={"StudyType"}
                value={"Observational"}
              />
              <span className="mx-2">Observational</span>
            </div>
          </div>
          <div className="my-3">
            <h6
              style={{ fontWeight: "bold", borderBottom: "1px solid #BDBDBD" }}
              className="py-2"
            >
              Conditions
            </h6>
            <div className="sidebar_filter">
              <input
                style={{ height: "auto", width: "auto" }}
                className="mx-1 mt-2"
                type={"checkbox"}
                checked={selectedFilters && selectedFilters["conditions"] && selectedFilters["conditions"].includes("cancer") === true ? true : false}
                onChange={(e) => filterCheckboxChange(e)}
                name={"conditions"}
                value={"cancer"}
              />
              <span className="mx-2">Cancer</span>
            </div>
            <div className="sidebar_filter">
              <input
                style={{ height: "auto", width: "auto" }}
                className="mx-1 mt-2"
                type={"checkbox"}
                checked={selectedFilters && selectedFilters["conditions"] && selectedFilters["conditions"].includes("diabetes") === true ? true : false}
                onChange={(e) => filterCheckboxChange(e)}
                name={"conditions"}
                value={"diabetes"}
              />
              <span className="mx-2">Diabetes</span>
            </div>
            <div className="sidebar_filter">
              <input
                style={{ height: "auto", width: "auto" }}
                className="mx-1 mt-2"
                type={"checkbox"}
                checked={selectedFilters && selectedFilters["conditions"] && selectedFilters["conditions"].includes("covid19") === true ? true : false}
                onChange={(e) => filterCheckboxChange(e)}
                name={"conditions"}
                value={"covid19"}
              />
              <span className="mx-2">Covid</span>
            </div>
            <div className="sidebar_filter">
              <input
                style={{ height: "auto", width: "auto" }}
                className="mx-1 mt-2"
                type={"checkbox"}
                checked={selectedFilters && selectedFilters["conditions"] && selectedFilters["conditions"].includes("brain injury") === true ? true : false}
                onChange={(e) => filterCheckboxChange(e)}
                name={"conditions"}
                value={"brain injury"}
              />
              <span className="mx-2">Brain Injury</span>
            </div>
          </div>
          <div
            onClick={()=>applyFiltersChange(selectedFilters)}
            className="search-button d-flex align-items-center justify-content-center"
          >
            <p className="mb-0">APPLY</p>
          </div>
        </div> */}
      </div>
    );
  }
}

export default withRouter(Sidebar);
