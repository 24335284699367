import React from "react";
import { withRouter } from "react-router-dom";
import { log } from "../../../helpers/helper";

const PSER = (props) => {
  const {
    criteria,
    onCheckboxChange,
    optionChange,
    sysId,
    city,
    state,
    country,
    zipcode,
    studySysId,
    questions,
    onEnroll,
    isLoading,
  } = props;
  const splitCriteria =
    criteria &&
    criteria.split("Exclusion Criteria:" || " -  EXCLUSION CRITERIA:") &&
    criteria.split("Exclusion Criteria:" || " -  EXCLUSION CRITERIA:")
      .length === 0
      ? null
      : criteria &&
        criteria.split("Exclusion Criteria:" || " -  EXCLUSION CRITERIA:") &&
        criteria.split("Exclusion Criteria:" || " -  EXCLUSION CRITERIA:");
  let youMust =
    splitCriteria &&
    splitCriteria[0] &&
    splitCriteria[0]
      .replace("Inclusion Criteria:" || " -  INCLUSION CRITERIA", "")
      .trim();
  let youNotMust =
    splitCriteria &&
    splitCriteria[1] &&
    splitCriteria[1]
      .replace("Exclusion Criteria:" || " -  EXCLUSION CRITERIA", "")
      .trim();

  const Prescreen = ({ questions, optionChange, onEnroll }) => {
    return (
      <div className="prescreen">
        <h2>Pre-screening Questions</h2>
        {questions && questions.length === 0 ? (
          <p>No Pre-screening Questions Found</p>
        ) : (
          questions.map((question) => (
            <div className="question my-3" key={question.sys_id}>
              <h3>{question.question}</h3>
              <div className="d-flex align-items-center justify-content-start">
                <div className="prescreen_option mx-2 form-check">
                  <input
                    type="radio"
                    onChange={() => optionChange}
                    value={"true"}
                    name={question.sys_id}
                    className="form-check-input"
                    style={{cursor:'pointer'}}
                  />{" "}
                  <span>True</span>
                </div>
                <div className="prescreen_option mx-2 form-check">
                  <input
                    type="radio"
                    onChange={() => optionChange}
                    value={"false"}
                    name={question.sys_id}
                    className="form-check-input"
                    style={{cursor:'pointer'}}
                  />{" "}
                  <span>False</span>
                </div>
              </div>
            </div>
          ))
        )}
        {/* {disableEnrollButton ? (
          <button
            style={{backgroundColor:'#BDBDBD'}}
            disabled={true}
            onClick={onEnroll}
            className="btn btn-lg btn-primary"
          >
            NEXT
          </button>
        ) : ( */}
          <button onClick={onEnroll} className="btn btn-lg btn-primary">
            NEXT
          </button>
        {/* )} */}
      </div>
    );
  };

  return (
    <div>
      {/* <div>
        <h2>Enrollment Requirements</h2>
        <div className="you_must">
          <h3>You must</h3>
          {log(
            youMust
              .split("EXCLUSION CRITERIA")[0]
              .replace("-  INCLUSION CRITERIA:", ""),
            "criteria"
          )}
          {(youMust && youMust.length === 0) ||
          youMust === "undefined" ||
          youMust === null ? (
            <h3 className="text-center">
              {"No Inclusion Criteria found for this trial"}
            </h3>
          ) : youMust.split("- ") && youMust.split("- ").length > 0 ? (
            youMust.split("- ").map((inclusion) =>
              inclusion === "" ? null : (
                <div className="checkbox d-flex align-items-start justify-content-start">
                  <input
                    className="mx-1 mt-2"
                    type="checkbox"
                    onChange={onCheckboxChange}
                    name={inclusion}
                    value={inclusion}
                  />
                  <p className="mx-1 mb-0">{inclusion}</p>
                </div>
              )
            )
          ) : (
            <h2 className="text-center">None</h2>
          )}
        </div>
        <div className="you_must">
          <h3>You must not have</h3>
          {(youNotMust && youNotMust.length === 0) ||
          youNotMust === undefined ||
          youNotMust === null ? (
            <h3 className="text-center">
              {"No Exclusion Criteria found for this trial"}
            </h3>
          ) : youNotMust.split("- ") && youNotMust.split("- ").length > 0 ? (
            youNotMust.split("- ").map((exclusion) =>
              exclusion === "" ? null : (
                <div className="checkbox d-flex align-items-start justify-content-start">
                  <input
                    className="mx-1 mt-2"
                    type="checkbox"
                    onChange={onCheckboxChange}
                    name={exclusion}
                    value={exclusion}
                  />
                  <p className="mx-1 mb-0">{exclusion}</p>
                </div>
              )
            )
          ) : (
            <h2 className="text-center">None</h2>
          )}
        </div>
        <div className="my-2 d-flex align-items-center justify-content-end">
          <a
            href={`/enroll/${sysId}/${type}?city=${city}&state=${state}&country=${country}`}
            style={{ backgroundColor: color }}
            className="next mb-0 btn btn-danger btn-lg"
          >
            <h3 className="mb-0">NEXT</h3>
          </a>
        </div>
      </div> */}
      {isLoading ? (
        <Prescreen {...props} />
      ) : (
        <div className="mx-auto text-center">
          <h3>Loading ...</h3>
        </div>
      )}
    </div>
  );
};

export default withRouter(PSER);
